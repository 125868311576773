import { Box, Avatar, Icon, HStack, Text, useBreakpointValue } from "@chakra-ui/react";
import { Combobox, Transition } from '@headlessui/react';
import { isNull, isUndefined } from "lodash";
import { Fragment, useState } from "react";
import { BsChevronDown, BsSearch } from "react-icons/bs";

export const ActionTeamCombobox: React.FC<any> = ({
    teams,
    actionData,
    disabled,
    setCreateActionBody,
    createActionBody,
    update
}) => {
    const isMobile = useBreakpointValue({ base: true, md: false });

    const [selected, setSelected] = useState<any>();
    const [query, setQuery] = useState("");

    const getDisplayValue = () =>
        (!isNull(actionData?.teamname) && !isUndefined(actionData?.teamname)) ? actionData?.teamname :
            (!isNull(selected?.teamname) && !isUndefined(selected?.teamname)) ? selected?.teamname : "Unassigned";

    const getDefaultValue = () => actionData?.actionteam;

    const filteredTeams =
        query === ""
            ? teams
            : teams.filter((team: any) => {
                return (team.teamname.toLowerCase().includes(query.toLowerCase()))
            });

    const updateTeam = (e: any) => {
        setSelected(e);

        isUndefined(actionData.actionteam) ? setCreateActionBody(Object.assign(
            createActionBody,
            { team: e.teamid })
        ) : update(actionData?.actionid, "team", e.teamid);
    };

    return (
        <Combobox
            value={selected}
            disabled={disabled}
            defaultValue={getDefaultValue}
            onChange={(e: any) => { updateTeam(e) }}
        >
            <Box
                border="1px solid"
                borderColor={!disabled ? "#e2e8f0" : ""}
                rounded="lg"
                style={{
                    height: '32px',
                    display: 'flex',
                    justifyContent: 'flex-start',
                }}
                alignItems="center"
                justifyContent={'center'}
            >
                <Combobox.Button hidden={disabled} style={{ width: "100%" }}>
                    <HStack
                        alignItems="center"
                        width="100%"
                        px={2}
                    >
                        {
                            <Avatar
                                mr={1}
                                size="xs"
                                color="white"
                                verticalAlign="middle"
                                name={
                                    (!isNull(actionData?.teamname) && !isUndefined(actionData?.teamname)) ? actionData?.teamname :
                                        (!isNull(selected?.teamname) && !isUndefined(selected?.teamname)) ? selected?.teamname : ""
                                }
                            />
                        }

                        <Combobox.Input
                            className="combobox"
                            displayValue={getDisplayValue}
                            defaultValue={getDefaultValue}
                            style={{
                                flexGrow: 1,
                                padding: "4px",
                                backgroundColor: "transparent",
                                fontSize: isMobile ? "xs" : "sm",
                                fontWeight: 500
                            }}
                            onChange={(event: any) => {
                                setQuery(event.target.value);
                            }}
                        />

                        <Icon
                            as={BsChevronDown}
                            color="gray.500"
                            verticalAlign="middle"
                        />
                    </HStack>
                </Combobox.Button>
            </Box>

            <Transition
                as={Fragment}
                leave="transition ease-in duration-600"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={() => setQuery("")}
            >
                <Combobox.Options
                    as={Box}
                    disabled={disabled}
                    rounded="lg"
                    style={{
                        boxShadow: "0px 0px 10px rgba(48, 49, 51, 0.05)",
                        listStyle: "none",
                        zIndex: 999,
                        position: "absolute",
                        backgroundColor: "white",
                        border: "1px solid #E2E8F0",
                        borderTop: "none",
                        width: "100%"
                    }}
                >
                    {
                        filteredTeams.length === 0 && query !== "" ?
                            <HStack mt={2}>
                                <Icon as={BsSearch} color="gray.500" />
                                <Text
                                    color="gray.500"
                                    fontSize="10pt"
                                    fontWeight={500}
                                    cursor="pointer"
                                >
                                    Nothing found
                                </Text>
                            </HStack> :

                            filteredTeams.map((team: any, i: number) => (
                                <Combobox.Option
                                    key={`${team}${i}`}
                                    value={team}
                                >
                                    {({ active }) => (
                                        <HStack
                                            w="100%"
                                            p={2}
                                            bgColor={active ? "gray.50" : ""}
                                            cursor="pointer"
                                        >
                                            <Avatar
                                                size="xs"
                                                color="white"
                                                name={!isNull(team.teamname) ? team.teamname : "Unnamed"}
                                            />
                                            <Text
                                                fontWeight={400}
                                                fontSize="sm"
                                                color="gray.600"
                                                wordBreak="break-word"
                                                textAlign="left"
                                            >
                                                {!isNull(team.teamname) ? team.teamname : "Unnamed"}
                                            </Text>
                                        </HStack>
                                    )}
                                </Combobox.Option>
                            ))
                    }
                </Combobox.Options>
            </Transition>
        </Combobox>
    )
};