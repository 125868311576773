import {
	Box,
	Button,
	Center,
	Grid,
	HStack,
	Icon,
	Image,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Select,
	Spinner,
	Text,
	toast,
	useToast,
	VStack,
} from "@chakra-ui/react";
import { BsPeople } from "react-icons/bs";
import reportIncident from "../../assets/images/reportIncident.svg";
import reportNearMiss from "../../assets/images/reportNearMiss.svg";
import { getClickthroughURL } from "../../features/auth/auth.actions";
import { createIncident, getIncidentForms } from "../../features/incidents/incidents.actions";
import { useCallback, useEffect, useState } from "react";
import { getCurrentTeams } from '../../features/common/common.actions';
import { Form } from "../../types/Form";

export const CreateIncident = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [incidentForms, setIncidentForms] = useState<any>([])
	const [teams, setTeams] = useState<any>([])
	const [showChooseTeamModal, setShowChooseTeamModal] = useState<boolean>(false);
	const [hasMultipleTeams, setHasMultipleTeams] = useState<boolean>(false)
	const [selectedTeam, setSelectedTeam] = useState<any>("");
	const [showSelectForm, setShowSelectForm] = useState<boolean>(false);
	const [selectedForm, setSelectedForm] = useState<any>();
	const [creatingIncident, setCreatingIncident] = useState<boolean>(false);
	const toast = useToast();

	const getForms = useCallback(async () => {
		setLoading(true);
		try {
			const forms = await getIncidentForms();

			if (!forms) {
				console.error('No forms found');
				return;
			}

			const incidentForms: Form[] = forms?.forms;

			setIncidentForms(incidentForms);
			setShowSelectForm(incidentForms?.filter((form: any) => form?.tifname != 'Near Miss')?.length > 1);
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	}, []);

	const getTeams = useCallback(async () => {
		try {
			const teams: any = await getCurrentTeams();

			if (!teams) {
				console.error('No teams found.');
				return;
			}

			//merge teams managed and team memberships
			const mergedTeams: any[] = [
				...teams?.manages,
				...teams?.memberships
			].reduce((acc: any, team: any) => {
				if (!acc.some((existingTeam: any) => existingTeam.teamid === team.teamid)) {
					acc.push(team);
				}
				return acc;
			}, []);

			setTeams(mergedTeams)

			//check if they have multiple teams
			setHasMultipleTeams(mergedTeams?.length > 1);

			if (mergedTeams?.length === 1) {
				setSelectedTeam(mergedTeams[0].teamid);
			}

		} catch (error) {
			console.error(error);
		}
	}, []);

	useEffect(() => {
		getTeams();
		getForms();
	}, [])

	const handleClickThrough = async (path: string, id: number) => {
		let url: string = await getClickthroughURL(path, id);
		window.open(url, "_self");
	};

	function teamSelected(event: any) {
		if (event.target.value === '') {
			setSelectedTeam(undefined);
			return;
		}

		setSelectedTeam(event?.target.value);
	}

	function handleFormSelected(event: any) {
		if (event.target.value === '') {
			setSelectedForm(undefined);
			return;
		}

		setSelectedForm(event?.target.value);
	}

	async function handleCreateIncident(formId?: string) {
		try {
			setCreatingIncident(true);

			const incidentDetails = {
				teamid: teams?.length === 1 ? teams[0].teamid : selectedTeam,
				formid: formId ?? selectedForm
			};

			const createIncidentResponse = await createIncident(incidentDetails);

			await handleClickThrough("Incident", createIncidentResponse?.incidentId);

		} catch (error: any) {
			toast({
				title: "Failed to created incident",
				description: error?.message,
				status: "error",
				duration: 4000,
				isClosable: true
			});

			console.error(error);
		} finally {
			// setShowChooseTeamModal(false);
			setCreatingIncident(false);
		}
	}

	function handleNearMissClick() {
		const nearMissForm = incidentForms?.find((form: Form) => form.tifname === 'Near Miss');

		if (!nearMissForm) {
			return;
		}

		if (hasMultipleTeams) {
			setShowSelectForm(false);
			setSelectedForm(nearMissForm.tifid);
			setSelectedTeam(undefined);
			setShowChooseTeamModal(true);
			return;
		}

		handleCreateIncident(nearMissForm.tifid);
	}

	function handleAccidentClick() {
		setSelectedForm(undefined);

		if (hasMultipleTeams) {
			setSelectedTeam(undefined);
		}

		setShowSelectForm(true);
		setShowChooseTeamModal(true);
	}

	const renderTeamFormModal = () => {
		return <Modal
			size="xl"
			isOpen={showChooseTeamModal}
			onClose={() => { setShowChooseTeamModal(false); }}
		>
			<ModalOverlay />

			<ModalContent>
				<ModalHeader>
					<HStack>
						<Icon
							as={BsPeople}
							verticalAlign="middle"
							fontSize="4xl"
							color="green.500"
							mr={2}
						/>
						<Text fontWeight={700}>Create Incident</Text>
					</HStack>

					<ModalCloseButton />
				</ModalHeader>

				<ModalBody>

					{
						teams.length > 1 && <Box>
							<Text fontWeight={500} color="gray.500" fontSize="sm">
								Which team is this incident related to?
							</Text>

							<Select
								placeholder="Select a team"
								value={selectedTeam}
								onChange={teamSelected}
								_focus={{ borderColor: "brand.500", borderWidth: "1px" }}
								mt={2}
							>
								{teams.map((team: any) =>
									<option key={team.teamid} value={team.teamid}>
										{team.teamname}
									</option>
								)}
							</Select>
						</Box>
					}

					{
						showSelectForm && <Box mt={teams?.length > 1 ? 5 : 0}>
							<Text fontWeight={500} color="gray.500" fontSize="sm">
								Which form would you like to complete?
							</Text>

							<Select
								placeholder="Select a form"
								value={selectedForm}
								onChange={handleFormSelected}
								_focus={{ borderColor: "brand.500", borderWidth: "1px" }}
								mt={2}
							>
								{incidentForms?.filter((form: any) => form?.tifname != 'Near Miss')?.map((form: any) =>
									<option key={form.tifid} value={form.tifid}>
										{form.tifname}
									</option>
								)}
							</Select>
						</Box>
					}
				</ModalBody>

				<ModalFooter p={5}>
					<Button
						bg="brand.500"
						color="white"
						size="sm"
						_hover={{ bg: "" }}
						onClick={() => handleCreateIncident()}
						disabled={
							(showSelectForm && selectedForm === undefined) || (teams.length > 1 && selectedTeam === undefined) || creatingIncident
						}
					>
						Create Incident
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	};

	return (
		<Box>
			{
				!loading && incidentForms?.length < 1 && <Center m={5}>
					<VStack>
						<Text fontSize="xl" fontWeight={600}>
							No Incident Report forms configured
						</Text>
					</VStack>
				</Center>
			}

			<Grid
				templateColumns={{
					base: "repeat(1, 2fr)",
					md: "repeat(2, 1fr)",
					lg: "repeat(2, 1fr)",
				}}
				gridAutoRows="1fr"
				gap={5}
			>
				{
					incidentForms?.filter((form: any) =>
						form?.tifname !== 'Near Miss')?.length >= 1 &&
					<Box
						p={5}
						border="1px"
						borderRadius="12px"
						borderColor="gray.200"
						className="hover-pop"
						cursor="pointer"
						onClick={handleAccidentClick}
					>
						<VStack gap={5}>
							<Image src={reportIncident} />

							<Text
								fontWeight={600}
								fontSize={{ base: "xl" }}
								color="gray.700"
								mb={5}
							>
								I want to report an Accident
							</Text>
						</VStack>
					</Box>
				}

				{
					incidentForms?.filter((form: any) =>
						form?.tifname === 'Near Miss')?.length === 1 &&
					<Box
						p={5}
						border="1px"
						borderRadius="12px"
						borderColor="gray.200"
						className="hover-pop"
						cursor="pointer"
						onClick={handleNearMissClick}
					>
						<VStack gap={5}>
							<Image src={reportNearMiss} />
							<Text
								fontWeight={600}
								fontSize={{ base: "xl" }}
								color="gray.700"
								mb={5}
							>
								I want to report a Near Miss
							</Text>
						</VStack>
					</Box>
				}
			</Grid>

			{renderTeamFormModal()}
		</Box>
	);
};