import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
	Box,
	Text,
	Button,
	VStack,
	Divider,
	HStack,
	Icon,
	Image,
	useColorModeValue,
	Table,
	Tbody,
	Tr,
	Th,
	Td,
	Spinner,
	useToast,
	FormControl,
	FormLabel,
	Input,
	FormErrorMessage,
	useBreakpointValue,
	Spacer,
	ModalOverlay,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader
} from "@chakra-ui/react";
import {
	BsArrowLeftCircle,
	BsArrowRightCircle,
	BsInfoCircle,
	BsXCircle
} from "react-icons/bs";

import taskComplete from "../../assets/images/taskComplete.svg";
import { isEmpty } from "lodash";
import { SetStateAction, useEffect, useState } from "react";
import { failTaskAttempt, passTaskAttempt } from "../../features/tasks/task.actions";
import { FaClipboardCheck } from "react-icons/fa";

export const TaskComplete = () => {
	const { state }: any = useLocation();
	const navigateTo = useNavigate();
	const toast = useToast();
	const { id } = useParams();

	const [failedItems, setFailedItems] = useState([]);
	const [submitting, setSubmitting] = useState<boolean>(false);

	const [pinCodeError, setPinCodeError] = useState<boolean>(false);
	const [pinCode, setPinCode] = useState<string>('')

	const isMobile = useBreakpointValue({ base: true, md: false });
	const [failModalIsOpen, setFailModalIsOpen] = useState(false);

	useEffect(() => {
		let result: any = [];

		if (!isEmpty(state.failedItems)) {
			Object.keys(state.failedItems.pages).map((key) => {
				let failedItems: any = [];

				const stepId = state.failedItems.pages[key].tpid;
				const stepName = state.failedItems.pages[key].tpname;

				state.failedItems.pages[key].items.map((item: any) =>
					failedItems.push({
						id: item.tiid,
						description: item.tidescription !== "" ? item.tidescription :
							item.cname !== "" ? item.cname :
								"",
						reason: item.itemnotes
					})
				);

				result.push({
					stepId,
					stepName,
					failedItems,
				});
			});

			setFailedItems(result);
		}
	}, []);

	const passTask = () => {
		setSubmitting(true);

		passTaskAttempt(Number(state.id), undefined, pinCode)
			.then((res) => {
				if (res?.success) {
					toast({
						title: "Task Report Submitted",
						description: "",
						status: "success",
						duration: 5000,
						isClosable: true
					});

					navigateTo("/tasks", {
						state: {
							navData: state?.navData,
							from: "task"
						}
					});
				} else {
					if (res?.message === 'PIN code incorrect.' && res?.error === 82) {
						setPinCodeError(true);

						return;
					}

					toast({
						title: res?.message,
						description: "Please try again. If this issue persists please contact your system administrator.",
						status: "error",
						duration: 5000,
						isClosable: true
					});
				}
			})
			.catch((error) => {
				console.log(error);

				toast({
					title: "We couldn't submit this Task Report",
					description: "Please try again. If this issue persists please contact your system administrator.",
					status: "error",
					duration: 5000,
					isClosable: true
				});
			})
			.finally(() => { setSubmitting(false); });
	};

	const failTask = () => {
		failTaskAttempt(Number(state?.id), undefined, pinCode)
			.then((res: any) => {
				if (res?.success) {
					toast({
						title: "Task Report Submitted As Failed",
						description: "",
						status: "success",
						duration: 5000,
						isClosable: true
					});

					navigateTo("/tasks", {
						state: {
							navData: state?.navData,
							from: "task"
						}
					});
				} else {
					if (res?.message === 'PIN code incorrect.' && res?.error === 82) {
						setPinCodeError(true);

						return;
					}

					toast({
						title: res?.message ?? "We couldn't submit this Task Report",
						description: "Please try again. If this issue persists please contact your system administrator.",
						status: "error",
						duration: 5000,
						isClosable: true
					});
				}
			})
			.catch((error: any) => {
				console.log(error);

				toast({
					title: "We couldn't submit this Task Report.",
					description: "Please try again. If this issue persists please contact your system administrator.",
					status: "error",
					duration: 6000,
					isClosable: true,
				});
			})
	};

	const renderFailTaskModal = () => {
		return <Modal
			isCentered
			size="md"
			isOpen={failModalIsOpen}
			onClose={() => { setFailModalIsOpen(false) }}
		>
			<ModalOverlay />

			<ModalContent>
				<ModalHeader>
					<Text>Fail Task</Text>
					<ModalCloseButton />
				</ModalHeader>

				<ModalBody>
					<Text fontWeight={400} fontSize="md">Are you sure you want to fail this task?</Text>
				</ModalBody>

				<ModalFooter p={5}>
					<HStack>
						<Button
							size="md"
							_hover={{ bg: "" }}
							onClick={() => { setFailModalIsOpen(false); }}
						>
							No
						</Button>
						<Button
							bg="danger.500"
							color="white"
							size="md"
							_hover={{ bg: "" }}
							onClick={() => {
								setFailModalIsOpen(false);
								failTask();
							}}
						>
							Yes
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	};

	function handlePinCodeChange(text: any) {
		if (pinCodeError) {
			setPinCodeError(false)
		}

		setPinCode(text)
	}

	async function checkEnterKey(e: React.KeyboardEvent) {
		if (e.key === 'Enter') {
			passTask();
		}
	}

	return (
		<Box
			h={{ base: "100%", md: "unset" }}
			m={{ base: 0, md: 5 }}
			p={{ base: 0, md: 5 }}
			pt={5}
			bg={useColorModeValue("white", "gray.900")}
			rounded={{ base: "none", md: "lg" }}
			boxShadow={{ base: "none", md: "lg" }}
		>
			<HStack gap={4} mx={3} mb={4}>
				<Icon
					as={FaClipboardCheck}
					color="green.500"
					fontSize="4xl"
				/>
				<Text
					fontWeight={{ base: 600, lg: 700 }}
					fontSize="2xl"
					color="gray.700"
				>
					Review and Submit Task Report
				</Text>
			</HStack>

			<Divider width="unset" mx={{ base: 0, md: -5 }} />

			<Box mx={3} mt={5}>
				<VStack alignItems="left" gap={2}>
					{
						state.failedItems.faileditemcount < 1 && !state?.pinCodeRequired ? (
							<Image src={taskComplete} w={{ base: "unset", md: "70%" }} />
						) : (
							<>
								{
									state?.failedItems?.faileditemcount >= 1 && <Box
										w={{ base: "100%", md: "50%" }}
										p={5}
										bg="red.50"
										border="1px solid"
										borderColor="red.100"
										rounded="lg"
									>
										<VStack alignItems="start" color="gray.600">
											<HStack color="red.500">
												<Icon as={BsInfoCircle} />
												<Text fontSize="lg" fontWeight={600}>
													There are some issues with your task report.
												</Text>
											</HStack>

											<Text
												fontWeight={500}
												fontSize="sm"
												textAlign="start"
												color="red.500"
											>
												If you can't address them now, you can still submit your report but it will be marked as a failure.
											</Text>
										</VStack>
									</Box>
								}

								{
									state.failedItems.faileditemcount >= 1 && <Box
										border={{ base: "1px solid #e2e8f0" }}
										borderRadius="12px"
										borderColor="gray.200"
									>
										<Box m={{ base: 1, md: 3 }}>
											{
												failedItems.map((item: any, i: Number) => {
													return <Table maxW="100%" variant="striped" >
														<Th borderTop="none">
															<Text fontWeight={700} color="gray.600" fontSize="md" whiteSpace="pre-line">
																{item.stepName}
															</Text>
														</Th>
														<Th borderTop="none"></Th>
														<Tbody>
															{
																item.failedItems.map((failedItem: any, i: Number) => {
																	return <Tr key={failedItem.id}>
																		<Td maxWidth="400px">
																			<Text whiteSpace="pre-line">{failedItem.description}</Text>
																		</Td>
																		<Td>
																			<Text whiteSpace="pre-line">{failedItem.reason}</Text>
																		</Td>
																	</Tr>
																})
															}
														</Tbody>
													</Table>
												})
											}
										</Box>
									</Box>
								}

								{
									state?.pinCodeRequired && <Box
										p={5}
										border={{ base: "1px solid #e2e8f0" }}
										borderRadius="12px"
										borderColor="gray.200"
										rounded="lg"
									>
										{
											state?.taskname && <Text
												mb={5}
												textAlign="left"
											>
												The task "{state?.taskname}" requires PIN code validation.
											</Text>

										}

										<FormControl
											w="100%"
											isRequired={true}
											isInvalid={pinCodeError}
											mb={2}
										>
											<FormLabel color="gray.500">
												Enter PIN Code for {state?.targetUser}
											</FormLabel>

											<Box w="100%">
												<Input
													required
													value={pinCode}
													type="password"
													placeholder="Enter PIN"
													onChange={(event: { target: { value: SetStateAction<string>; }; }) => handlePinCodeChange(event.target.value)}
													onKeyDown={checkEnterKey}
													autoComplete='one-time-code'
												/>
											</Box>

											{
												pinCodeError && <FormErrorMessage>Pin Code Invalid</FormErrorMessage>
											}
										</FormControl>
									</Box>
								}
							</>
						)}

					<HStack pb={{ base: 5, md: 0 }}>
						<Button
							size="sm"
							leftIcon={<BsArrowLeftCircle />}
							variant="outline"
							borderColor="gray.200"
							color="gray.600"
							onClick={() => {
								navigateTo(`/tasks/task/${id}`,
									{
										state: {
											cardStatus: state?.cardStatus,
											id: state?.id,
											teamId: state?.teamId,
											taskId: state?.taskId,
											teamTaskId: state?.teamTaskId,
											navData: state?.navData
										}
									});
							}}
						>
							Back
						</Button>

						<Button
							size="sm"
							variant="outline"
							borderColor="brand.500"
							_hover={{ bg: "#248451" }}
							_active={{ bg: "" }}
							color="white"
							bg="brand.500"
							onClick={() => {
								passTask();
							}}
						>
							Submit {
								submitting ? <Spinner ml={2} size="sm" color="white" /> :
									<Icon as={BsArrowRightCircle} ml={2} />
							}
						</Button>

						<Spacer />

						{
							state?.showFailButton && <Button
								w={{ baae: "full", md: "" }}
								borderColor="danger.500"
								variant="outline"
								_hover={{ bg: "gray.50" }}
								size="md"
								bg="white"
								color="danger.500"
								fontSize={isMobile ? "10pt" : "unset"}
								onClick={() => {
									setFailModalIsOpen(true);
								}}
							>
								<HStack>
									<Text fontSize={{ base: "11pt", md: "unset" }}>
										Fail Task
									</Text>
									<Icon as={BsXCircle} />
								</HStack>
							</Button>
						}
					</HStack>
				</VStack>
			</Box>

			{renderFailTaskModal()}
		</Box >
	);
};