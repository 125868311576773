import { useNavigate } from 'react-router-dom';
import {
    Box,
    Text,
    Button,
    VStack,
    Center,
    Divider,
    HStack,
    Icon,
    Image,
    useColorModeValue
} from '@chakra-ui/react';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { VscMortarBoard } from 'react-icons/vsc';

import moduleComplete from "../../assets/images/moduleComplete.svg";

export const ModuleComplete = () => {
    const navigateTo = useNavigate();

    return (
        <Box
            m={5}
            p={{ base: 0, md: 5 }}
            pt={5}
            bg={useColorModeValue('white', 'gray.900')}
            boxShadow={'lg'}
            rounded={'lg'}
            textAlign={'center'}
        >
            <HStack>
                <Text
                    fontWeight={{ base: 600, lg: 700 }}
                    fontSize={{ base: "lg", lg: '2xl' }}
                    color="gray.700"
                >
                    <Icon
                        as={VscMortarBoard}
                        ml={5}
                        mr={5}
                        mt={-1}
                        verticalAlign="middle"
                        color="green.500"
                        fontSize="4xl"
                    /> Module Title
                </Text>
            </HStack>

            <Text
                fontWeight={500}
                fontSize="md"
                color="gray.500"
                textAlign="left"
                ml={20}
                mb={3}
            >
                Course name
            </Text>

            <Divider
                ml={{ base: 0, md: -5 }}
                mr={{ base: 0, md: -5 }}
            />

            <Center mb={3}>
                <VStack alignItems="center" gap={8}>
                    <Image src={moduleComplete} mt={3} />

                    <Button
                        w="fit-content"
                        mt={5}
                        leftIcon={<BsArrowLeftCircle />}
                        size="md"
                        variant="outline"
                        color="gray.600"
                        fontWeight={600}
                        onClick={() => {
                            navigateTo(`/training`);
                        }}
                    >
                        Back to Training
                    </Button>
                </VStack>
            </Center>
        </Box>
    )
}