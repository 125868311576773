import { Fragment, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
	Box,
	Button,
	Center,
	Divider,
	HStack,
	Icon,
	Image,
	SlideFade,
	Spacer,
	Spinner,
	Stack,
	Text,
	useColorModeValue,
	useToast,
	VStack,
} from '@chakra-ui/react';

import { VscMortarBoard } from 'react-icons/vsc';
import { BsArrowCounterclockwise, BsArrowLeftCircle, BsArrowRightCircle, BsCheck2Circle, BsCheckCircleFill, BsExclamationCircleFill, BsEye, BsPlayCircle, BsSkipBackwardFill } from 'react-icons/bs';

import { completeModule, getModule, getModuleAudio, getModuleAssetDataUri, patchModule } from '../../features/training/training.actions';
import { slideInterface, SlidePlayer } from './SlidePlayer';

import CSS from 'csstype';
import { choiceInterface, questionInterface, QuestionPlayer } from './QuestionPlayer';
import { isEmpty, isNull, isUndefined } from 'lodash';

import { PieChart } from 'react-minimal-pie-chart';

import incident from "../../assets/images/incident.svg";
import hi5 from "../../assets/images/hi5.svg";

export const Module = () => {
	const appStyles: CSS.Properties = {
		minWidth: "100%",
		margin: "0px"
	};

	const navigateTo = useNavigate();
	const { id } = useParams();
	const { state }: any = useLocation();
	const toast = useToast();

	const [courseId, setCourseId] = useState<any>(null);
	const [attemptData, setAttemptData] = useState<any>({});
	const [resultData, setResultData] = useState<any>([]);

	const [loading, setLoading] = useState<boolean>(true);
	const [loadingFirstTime, setLoadingFirstTime] = useState<boolean>(false);
	const [completing, setCompleting] = useState<boolean>(false);
	const [showScore, setShowScore] = useState<boolean>(false);

	const [moduleData, setModuleData] = useState({
		isViewingSlides: false,
		isFinishedSlides: false,
		isViewingQuestions: false,
		isFinishedQuestions: false,
		isWaitingForObservations: false,
		acknowledge: false,
		askQuestions: false,
		ackText: "",

		canJumpToQuestions: false,
		canGoBackwards: false,
		canGoForwards: false,

		percentageComplete: 0,
		slideCount: 0,
		slideNumber: 0,
		questionCount: 0,
		questionNumber: 0,
		courseName: ""
	});

	const [slide, setSlide] = useState<slideInterface>({
		layout: "",
		title: "",
		image: "",
		video: "",
		pdf: "",
		audio: "",
		autostartVoiceover: false,
		content: ""
	});

	const [image, setImage] = useState<string>("");
	const [audioData, setAudioData] = useState<any>({
		file: "",
		error: null
	});

	const [choices, setChoices] = useState<choiceInterface[]>([]);
	const [question, setQuestion] = useState<questionInterface>({
		csrf: "",
		type: "",
		layout: "",
		title: "",
		image: "",
		video: "",
		content: "",
		prompt: "",
		shuffle: "",
		maxChoices: 0,
		orientation: "",
		choices: []
	});

	const getFailedQuestionResults = (choices: any) => {
		const selectedData = choices.filter((c: any) => c.isSelected)[0];
		const correctData = choices.filter((c: any) => c.isCorrect)[0];

		return <VStack alignItems="start">
			<HStack>
				<Icon as={BsExclamationCircleFill} color="danger.500" />
				<Text fontSize={{ base: "xs", md: "sm" }}>
					You selected <strong>{selectedData.text}</strong>
				</Text>
			</HStack>

			<HStack>
				<Icon as={BsCheckCircleFill} color="brand.500" />
				<Text fontSize={{ base: "xs", md: "sm" }}>
					The correct answer is <strong>{correctData.text}</strong>
				</Text>
			</HStack>
		</VStack>
	};

	const complete = async () => {
		setCompleting(true);

		await completeModule({ id: Number(id) })
			.then((res: any) => {
				if (!isNull(res.questions)) {
					const failedQuestions = res.questions?.filter(((q: any) => q.qahscore === "0"));
					setResultData(failedQuestions);
				}

				if (!isUndefined(res.attempt)) {
					setAttemptData(res.attempt);
				}
			})
			.catch((error: any) => {
				console.log(error);
			})
			.finally(() => {
				setCompleting(false);

				if ((moduleData.acknowledge && !moduleData.isWaitingForObservations)) {
					isNull(courseId) ? navigateTo("/training") :
						navigateTo(`/training/course/${courseId}`);
				} else
					setShowScore(true);
			});
	};

	const updateQuestionData = async (answer: string, csrf: string) => {
		await patchModule({
			id: Number(id),
			answer,
			seasurf: csrf
		})
			.then((res: any) => {
				let updatedChoices: choiceInterface[] = [];

				const selectedChoice: choiceInterface = res.question.questionData.choices
					.filter((choice: choiceInterface) => choice.isSelected)[0];

				choices.filter(choice => {
					if (selectedChoice.identifier === choice.identifier) {
						choice.isSelected = selectedChoice.isSelected;
					} else {
						choice.isSelected = false;
					}
					updatedChoices.push(choice);
				});

				setChoices(updatedChoices);
				setQuestion(Object.assign(
					res.question.questionData,
					{ "csrf": res.question.questionSeasurf })
				);
			})
			.catch((error: any) => {
				console.log(error);
			});
	};

	const fetchQuestionData = useCallback(async (res: any) => {
		const resultData = res.question.questionData;
		let choices: choiceInterface[] = [];

		// Set question data
		setQuestion(Object.assign(
			resultData,
			{ "csrf": res.question.questionSeasurf })
		);

		// Get question image
		if (resultData.image !== "" &&
			resultData.image !== null) {
			await getModuleAssetDataUri(
				Number(id),
				resultData.image,
				true,
				false
			)
				.then((res: any) => {
					setImage(res);
				})
				.catch((error: any) => {
					console.log(error);
				});
		} else
			setImage("");

		// Image buttons
		if (resultData.layout === "ImagesChoices2" ||
			resultData.layout === "ImagesChoices3" ||
			resultData.layout === "ImagesChoices4"
		) {
			for (let i = 0; i < resultData.choices.length; i++) {
				await getModuleAssetDataUri(
					Number(id),
					resultData.choices[i].image,
					true,
					true
				)
					.then((uri) => {
						choices.push({
							identifier: resultData.choices[i].identifier,
							image: resultData.choices[i].image,
							imageUri: uri,
							text: resultData.choices[i].text,
							isCorrect: resultData.choices[i].isCorrect,
							isSelected: resultData.choices[i].isSelected ?? false
						});
					})
					.catch((error: any) => {
						console.log(error);
					})
			}

			setChoices(choices);
		} else {
			// Text buttons
			resultData.choices.map((choice: any) => {
				choices.push({
					identifier: choice.identifier,
					image: choice.image,
					imageUri: null,
					text: choice.text,
					isCorrect: choice.isCorrect,
					isSelected: choice.isSelected ?? false
				});
			});

			setChoices(choices);
		}

		setModuleData(Object.assign(
			res,
			{
				"canGoBackwards": res.question.canGoBackwards,
				"canGoForwards": res.question.canGoForwards,
				"questionCount": res.question.questionCount,
				"questionNumber": res.question.questionNumber,
				"percentageComplete": res.question.percentageComplete,

				"isViewingSlides": res.isViewingSlides,
				"isFinishedSlides": res.isFinishedSlides,
				"isViewingQuestions": res.isViewingQuestions,
				"isWaitingForObservations": res.isWaitingForObservations,
				"askQuestions": res.askQuestions,
				"acknowledge": res.acknowledge,
				"ackText": res.ackText ?? ""
			}
		));

		setLoading(false);
	}, [id]);

	const processData = async (res: any) => {
		if (res.modules.length === 1) {
			setCourseId(res.modules[0].id);
		}

		if (res.isViewingQuestions) {
			setImage("");
			fetchQuestionData(res);
		} else if (
			res.isFinishedQuestions ||
			res.isFinishedSlides ||
			res.isWaitingForObservations
		) {
			setModuleData(Object.assign(
				{ ...moduleData },
				{
					"courseName": res.courseName,
					"canJumpToQuestions": res.canJumpToQuestions,
					"isViewingSlides": res.isViewingSlides,
					"isFinishedSlides": res.isFinishedSlides,
					"isViewingQuestions": res.isViewingQuestions,
					"isFinishedQuestions": res.isFinishedQuestions,
					"isWaitingForObservations": res.isWaitingForObservations,
					"askQuestions": res.askQuestions,
					"acknowledge": res.acknowledge,
					"ackText": res.ackText
				}
			));
		} else if (res.isViewingSlides) {
			try {
				let pdf = null;
				let image = null;
				let video: string = "";

				setModuleData(Object.assign(
					res.slide,
					{
						"courseName": res.courseName,
						"canJumpToQuestions": res.canJumpToQuestions,

						"isViewingSlides": res.isViewingSlides,
						"isFinishedSlides": res.isFinishedSlides,
						"isViewingQuestions": res.isViewingQuestions,
						"isFinishedQuestions": res.isFinishedQuestions,
						"isWaitingForObservations": res.isWaitingForObservations,
						"askQuestions": res.askQuestions,
						"acknowledge": res.acknowledge,
						"ackText": res.ackText
					}
				));

				// Get audio
				if (!isNull(res.slide.slideData.audio) && !isEmpty(res.slide.slideData.audio)) {
					await getModuleAudio(
						Number(id),
						res.slide.slideData.audio,
						true,
						false
					)
						.then((res: any) => {
							if (!res.success) {
								setAudioData({ "error": res.message });
							} else
								setAudioData({ "file": res.datauri, "error": null });
						})
						.catch((error: any) => {
							console.log(error);
						});
				}

				// Get video
				const videoYoutube: boolean = res.slide.slideData.video?.length > 0 && res.slide.slideData.video?.startsWith("http") && res.slide.slideData.video?.indexOf("youtube") > 0;
				const videoVimeo: boolean = res.slide.slideData.video?.length > 0 && res.slide.slideData.video?.startsWith("http") && res.slide.slideData.video?.indexOf("vimeo") > 0;
				const videoBitMovin: boolean = res.slide.slideData.video?.length > 0 && res.slide.slideData.video?.startsWith("http") && res.slide.slideData.video?.indexOf("bitmovin") > 0;

				const videoEmbed: boolean = res.slide.slideData.video?.length > 0 && !videoYoutube && !videoVimeo && !videoBitMovin;

				if (videoEmbed &&
					!isNull(res.slide.slideData.video) &&
					!isEmpty(res.slide.slideData.video)
				) {
					video = `${process.env.REACT_APP_OBBI_API_URL}/training/courseAsset?id=${Number(id)}&asset=${res.slide.slideData.video}`
				} else {
					video = res.slide.slideData.video;
				}

				// Get image
				if (!isNull(res.slide.slideData.image) && !isEmpty(res.slide.slideData.image)) {
					image = `${process.env.REACT_APP_OBBI_API_URL}/training/courseAsset?id=${Number(id)}&asset=${res.slide.slideData.image}`;
				}

				// Get PDF
				if (!isNull(res.slide.slideData.pdf) && !isEmpty(res.slide.slideData.pdf)) {
					pdf = await getModuleAssetDataUri(
						Number(id),
						res.slide.slideData.pdf,
						true,
						false
					).catch((error: any) => {
						console.log(error);
					});
				}

				setSlide(Object.assign(
					res.slide.slideData,
					{ "image": image },
					{ "video": video },
					{ "pdf": pdf },
					{ "autostartVoiceover": res.slide.autostartVoiceover }
				));
			} catch (error: any) {
				console.log();
			} finally {
				setLoading(false);
			}
		}
	};

	const updateData = async (navigate: string = "") => {
		await patchModule({ id: Number(id), navigate })
			.then(async (res: any) => {
				processData(res);
			})
			.catch((error: any) => {
				console.log(error);
			});
	};

	const fetchData = useCallback(async () => {
		setLoadingFirstTime(true);
		setLoading(true);

		await getModule(Number(id))
			.then(async (res: any) => {
				if (!res.success) {
					toast({
						title: res.message,
						description: "",
						status: "error",
						duration: 6000,
						isClosable: true,
					});

					if (res.modules.length === 1) {
						navigateTo(`/training/course/${res.modules[0].id}`);
					} else {
						navigateTo("/training");
					}
				} else {
					processData(res);
				}
			})
			.catch((error: any) => {
				console.log(error);
			})
			.finally(() => {
				setLoadingFirstTime(false);
				setLoading(false);
			});
	}, [fetchQuestionData, id]);

	useEffect(() => { fetchData() }, [fetchData]);

	return (
		<Box m={{ base: 3, md: 5 }} mt={{ base: 5 }}>
			<Button
				display={{ base: "none", md: "unset" }}
				w="fit-content"
				hidden={state?.from !== "notification"}
				leftIcon={<BsArrowLeftCircle />}
				size="sm"
				mb={2}
				variant="outline"
				color="gray.600"
				fontWeight={600}
				onClick={() => {
					navigateTo(-1);
				}}
			>
				Back to Notification
			</Button>

			<Box
				p={{ base: 0, md: 5 }}
				h="max-content"
				bg={useColorModeValue("white", "gray.900")}
				boxShadow={{ base: "none", md: "lg" }}
				rounded={"lg"}
			>
				<Button
					display={{ base: "unset", md: "none" }}
					size="md"
					m={5}
					mb={8}
					textAlign="start"
					color="gray.600"
					variant="link"
					fontWeight={600}
					onClick={() => {
						navigateTo(-1);
					}}
				>
					<HStack>
						<Icon as={BsArrowLeftCircle} />
						<Text>Back to Notification</Text>
					</HStack>
				</Button>

				<Stack direction={{ base: "column", md: "row" }}>
					<VStack alignItems="start" mx={3}>
						<HStack alignItems="start" gap={4}>
							<Icon
								as={VscMortarBoard}
								verticalAlign="middle"
								color="green.500"
								fontSize="4xl"
							/>
							<SlideFade in={!loadingFirstTime}>
								<Text
									fontWeight={{ base: 600, lg: 700 }}
									fontSize={{ base: "2xl", lg: '2xl' }}
									color="gray.700"
								>
									{moduleData.courseName}
								</Text>
							</SlideFade>
						</HStack>

						<Text
							fontWeight={500}
							fontSize="md"
							color="gray.500"
							textAlign="left"
							hidden={(moduleData.questionCount === 0 && moduleData.slideCount === 0) || showScore}
						>
							{moduleData.isViewingQuestions ? "Question" : "Slide"} <strong>{moduleData.slideNumber || moduleData.questionNumber}</strong> of <strong>{moduleData.slideCount || moduleData.questionCount}</strong>
						</Text>
					</VStack>

					<Spacer />

					<HStack px={3}>
						<Button
							w="fit-content"
							size="md"
							bg="brand.500"
							color="white"
							fontWeight={600}
							_hover={{ bg: "" }}
							disabled={completing}
							onClick={() => {
								isNull(courseId) ? navigateTo("/training") :
									navigateTo(`/training/course/${courseId}`);
							}}
						>
							Exit
						</Button>

						<Button
							float="left"
							size="md"
							bg="brand.500"
							color="white"
							variant="ghost"
							disabled={loading || showScore || (!moduleData.canGoBackwards && !(moduleData.acknowledge && moduleData.isFinishedSlides))}
							_hover={{ bgColor: "" }}
							_active={{ bgColor: "" }}
							onClick={() => updateData("first")}
						>
							<Icon as={BsSkipBackwardFill} m={0} />
						</Button>

						<Button
							float="left"
							size="md"
							bg="brand.500"
							color="white"
							variant="ghost"
							disabled={
								loading ||
								showScore ||
								(!moduleData.canGoBackwards && !moduleData.isFinishedSlides)
							}
							leftIcon={<Icon as={BsArrowLeftCircle} />}
							_hover={{ bgColor: "" }}
							_active={{ bgColor: "" }}
							onClick={() => updateData("prev")}
						>
							Back
						</Button>

						<Button
							float="right"
							size="md"
							bg="brand.500"
							color="white"
							variant="ghost"
							disabled={
								loading ||
								showScore ||
								moduleData.isFinishedQuestions ||
								moduleData.isWaitingForObservations ||
								(moduleData.askQuestions && moduleData.isFinishedSlides) ||
								(moduleData.acknowledge && moduleData.isFinishedSlides) ||
								(moduleData.isViewingQuestions && moduleData.isFinishedSlides)
							}
							rightIcon={<Icon as={BsArrowRightCircle} />}
							_hover={{ bgColor: "" }}
							_active={{ bgColor: "" }}
							onClick={() => updateData("next")}
						>
							Next
						</Button>
					</HStack>
				</Stack>

				<Divider
					width="unset"
					mt={5}
					mx={{ base: 0, md: -5 }}
				/>

				<VStack m={3} gap={3}>
					<Box style={appStyles}>
						{
							loading && <Center my={48}>
								<Spinner
									thickness='4px'
									speed='0.65s'
									color="brand.500"
									size="xl"
								/>
							</Center>
						}

						{
							!loading &&
							moduleData.isWaitingForObservations && <Center mt={5}>
								<VStack>
									<HStack>
										<Icon as={BsEye} fontSize="2xl" />

										<Text fontWeight={600} fontSize="xl">
											Awaiting Observation
										</Text>
									</HStack>

									<Text color="gray.500">
										Training module awaiting observation. Check back later.
									</Text>

									<Spacer />

									<Button
										size="md"
										bg="brand.500"
										color="white"
										boxShadow="sm"
										_hover={{ bgColor: "" }}
										disabled={completing}
										leftIcon={!completing ? <Icon as={BsArrowLeftCircle} /> : <Fragment />}
										onClick={() => isNull(courseId) ? navigateTo("/training") :
											navigateTo(`/training/course/${courseId}`)
										}
									>
										Go Back
									</Button>
								</VStack>
							</Center>
						}

						{
							!loading &&
							moduleData.acknowledge &&
							moduleData.isFinishedSlides && <Center mt={5}>
								<VStack>
									<Text fontWeight={600} fontSize="xl">
										Acknowledge Training Module
									</Text>
									<Text color="gray.500">
										{moduleData.ackText || "Thank you for completing this module. Please acknowledge that you have understood the topics covered."}
									</Text>

									<Spacer />

									<Button
										size="md"
										bg="brand.500"
										color="white"
										boxShadow="sm"
										_hover={{ bgColor: "" }}
										disabled={completing}
										rightIcon={!completing ? <Icon as={BsCheck2Circle} /> : <Fragment />}
										onClick={() => complete()}
									>
										Acknowledge
										{
											completing && <Spinner size="sm" ml={3} />
										}
									</Button>
								</VStack>
							</Center>
						}

						{
							!loading &&
							moduleData.askQuestions &&
							moduleData.isFinishedSlides && <Center mt={5}>
								<VStack>
									<Text fontWeight={600} fontSize="xl">
										Start Assessment
									</Text>
									<Text color="gray.500">
										Thank you for completing this module. Please acknowledge that you have understood the topics covered.
									</Text>

									<Spacer />

									<Button
										ml={2}
										float="right"
										size="md"
										bg="brand.500"
										color="white"
										boxShadow="sm"
										rightIcon={<Icon as={BsPlayCircle} />}
										_hover={{ bgColor: "" }}
										onClick={() => {
											updateData("assessment")
										}}
									>
										Start Assessment
									</Button>
								</VStack>
							</Center>
						}

						{
							!loading &&
							!showScore && moduleData.isFinishedQuestions && <Center mt={5}>
								<VStack>
									<Text fontWeight={600} fontSize="xl">
										Submit Your Assessment
									</Text>
									<Text color="gray.500">
										Click Complete to submit your assessment.
									</Text>

									<Spacer />

									<Button
										ml={2}
										float="right"
										size="md"
										bg="brand.500"
										color="white"
										boxShadow="sm"
										_hover={{ bgColor: "" }}
										disabled={completing}
										onClick={() => complete()}
									>
										<HStack>
											<Text>
												Complete
											</Text>

											{
												!completing ? <Icon as={BsCheck2Circle} /> :
													<Spinner size="sm" ml={3} />
											}
										</HStack>
									</Button>
								</VStack>
							</Center>
						}

						{
							!loading && showScore && <Center mt={5}>
								<Box
									border="1px solid"
									borderColor="gray.200"
									rounded="lg"
									p={5}
									w="100%"
								>
									<HStack>
										<Box alignSelf="start" style={{ scale: "0.7" }}>
											{
												attemptData.cahpercentage < 10 ? <Image src={incident} mt={3} /> :
													attemptData.cahpercentage > 10 &&
														attemptData.cahpercentage < 100 ? <PieChart
														data={[
															{
																value: attemptData.cahpercentage,
																color: attemptData.cahpercentage > 75 ? '#37a169'
																	: attemptData.cahpercentage > 50 ? "orange"
																		: "red"
															}
														]}
														totalValue={100}
														lineWidth={20}
														label={({ dataEntry }) => dataEntry.value}
														viewBoxSize={[150, 150]}
														center={[55, 75]}
														labelStyle={{
															fontSize: '16pt',
															fontFamily: 'sans-serif',
															fill: attemptData.cahpercentage > 75 ? '#37a169'
																: attemptData.cahpercentage > 50 ? "orange"
																	: "red"
														}}
														labelPosition={0}
													/> : <Image src={hi5} mt={3} />
											}
										</Box>

										<VStack alignItems="start" gap={2}>
											<Text fontWeight={600} fontSize="xl">
												You scored {attemptData.cahpercentage ?? "100"}%
											</Text>

											{
												resultData.length > 0 && resultData.map((result: any) =>
													<VStack alignItems="start" w="full">
														<Text fontSize={{ base: "xs", md: "md" }} fontWeight={500}>
															{result.questionData.text || result.questionData.prompt}
														</Text>
														<Box w="full">
															{
																getFailedQuestionResults(result.questionData.choices)
															}
														</Box>

														<Divider pt={2} />
													</VStack>
												)
											}
										</VStack>
									</HStack>

									<HStack
										mt={5}
										alignItems="end"
										display="block"
										w="100%"
									>
										<Button
											variant="outline"
											_hover={{ bgColor: "" }}
											disabled={completing}
											onClick={() => { navigateTo(0); }}
										>
											<HStack>
												<Text>Redo</Text>
												<Icon as={BsArrowCounterclockwise} />
											</HStack>
										</Button>

										<Button
											bg="brand.500"
											color="white"
											boxShadow="sm"
											_hover={{ bgColor: "" }}
											disabled={completing}
											onClick={() => {
												if (state?.from !== "task" && state?.from !== "notification") {
													isNull(courseId) ? navigateTo("/training") :
														navigateTo(`/training/course/${courseId}`);
												} else {
													navigateTo(-2);
												}
											}}
										>
											<HStack>
												{
													!completing ? <Icon as={BsArrowLeftCircle} /> :
														<Spinner size="sm" ml={3} />
												}

												{
													!isNull(state) && !isUndefined(state.from) ?
														state.from === "task" ? <Text>Back to Task</Text> :
															state.from === "notification" && <Text>Back to Notifications</Text>
														: <Text>Back to Modules</Text>
												}
											</HStack>
										</Button>
									</HStack>
								</Box>
							</Center>
						}

						{
							!loading &&
							moduleData.isViewingSlides &&
							!moduleData.isFinishedSlides && <SlidePlayer
								slide={slide}
								audioData={audioData}
								loading={loading}
							/>
						}

						{
							!loading && moduleData.isViewingQuestions && <QuestionPlayer
								question={question}
								choices={choices}
								image={image}
								loading={loading}
								updateData={updateQuestionData}
							/>
						}
					</Box>
				</VStack>
			</Box>
		</Box>
	)
}