export function CheckProductTierFlag(flag: any): boolean {
    if (flag === "1" || flag === undefined || flag === "") {
        return true;
    }

    return false;
}

export function CheckIsWithinProductTierLimit(value: any, limit: any): boolean {

    if (limit === "0" || limit === undefined || limit === "") {
        return true;
    }

    if (value < Number(limit)) {
        return true;
    }

    return false;
}

