import {
	Avatar,
	Box,
	Button,
	Drawer,
	DrawerContent,
	DrawerOverlay,
	Flex,
	HStack,
	Icon,
	Image,
	Menu,
	MenuButton,
	MenuDivider,
	MenuItem,
	MenuList,
	Text,
	useColorModeValue,
	useDisclosure,
	VStack,
} from "@chakra-ui/react";
import { BsBellFill, BsBoxArrowRight, BsFileEarmarkText, BsPersonCircle, BsQuestionCircle } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Logo from "../../assets/logos/obbi_logo_1051x640.png";
import { RootState } from "../../redux/rootReducer";
import { Sidebar } from "./Sidebar";
import { ToggleButton } from "./ToggleButton";
import { destroySession } from "../../features/auth/auth.actions";
import { useEffect, useState } from "react";
import { FaAppStore, FaGooglePlay } from "react-icons/fa";

export const Navbar: React.FC<any> = ({ isAdmin, loading }) => {
	const navigateTo = useNavigate();
	const { isOpen, onToggle, onClose } = useDisclosure();

	const user = useSelector((state: RootState) => state.authReducer.user);
	const notificationFolders = useSelector((state: RootState) => state.notificationReducer.notificationFolders);

	const [isMobile, setIsMobile] = useState(false);
	const [deviceType, setDeviceType] = useState<string>();

	const handleLogout = async () => {
		await destroySession()
			.finally(() => {
				localStorage.clear();
				window.location.href = `${process.env.REACT_APP_ENV_URL}/login`;
			});
	};

	useEffect(() => {
		const userAgent = window.navigator.userAgent;

		console.log('user agent', userAgent);
		const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

		setIsMobile(isMobileDevice);

		if (isMobileDevice) {
			if (/iPad|iPhone|iPod/.test(userAgent)) {
				setDeviceType('iOS');
			} else {
				setDeviceType('Android');
			}
		}
	}, []);

	return (
		<Box
			width="full"
			py="2"
			px={{ base: "4", md: "8" }}
			bg="bg-surface"
			boxShadow={useColorModeValue("sm", "sm-dark")}
			position="fixed"
			top={0}
			zIndex={999}
		>
			<Flex justify="space-between">
				<HStack>
					<ToggleButton
						isOpen={isOpen}
						aria-label="Open Menu"
						display="inline"
						color="gray.500"
						onClick={onToggle}
					/>

					<Drawer
						isOpen={isOpen}
						placement="left"
						onClose={onClose}
						isFullHeight
						preserveScrollBarGap
						trapFocus={true}
					>
						<DrawerOverlay />

						<DrawerContent>
							<Sidebar
								toggleMenu={onToggle}
								isAdmin={isAdmin}
							/>
						</DrawerContent>
					</Drawer>

					<Image
						src={Logo}
						alt="Obbi logo"
						className="hover-pop"
						width={isMobile ? "60px" : "80px"}
						cursor="pointer"
						display="inline-block"
						onClick={() => {
							navigateTo("/home");
						}}
					/>
				</HStack>

				<HStack gap={1}>
					<Button
						variant="outline"
						size="sm"
						_hover={{
							bg: ""
						}}
						onClick={() => {
							window.open('https://help.obbisolutions.com/', '_blank');
						}}
					>
						<Icon as={BsQuestionCircle}
							boxSize={5}
							color="gray.500"
						/>
					</Button>

					<Button
						size="sm"
						variant="outline"
						color={
							loading ? "gray.600" :
								Number(notificationFolders.totalCount) > 0 ? "red.500" : "gray.600"
						}
						borderColor={
							loading ? "gray.200" :
								Number(notificationFolders.totalCount) > 0 ? "red.100" : "gray.200"
						}
						_hover={{ bg: "" }}
						onClick={() => {
							navigateTo("/notifications");
						}}
					>
						<Icon
							as={BsBellFill}
							mr={Number(notificationFolders.totalCount) > 0 ? 1 : 0}
							color={
								loading ? "gray.600" :
									Number(notificationFolders.totalCount) > 0 ? "red.500" : "gray.600"
							}
						/>

						{
							Number(notificationFolders.totalCount) > 0 && notificationFolders.totalCount
						}
					</Button>

					<Flex alignItems={"center"}>
						<Menu>
							<MenuButton
								py={2}
								transition="all 0.3s"
								_focus={{ boxShadow: "none" }}
							>
								<HStack>
									<VStack
										display={{ base: "flex" }}
										alignItems="flex-start"
										spacing="1px"
									>
										<Avatar
											bg="brand.500"
											color="white"
											name={`${user.forename} ${user.surname}`}
											size="sm"
										/>
									</VStack>
								</HStack>
							</MenuButton>

							<MenuList
								bg={useColorModeValue("white", "gray.900")}
								borderColor={useColorModeValue("gray.200", "gray.700")}
							>
								<MenuItem onClick={() => navigateTo("profile")}>
									<HStack color="gray.600">
										<Icon as={BsPersonCircle} />
										<Text>My Profile</Text>
									</HStack>
								</MenuItem>

								<MenuItem onClick={() => navigateTo("/releaseNotes")}>
									<HStack color="gray.600">
										<Icon as={BsFileEarmarkText} />
										<Text>Release Notes</Text>
									</HStack>
								</MenuItem>

								<MenuDivider />

								{
									isMobile && <MenuItem onClick={() => deviceType === 'iOS' ?
										window.open('https://apps.apple.com/us/app/obbi/id6451968371', '_blank') :
										window.open('https://play.google.com/store/apps/details?id=com.obbisolutions.obbitasksv2&pcampaignid=web_share', '_blank')
									}>
										<HStack color="gray.600">
											{
												deviceType === 'iOS' ?
													<Icon as={FaAppStore} /> :
													<Icon as={FaGooglePlay} />
											}
											<Text>Get the App!</Text>
										</HStack>
									</MenuItem>
								}

								<MenuItem onClick={() => handleLogout()}>
									<HStack color="gray.600">
										<Icon as={BsBoxArrowRight} />
										<Text>Sign Out</Text>
									</HStack>
								</MenuItem>
							</MenuList>
						</Menu>
					</Flex>
				</HStack>
			</Flex>
		</Box>
	)
};