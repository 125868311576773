import {
	Badge,
	Box,
	HStack,
	Icon,
	SlideFade,
	Spacer,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	VStack,
} from "@chakra-ui/react";
import { BsCheckCircleFill, BsClockHistory, BsDash, BsExclamationCircleFill } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import { useMemo } from "react";
import { useTable, useSortBy, usePagination } from "react-table";

import { localDateTime, localDateTimeIgnoreToday, relativeTime } from "../../helpers/DayJsHelper";

export const TeamTaskHistoryTable: React.FC<any> = ({
	teamTaskId,
	currentItems,
	reportsNavData,
	loading,
	isGuided
}) => {
	const navigateTo = useNavigate();
	const { state }: any = useLocation();

	const columns = useMemo(() =>
		Object.keys(currentItems[0])
			.map((columnName: any) => (
				{
					Header: () => {
						switch (columnName) {
							case "targetUser": return "OBSERVED";
							case "lastReport": return isGuided ? "DATE OF OBSERVATION" : "LAST REPORT";
							case "signedOffBy": return isGuided ? "OBSERVED BY" : "SIGNED OFF BY";
							default: return columnName.toUpperCase();
						}
					}
					,
					accessor: columnName,
					disableSortBy: true,
					Cell: (cell: any) => {
						switch (columnName) {
							case "status": return cell.value === "passed" ? (
								<Badge
									colorScheme="green"
									backgroundColor="transparent"
									size="sm"
									variant="outline"

								>
									<Icon
										as={BsCheckCircleFill}
										mt="-2px"
										mr="2px"
										verticalAlign="middle"
									/>{" "}
									Passed
								</Badge>
							) : cell.value === "failed" ? (
								<Badge
									colorScheme="red"
									backgroundColor="transparent"
									size="sm"
									variant="outline"
								>
									<Icon
										as={BsExclamationCircleFill}
										mt="-2px"
										mr="2px"
										verticalAlign="middle"
									/>{" "}
									Failed
								</Badge>
							) : (
								""
							)
							case "lastReport": return <VStack alignItems="left">
								<Text color="gray.600">
									{localDateTime(cell.value)}
								</Text>

								<HStack>
									<Icon as={BsClockHistory} />
									<Text color="gray.500" fontSize="xs">
										{relativeTime(cell.value)}
									</Text>
								</HStack>
							</VStack>
							default: return <Text color="gray.600">{cell.value || <Icon as={BsDash} />}</Text>
						}
					}
				}
			)), []
	);

	function DataTable({
		data,
		columns,
		loading,
		pageCount: controlledPageCount,
		onHeaderClick,
	}: any) {
		const {
			getTableProps,
			getTableBodyProps,
			headerGroups,
			page,
			prepareRow
		} = useTable(
			{
				columns,
				data,
				manualPagination: true,
				manualSortBy: true,
				pageCount: controlledPageCount,
				onHeaderClick,
				autoResetPage: false,
				initialState: {
					hiddenColumns: ["id", "taskId"],
				},
			},
			useSortBy,
			usePagination
		);

		return (
			<SlideFade in={!loading} offsetY="20px">
				<VStack display={{ base: "flex", lg: "none" }} spacing={3} m={2}>
					{
						currentItems.map((report: any, i: number) => (
							<Box
								key={`taskHistoryItem_${i}`}
								w="full"
								p={2}
								className="hover-pop"
								cursor="pointer"
								backgroundColor={i % 2 ? "white" : "gray.50"}
								boxShadow="xs"
								border="1px solid"
								borderColor="gray.100"
								rounded="lg"
								onClick={() => {
									navigateTo(`/tasks/taskreport/${report.id}`, {
										state: {
											from: 'tasks',
											teamTaskId: teamTaskId,
											isGuided: state?.isGuided,
											navData: state?.navData,
											reportsNavData: reportsNavData
										}
									});
								}}
							>
								<Box verticalAlign="middle" mx={2}>
									<HStack>
										<Text
											fontSize="sm"
											fontWeight={500}
											color="gray.600"
											textAlign="start"
										>
											{localDateTimeIgnoreToday(report.lastReport)}
										</Text>

										<Spacer />

										{
											report.status === "passed" ? (
												<Badge
													colorScheme="brand"
													backgroundColor="transparent"
													size="sm"
													variant="outline"

												>
													<HStack>
														<Icon as={BsCheckCircleFill} verticalAlign="middle" />
														<Text>Passed</Text>
													</HStack>
												</Badge>
											) : (
												<Badge
													colorScheme="danger"
													backgroundColor="transparent"
													size="sm"
													variant="outline"

												>
													<HStack>
														<Icon as={BsExclamationCircleFill} verticalAlign="middle" />
														<Text>Failed</Text>
													</HStack>
												</Badge>
											)
										}
									</HStack>

									<Text
										fontSize="xs"
										fontWeight={500}
										color="gray.500"
										textAlign="start"
									>
										By {report.signedOffBy}, {relativeTime(report.lastReport)}
									</Text>
								</Box>
							</Box>
						))}
				</VStack>

				<Table
					{...getTableProps()}
					id="TaskHistoryTable"
					variant="striped"
					display={{ base: "none", lg: "inline-table" }}
				>
					<Thead>
						{
							headerGroups.map((headerGroup) => (
								<Tr {...headerGroup.getHeaderGroupProps()}>
									{
										headerGroup.headers.map((column) => (
											<Th
												{...column.getHeaderProps()}
												onClick={() => onHeaderClick(column)}
											>
												<Text fontWeight={700} color="gray.600">
													{column.render("Header")}
												</Text>
											</Th>
										))
									}
								</Tr>
							))
						}
					</Thead>

					<Tbody {...getTableBodyProps()}>
						{
							page.map((row, i) => {
								prepareRow(row);
								return (
									<Tr
										{...row.getRowProps()}
										key={i}
										className="hover-pop"
										cursor="pointer"
										borderBottom="1px solid"
										borderColor="gray.100"
										onClick={() => {
											navigateTo(`/tasks/taskreport/${row.values.id}`, {
												state: {
													from: 'tasks',
													teamTaskId: teamTaskId,
													navData: state?.navData,
													reportsNavData: reportsNavData
												}
											});
										}}
									>
										{
											row.cells.map((cell) => (
												<Td wordBreak="break-word" {...cell.getCellProps()}>
													{cell.render("Cell")}
												</Td>
											))
										}
									</Tr>
								);
							})
						}
					</Tbody>
				</Table>
			</SlideFade>
		);
	}

	return DataTable({
		data: currentItems,
		columns,
		loading,
		pageCount: reportsNavData?.pageCount
	});
};