import {
    Box,
    VStack,
    SimpleGrid,
    Text,
    Icon,
    Avatar,
    Input,
    Textarea,
    HStack,
    Skeleton,
    useToast,
    Divider,
    Button,
    ButtonGroup,
    Stack
} from "@chakra-ui/react";
import {
    AutoComplete,
    AutoCompleteInput,
    AutoCompleteItem,
    AutoCompleteList,
    AutoCompleteTag
} from "@choc-ui/chakra-autocomplete";
import { Fragment, useState } from "react";
import {
    BsArrowLeftCircle,
    BsArrowRightCircleFill,
    BsWrench,
    BsCheckCircleFill,
    BsClockHistory,
    BsConeStriped,
    BsHandThumbsUp,
    BsTrashFill
} from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import Contact from "../../types/Contact";

import { DropZone } from "../common/DropZone";
import { useStep } from "../common/progressSteps/useStep";
import { RadioCardGroup, RadioCard } from "../common/radioCardGroup/RadioCardGroup";

export const steps = [
    { title: 'Task Overview', description: 'Description' },
    { title: 'Step 2', description: 'Description' },
];

export const CreateNearMiss: React.FC<any> = ({ }) => {
    const { state }: any = useLocation();
    const toast = useToast();
    const navigateTo = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const [fileUploadLoading, setFileUploadLoading] = useState(false);

    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");

    const [priority, setPriority] = useState(false);
    const onChangePriority = (arg: boolean): void => {
        setPriority(arg);
    };

    const onChangeSubject =
        (event: React.ChangeEvent<HTMLInputElement>): void => {
            if (event.target.value.length === 200) {
                setValidSubjectLength(false);
            } else {
                setValidSubjectLength(true);
            }
            setSubject(event.target.value)
        };

    const onChangeMessage =
        (event: React.ChangeEvent<HTMLInputElement>): void => {
            if (event.target.value.length === 5000) {
                setValidMessageLength(false);
            } else {
                setValidMessageLength(true);
            }
            setMessage(event.target.value);
        };

    const [contacts, setContacts] = useState<Contact[]>([]);
    const [selectedContacts, setSelectedContacts] = useState<Contact[]>([]);

    const [teams, setTeams] = useState<any[]>([]);
    const [selectedTeams, setSelectedTeams] = useState<any[]>([]);

    const selectContact = (arg: Contact): void => {
        let currentSelection = selectedContacts;
        currentSelection.push(arg);

        let updatedContacts = contacts.filter(contact => arg !== contact);

        setContacts(updatedContacts);
        setSelectedContacts(currentSelection);
    };

    const deselectContact = (id: string): void => {
        let currentSelection = selectedContacts;
        const deselectedContact =
            currentSelection.filter(contact => id === contact.id);

        currentSelection =
            currentSelection.filter(contact => !deselectedContact.includes(contact));

        let updatedContacts = contacts;
        updatedContacts = updatedContacts.concat(deselectedContact).sort();

        setContacts(updatedContacts);
        setSelectedContacts(currentSelection);
    };

    const selectTeam = (arg: any): void => {
        let currentSelection = selectedTeams;
        currentSelection.push(arg);

        let updatedContacts = teams.filter(team => arg !== team);

        setTeams(updatedContacts);
        setSelectedTeams(currentSelection);
    };

    const deselectTeam = (id: string): void => {
        let currentSelection = selectedTeams;
        const deselectedTeam =
            currentSelection.filter(team => id === team.id);

        currentSelection =
            currentSelection.filter(team => !deselectedTeam.includes(team));

        let updatedTeams = teams;
        updatedTeams = updatedTeams.concat(deselectedTeam).sort();

        setTeams(updatedTeams);
        setSelectedTeams(currentSelection);
    };

    const getDefaultTeams = () => {
        let teams = [];
        for (let team of selectedTeams) {
            teams.push(`${team.label}:${team.id}`);
        }
        return teams;
    };

    const getDefaultContacts = () => {
        let contacts = [];
        for (let contact of selectedContacts) {
            contacts.push(`${contact.label}:${contact.id}`);
        }
        return contacts;
    };

    const [attachments, setAttachments] = useState<any[]>([]);

    const [validTeamRecipients, setValidTeamRecipients] = useState<boolean>(true);
    const [validRecipients, setValidRecipients] = useState<boolean>(true);
    const [validSubject, setValidSubject] = useState<boolean>(true);
    const [validSubjectLength, setValidSubjectLength] = useState<boolean>(true);
    const [validMessage, setValidMessage] = useState<boolean>(true);
    const [validMessageLength, setValidMessageLength] = useState<boolean>(true);

    const [currentStep, { setStep }] = useStep({ maxStep: steps.length, initialStep: 0 });

    const options = [
        {
            "title": "Normal",
            "description": "This is just for general information and is not urgent.",
            "icon": BsHandThumbsUp,
            "iconColor": "green.500"
        },
        {
            "title": "Critical",
            "description": "This is critical information that might impact on safety.",
            "icon": BsClockHistory,
            "iconColor": "red.500"
        }
    ];

    const onDropAction = async (file: any) => {
        if (attachments.length > 4) {
            toast({
                title: "You can only upload up to 5 attachments",
                description: "",
                status: "error",
                duration: 6000,
                isClosable: true
            });

            return;
        }

        setFileUploadLoading(true);
        let formData = new FormData();
        // formData.append("id", id);
        // formData.append("file", file);

        // await (formData)
        //     .then((attachment: any) => {
        //         
        //     })
        //     .catch((error) => {
        //         toast({
        //             title: error.response.data.message,
        //             description: "",
        //             status: "error",
        //             duration: 6000,
        //             isClosable: true
        //         });
        //     })
        //     .finally(() => setFileUploadLoading(false));
    }

    return (
        <Box>
            <Button
                w="fit-content"
                mb={2}
                leftIcon={<BsArrowLeftCircle />}
                size="md"
                variant="outline"
                color="gray.500"
                fontWeight={600}
                onClick={() => {
                    navigateTo(`/incidents`);
                }}
            >
                Back to Incidents
            </Button>

            <Skeleton isLoaded={!loading} rounded="lg">
                <Box
                    p={5}
                    pb={16}
                    bg="white"
                    rounded="lg"
                    boxShadow="lg"
                >
                    <HStack>
                        <Text
                            fontWeight={{ base: 600, lg: 700 }}
                            fontSize={{ base: "lg", lg: '2xl' }}
                            color="gray.700"
                            mb={5}
                        >
                            <Icon
                                as={BsConeStriped}
                                ml={5}
                                mr={5}
                                mt={-1}
                                verticalAlign="middle"
                                fontSize="4xl"
                                color="green.500"
                            /> Report Near Miss
                        </Text>
                    </HStack>

                    <Divider
                        w="unset"
                        ml={-5}
                        mr={-5}
                    />

                    <VStack
                        m={5}
                        mb={12}
                        display="flex"
                        alignItems="baseline"
                        spacing={8}
                    >
                        <SimpleGrid
                            w="full"
                            spacing={8}
                            columns={{ base: 1, sm: 1, md: 2, lg: 2, xl: 2 }}
                        >
                            <Box w="full">
                                <Text
                                    textAlign="start"
                                    fontWeight={500}
                                    fontSize="lg"
                                    color="gray.700"
                                >
                                    Name
                                </Text>
                                <Input
                                    placeholder='Enter action name'
                                    onChange={onChangeSubject}
                                    value={subject}
                                    mt={2}
                                    maxLength={200}
                                />
                                {
                                    !validSubject ?
                                        <Text
                                            fontWeight={500}
                                            fontSize="md"
                                            color="red.500"
                                            textAlign="start"
                                            mt={1}
                                        >
                                            You must provide an action name
                                        </Text> : <Fragment></Fragment>
                                }

                                {
                                    !validSubjectLength ?
                                        <Text
                                            fontWeight={500}
                                            fontSize="md"
                                            color="red.500"
                                            textAlign="start"
                                            mt={1}
                                        >
                                            Name cannot exceed 200 characters
                                        </Text> : <Fragment></Fragment>
                                }

                                <Text
                                    textAlign="start"
                                    fontWeight={500}
                                    fontSize="lg"
                                    color="gray.700"
                                    mt={8}
                                >
                                    Details
                                </Text>
                                <Textarea
                                    placeholder='Enter action details here'
                                    //onChange={onChangeMessage}
                                    rows={5}
                                    value={message}
                                    mt={2}
                                    maxLength={5000}
                                />
                                {
                                    !validMessage ?
                                        <Text
                                            fontWeight={500}
                                            fontSize="md"
                                            color="red.500"
                                            textAlign="start"
                                            mt={1}
                                        >
                                            You must provide action details
                                        </Text> : <Fragment></Fragment>
                                }

                                {
                                    !validMessageLength ?
                                        <Text
                                            fontWeight={500}
                                            fontSize="md"
                                            color="red.500"
                                            textAlign="start"
                                            mt={1}
                                        >
                                            Details cannot exceed 5000 characters
                                        </Text> : <Fragment></Fragment>
                                }

                                <Box w="full" textAlign="start" mt={8}>
                                    <Text
                                        fontWeight={500}
                                        fontSize="lg"
                                        color="gray.700"
                                        textAlign="start"
                                    >
                                        Assign to Team
                                    </Text>

                                    <AutoComplete
                                        emptyState={false}
                                        openOnFocus
                                        multiple={false}
                                        maxSuggestions={10}
                                        onTagRemoved={(removedTag) => {
                                            const teamToRemove = removedTag.substring(removedTag.indexOf(":") + 1);
                                            deselectTeam(teamToRemove);
                                        }}
                                        defaultValues={getDefaultTeams()}
                                    >
                                        <AutoCompleteInput
                                            id="TeamAutoCompleteInput"
                                            variant="filled"
                                            borderColor="gray.200"
                                            placeholder="Start typing a team name"
                                            mt={2}
                                            pt={selectedTeams.length > 0 ? 3 : 1}
                                        >
                                            {
                                                ({ tags }) =>
                                                    tags.map((tag, tid) => (
                                                        <AutoCompleteTag
                                                            backgroundColor="brand.500"
                                                            color="white"
                                                            fontWeight={500}
                                                            p={2}
                                                            key={tid}
                                                            label={tag.label.substring(0, tag.label.indexOf(':'))}
                                                            onRemove={() => {
                                                                tag.onRemove();
                                                            }}
                                                        />
                                                    ))
                                            }
                                        </AutoCompleteInput>
                                        <AutoCompleteList>
                                            {
                                                teams.length > 0 &&
                                                teams.map((team: any) => (
                                                    <AutoCompleteItem
                                                        id={team.id}
                                                        key={team.id}
                                                        value={team.label + ":" + team.id}
                                                        align="center"
                                                        onClick={() => {
                                                            selectTeam(team);
                                                        }}
                                                        onKeyUp={() => {
                                                            selectTeam(team);
                                                        }}
                                                    >
                                                        <Avatar
                                                            size="sm"
                                                            name={team.label}
                                                            src={team.image || ""}
                                                        />
                                                        <Text ml="4">{team.label}</Text>
                                                    </AutoCompleteItem>
                                                ))
                                            }
                                        </AutoCompleteList>
                                    </AutoComplete>

                                    {!validRecipients && !validTeamRecipients ?
                                        <Text
                                            fontWeight={500}
                                            fontSize="md"
                                            color="red.500"
                                            textAlign="start"
                                            mt={1}
                                        >
                                            You must select at least one team or individual recipient
                                        </Text> : <Fragment></Fragment>
                                    }

                                    <Text
                                        fontWeight={500}
                                        fontSize="lg"
                                        color="gray.700"
                                        textAlign="start"
                                        mt={8}
                                    >
                                        Assign to an Individual
                                    </Text>

                                    <AutoComplete
                                        emptyState={false}
                                        openOnFocus
                                        multiple={false}
                                        maxSuggestions={5}
                                        //onChange={vals => console.log(vals)}
                                        onTagRemoved={(removedTag) => {
                                            const contactToRemove = removedTag.substring(removedTag.indexOf(":") + 1);
                                            deselectContact(contactToRemove);
                                        }}
                                        defaultValues={getDefaultContacts()}
                                    >
                                        <AutoCompleteInput
                                            id="UserAutoCompleteInput"
                                            variant="filled"
                                            borderColor="gray.200"
                                            placeholder="Start typing a name"
                                            mt={2}
                                            pt={selectedContacts.length > 0 ? 3 : 1}
                                        >
                                            {({ tags }) =>
                                                tags.map((tag, tid) => (
                                                    <AutoCompleteTag
                                                        backgroundColor="brand.500"
                                                        color="white"
                                                        fontWeight={500}
                                                        p={2}
                                                        key={tid}
                                                        label={tag.label.substring(0, tag.label.indexOf(':'))}
                                                        onRemove={() => {
                                                            tag.onRemove();
                                                        }}
                                                    />
                                                ))
                                            }
                                        </AutoCompleteInput>
                                        <AutoCompleteList>
                                            {
                                                contacts.length > 0 &&
                                                contacts.map((contact: Contact) => (
                                                    <AutoCompleteItem
                                                        id={contact.id}
                                                        key={contact.id}
                                                        value={contact.label + ":" + contact.id}
                                                        align="center"
                                                        onClick={() => {
                                                            selectContact(contact);
                                                        }}
                                                        onKeyUp={() => {
                                                            selectContact(contact);
                                                        }}
                                                    >
                                                        <Avatar
                                                            size="sm"
                                                            name={contact.label}
                                                            src={contact.image || ""}
                                                        />
                                                        <Text ml="4">{contact.label}</Text>
                                                    </AutoCompleteItem>
                                                ))
                                            }
                                        </AutoCompleteList>
                                    </AutoComplete>
                                    {!validRecipients && !validTeamRecipients ?
                                        <Text
                                            fontWeight={500}
                                            fontSize="md"
                                            color="red.500"
                                            textAlign="start"
                                            mt={1}
                                        >
                                            You must select at least one team or individual recipient
                                        </Text> : <Fragment></Fragment>
                                    }


                                </Box>
                            </Box>

                            <Box w="full">
                                <Text
                                    fontWeight={500}
                                    fontSize="lg"
                                    color="gray.700"
                                    textAlign="start"
                                >
                                    Action Priority
                                </Text>

                                <RadioCardGroup
                                    w="full"
                                    flexDirection={{ base: "column", md: "row" }}
                                    alignItems="center"
                                    gap={{ base: 2, md: 5 }}
                                    onChange={() => onChangePriority(!priority)}
                                    value={priority === true ? "Critical" : "Normal"}
                                >
                                    {
                                        options.map((option) => (
                                            <RadioCard
                                                key={option.title}
                                                value={option.title}
                                                className="hover-pop"
                                            >
                                                <Text
                                                    color="gray.700"
                                                    fontWeight="500"
                                                    display='flex'
                                                    alignItems='baseline'
                                                    mb={2}
                                                >
                                                    <Icon
                                                        as={option.icon}
                                                        mr={2}
                                                        color={option.iconColor}
                                                    ></Icon> {option.title}
                                                </Text>

                                                <Text textAlign='start' color="gray.500" fontSize="sm">
                                                    {option.description}
                                                </Text>
                                            </RadioCard>
                                        ))
                                    }
                                </RadioCardGroup>

                                <Box w="full" textAlign="start">
                                    <Text
                                        fontWeight={500}
                                        fontSize="lg"
                                        color="gray.700"
                                        mb={2}
                                        mt={8}
                                    >
                                        Upload attachments
                                    </Text>

                                    <DropZone
                                        onDropAction={onDropAction}
                                        w="full"
                                        py={6}
                                        fileUploadLoading={fileUploadLoading}
                                    />

                                    {
                                        //attachments.length > 0 ?

                                        <Box
                                            w="100%"
                                            minH="97px"
                                            p={5}
                                            mt={3}
                                            bgColor="gray.50"
                                            borderRadius="8px"
                                        >
                                            <SimpleGrid columns={2} spacing={2}>
                                                {/* {
                                                            attachments.map((a: any, i: string) => {
                                                                return (
                                                                    <Box
                                                                        boxShadow="md"
                                                                        rounded="md"
                                                                        bg="gray.700"
                                                                    >
                                                                        <Image
                                                                            src={a.thumbnail}
                                                                            alt={a.fileName}
                                                                            rounded="md"
                                                                            p={1}
                                                                        />

                                                                        <HStack
                                                                            p={4}
                                                                            gap={5}
                                                                            justifyContent="space-between"
                                                                            wordBreak="break-all"
                                                                        >
                                                                            <Text color="white">
                                                                                {a.fileName}
                                                                            </Text>
                                                                            <Icon
                                                                                as={BsTrashFill}
                                                                                size="lg"
                                                                                color="white"
                                                                                className="hover-pop"
                                                                                cursor="pointer"
                                                                                onClick={() => {
                                                                                   // deleteAttachment(a.path);
                                                                                }}
                                                                            />
                                                                        </HStack>
                                                                    </Box>
                                                                )
                                                            })
                                                        } */}
                                            </SimpleGrid>
                                        </Box>

                                        // : <Fragment></Fragment>
                                    }
                                </Box>
                            </Box>
                        </SimpleGrid>
                    </VStack>

                    <Divider
                        w="unset"
                        ml={-5}
                        mr={-5}
                    />

                    <Box mt={5}>
                        <Stack float={{ md: "left" }}>
                            <Button
                                size="md"
                                rightIcon={currentStep === 1 ? <BsCheckCircleFill /> : <BsArrowRightCircleFill />}
                                color="white"
                                bg="green.500"
                                _hover={{
                                    bg: '#248451',
                                }}
                                variant="solid"
                                onClick={() => {
                                    // if (currentStep === 1) {
                                    //     send();
                                    // } else {
                                    //     if (canProceed())
                                    //         setStep(currentStep + 1);
                                    // }
                                }}
                            >
                                {
                                    {
                                        0: "Review and create",
                                        1: "Create"
                                    }[currentStep]
                                }
                            </Button>
                        </Stack>

                        <HStack
                            spacing={2}
                            mt={{ base: 3, md: 2 }}
                            float={{ base: "left", md: "right" }}
                        >
                            <ButtonGroup gap={1}>
                                {
                                    currentStep > 0 ?
                                        <Button
                                            leftIcon={<BsArrowLeftCircle />}
                                            size="sm"
                                            variant="outline"
                                            color="gray.500"
                                            borderColor="gray.500"
                                            fontWeight={600}
                                            onClick={() => {
                                                setStep(currentStep - 1);
                                            }}
                                        >
                                            <Text display={{ base: "none", md: "flex" }}>Go back</Text>
                                            <Text display={{ base: "flex", md: "none" }}>Back</Text>
                                        </Button>
                                        : <Fragment></Fragment>
                                }

                                <Button
                                    rightIcon={<BsTrashFill />}
                                    size="sm"
                                    variant="outline"
                                    color="red.500"
                                    borderColor="red.500"
                                    fontWeight={600}
                                // onClick={() => onOpen()}
                                >
                                    Cancel
                                </Button>
                            </ButtonGroup>
                        </HStack>
                    </Box>
                </Box>
            </Skeleton>
        </Box>
    )
}