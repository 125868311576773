import {
    Box,
    Button,
    Center,
    Divider,
    HStack,
    Icon,
    Image,
    Spacer,
    Square,
    Stack,
    Text,
    VStack
} from "@chakra-ui/react";

import hammer from "../../assets/images/hammer.svg";
import { BsLockFill, BsTelephone } from "react-icons/bs";
import { FaLock } from "react-icons/fa";
import { BiLinkAlt } from "react-icons/bi";

export const ContactSales: React.FC<any> = ({
    title,
    lineOne,
    lineTwo,
    image,
    buttonOneText,
    buttonOneLink,
    buttonTwoText,
    buttonTwoLink,
    limitReached,
    limit,
    limitType,
    attributeIcon1,
    attributeTitle1,
    attributeText1,
    attributeIcon2,
    attributeTitle2,
    attributeText2,
    attributeIcon3,
    attributeTitle3,
    attributeText3
}) => {
    return (
        <VStack alignItems="start">
            <Box
                borderRadius={8}
                borderColor="#E7E1F8"
                borderWidth={1}
                py={1}
                px={3}
                backgroundColor="#F9F7FC"
                marginBottom={3}
            >

                <HStack>
                    <FaLock />
                    <Text
                        fontWeight={600}
                    >
                        Pro feature
                    </Text>
                </HStack>
            </Box>
            {limitReached &&
                <Box
                    w="full"
                    p={3}
                    bgGradient='linear(to-r, green.500, green.400)'
                    rounded="lg"
                >
                    <HStack w="full" gap={3}>
                        <Square
                            size="10"
                            rounded="lg"
                            bg="white"
                        >
                            <Icon
                                as={BsLockFill}
                                color="green.500"
                                boxSize="5" />
                        </Square>

                        <Text
                            color="white"
                            fontSize="xl"
                            fontWeight={600}
                        >
                            You have reached the limit of {limit} {limitType} for your organisation!
                        </Text>

                        <Spacer />

                        <Button
                            colorScheme="white"
                            color="green.500"
                            variant="outline"
                            fontWeight={500}
                            _hover={{
                                bg: "green.400",
                                color: "white"
                            }}
                            _active={{
                                bg: "green.400",
                                color: "white"
                            }}
                        >
                            Contact Sales
                        </Button>
                    </HStack>
                </Box>
            }

            <Box m={5}>
                <Stack direction={{ base: "column", md: "row" }} w="full">
                    <VStack
                        gap={5}
                        alignItems="start"
                        w={{ base: "full", md: "50%" }}
                    >
                        <Text
                            color="gray.600"
                            fontSize="3xl"
                            fontWeight={600}
                        >
                            {title}
                        </Text>

                        <Text
                            color="gray.500"
                            fontSize="md"
                            fontWeight={500}
                        >
                            {lineOne}
                        </Text>

                        <Text
                            color="gray.500"
                            fontSize="md"
                            fontWeight={500}
                        >
                            {lineTwo}
                        </Text>

                        <HStack>
                            <Button
                                colorScheme="brand"
                                _hover={{
                                    bg: "#248451"
                                }}
                                onClick={buttonOneLink ? () => window.window.location.href = buttonOneLink : () => { }}
                            >
                                <HStack>
                                    <BsTelephone />
                                    <Text>
                                        {buttonOneText}
                                    </Text>
                                </HStack>
                            </Button>

                            <Button
                                color="brand.500"
                                variant="outline"
                                onClick={buttonTwoLink ? () => window.window.location.href = buttonTwoLink : () => { }}
                            >
                                <HStack>
                                    <BiLinkAlt size={20} />
                                    <Text>
                                        {buttonTwoText}
                                    </Text>
                                </HStack>
                            </Button>
                        </HStack>
                    </VStack>

                    <Spacer />

                    <Box
                        display={{ base: "none", md: "block" }}
                        w={{ base: "full", md: "40%" }}
                        bg="gray.50"
                        rounded="lg"
                        border="1px solid"
                        borderColor="gray.200"
                    >
                        <Center>
                            <Image
                                src={image || hammer}
                                w='fill-available'
                                p={8}
                            />
                        </Center>
                    </Box>
                </Stack>
            </Box>

            {attributeTitle1 &&
                <>
                    <Divider py={5} />

                    <Stack
                        pt={10}
                        gap={5}
                        direction={{ base: "column", md: "row" }}
                        w="full"
                    >
                        <VStack
                            flex={1}
                            alignItems={'start'}
                        >
                            {attributeIcon1 &&
                                <Box
                                    borderWidth={1}
                                    borderRadius={'50%'}
                                    borderColor="green.100"
                                    p={2}
                                    backgroundColor='green.50'
                                >
                                    <Center>
                                        {attributeIcon1}
                                    </Center>
                                </Box>
                            }
                            <Text
                                color="gray.600"
                                fontWeight={600}
                            >
                                {attributeTitle1}
                            </Text>
                            <Text
                                color="gray.500"
                                fontWeight={500}
                                fontSize="xs"
                            >
                                {attributeText1}
                            </Text>
                        </VStack>
                        <VStack
                            flex={1}
                            alignItems={'start'}
                        >
                            {attributeIcon2 &&
                                <Box
                                    borderWidth={1}
                                    borderRadius={'50%'}
                                    borderColor="purple.100"
                                    p={2}
                                    backgroundColor='purple.50'
                                >
                                    <Center>
                                        {attributeIcon2}
                                    </Center>
                                </Box>
                            }
                            <Text
                                color="gray.600"
                                fontWeight={600}
                            >
                                {attributeTitle2}
                            </Text>
                            <Text
                                color="gray.500"
                                fontWeight={500}
                                fontSize="xs"
                            >
                                {attributeText2}
                            </Text>
                        </VStack>
                        <VStack
                            alignItems={'start'}
                            flex={1}
                        >
                            {attributeIcon3 &&
                                <Box
                                    borderWidth={1}
                                    borderRadius={'50%'}
                                    borderColor="blue.100"
                                    p={2}
                                    backgroundColor='blue.50'
                                >
                                    <Center>
                                        {attributeIcon3}
                                    </Center>
                                </Box>
                            }
                            <Text
                                color="gray.600"
                                fontWeight={600}
                            >
                                {attributeTitle3}
                            </Text>
                            <Text
                                color="gray.500"
                                fontWeight={500}
                                fontSize="xs"
                            >
                                {attributeText3}
                            </Text>
                        </VStack>

                    </Stack>
                </>
            }

        </VStack>
    )
};