import { useMemo, useState } from 'react';
import {
    Box,
    Icon,
    Text,
    VStack,
    Image,
    SlideFade,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    SimpleGrid,
    HStack,
    Avatar,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay
} from '@chakra-ui/react';
import {
    BsCheckCircleFill,
    BsExclamationCircleFill
} from 'react-icons/bs';

import { useTable, useSortBy, usePagination } from 'react-table';
import { Pager } from '../common/Pager';
import { isEmpty } from 'lodash';
// import Lightbox from 'react-image-lightbox';
import { getGuidedReportItemAttachment, getReportItemAttachment } from '../../features/tasks/task.actions';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from "rehype-raw";
import { localDateTimeIgnoreToday } from '../../helpers/DayJsHelper';

export const TaskReportTable: React.FC<any> = ({
    currentItems,
    pageCount,
    pageIndex,
    setPageIndex,
    loading,
    isGuided
}) => {
    const [activeImage, setActiveImage] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    const getCurrentAttachment = async (attachment: any) => {
        let currentAttchment: any = {};

        if (isGuided) {
            currentAttchment = await getGuidedReportItemAttachment(
                attachment.tthid,
                attachment.tiid,
                attachment.ttihapath,
                true,
                false
            );
        } else {
            currentAttchment = await getReportItemAttachment(
                attachment.tthid,
                attachment.tiid,
                attachment.ttihapath,
                true,
                false
            );
        }

        setActiveImage(currentAttchment.datauri);
        console.log(activeImage);
        setIsOpen(true);
    };

    const renderImage = () => {
        return <Modal
            size="4xl"
            isOpen={isOpen}
            onClose={() => { setIsOpen(false) }}
        >
            <ModalOverlay />

            <ModalContent mt={0}>
                <ModalHeader />

                <ModalCloseButton
                    _hover={{ bg: "" }}
                    _active={{ bg: "" }}
                />

                <ModalBody>
                    <Image
                        src={activeImage}
                        m="5px auto 15px auto"
                        p={0}
                    />
                </ModalBody>
            </ModalContent>
        </Modal>
    };

    const columns = useMemo(
        () => [
            {
                Header: "",
                accessor: "id",
                disableSortBy: true,
            },
            {
                Header: "ITEM",
                accessor: "item",
                disableSortBy: true,
                Cell: (cell: any) => (<VStack alignItems="start">
                    <Text minW="120px" color="gray.600" whiteSpace="pre-wrap">
                        {cell?.value.description || ""}
                    </Text>

                    <Text minW="120px" whiteSpace="pre-wrap" color="gray.500" hidden={isEmpty(cell?.value.feedback)}>
                        <ReactMarkdown
                            children={cell?.value.feedback}
                            className="task-report"
                            rehypePlugins={[rehypeRaw] as any}
                        />
                    </Text>

                    <SimpleGrid
                        columns={{ base: 1, sm: 2, md: 2, lg: 3, xl: 4 }}
                        spacing={2}
                    >
                        {
                            cell?.value.attachments.map((a: any, i: number) => {
                                return (
                                    <Image
                                        className="hover-pop"
                                        cursor="pointer"
                                        src={a.datauri}
                                        p={1} h="100px" m="0 auto" rounded="md"
                                        fallbackSrc='https://via.placeholder.com/160'
                                        alt={a.fileName}
                                        boxSize='160px'
                                        boxShadow="xs"
                                        objectFit="cover"
                                        border="1px solid"
                                        borderColor="gray.200"
                                        onClick={() => {
                                            getCurrentAttachment(a);
                                        }}
                                    />
                                );
                            })
                        }
                    </SimpleGrid>
                </VStack>)
            },
            {
                Header: "STATUS",
                accessor: "status",
                disableSortBy: true,
                Cell: (cell: any) => (
                    <HStack>
                        {
                            (cell.value === "Passed" || cell.value === "Failed") && <Icon
                                as={
                                    cell.value === "Failed" ? BsExclamationCircleFill :
                                        cell.value === "Passed" ? BsCheckCircleFill : undefined
                                }
                                color={
                                    cell.value === "Failed" ? "red.500" :
                                        cell.value === "Passed" ? "green.500" : "gray.600"
                                }
                            />
                        }

                        <Text color="gray.600">
                            {cell.value}
                        </Text>
                    </HStack>
                )
            },
            {
                Header: "DATA",
                accessor: "data",
                disableSortBy: true,
                Cell: (cell: any) => (<Text color="gray.600">{cell?.value || "-"}</Text>)
            },
            {
                Header: "NOTES",
                accessor: "notes",
                disableSortBy: true,
                Cell: (cell: any) => (
                    <>
                        {
                            cell.value ?
                                <Text color="red.500">{cell?.value || ""}</Text>
                                :
                                <Text color="gray.600">-</Text>
                        }

                    </>
                )
            },
            {
                Header: "SIGNED OFF",
                accessor: "signedOffBy",
                disableSortBy: true,

                Cell: (cell: any) => (
                    <>
                        {(cell.value?.date != "" || cell.value?.by != "") ?
                            <HStack alignItems="start">
                                {
                                    <Avatar name={cell.value.by || ""} size="xs" color="white" />
                                }

                                <Text fontSize="xs" color="gray.500">
                                    {localDateTimeIgnoreToday(cell.value.date)}
                                </Text>
                            </HStack>
                            :
                            <Text color="gray.600">
                                -
                            </Text>
                        }
                    </>
                )
            },
        ], []
    );

    function DataTable({
        data,
        columns,
        loading,
        pageCount: controlledPageCount,
        onHeaderClick,
    }: any) {
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            page,
            prepareRow,
            pageCount,
        } = useTable(
            {
                columns,
                data,
                manualPagination: true,
                manualSortBy: true,
                pageCount: controlledPageCount,
                onHeaderClick,
                autoResetPage: false,
                initialState: {
                    hiddenColumns: ["id"],
                },
            },
            useSortBy,
            usePagination
        );

        return (
            <Box>
                {/* {
                    isOpen && <Lightbox
                        mainSrc={activeImage}
                        onCloseRequest={() => setIsOpen(false)}
                    />
                } */}

                <SlideFade in={!loading} offsetY="20px">
                    <Table
                        {...getTableProps()}
                        id="TaskReportTable"
                        variant="striped"
                    >
                        <Thead>
                            {headerGroups.map((headerGroup) => (
                                <Tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <Th
                                            display={{ base: column.id === "signedOffBy" || column.id === "notes" ? "none" : "", lg: "revert" }}
                                            {...column.getHeaderProps()}
                                            onClick={() => onHeaderClick(column)}
                                        >
                                            <Text fontWeight={700} color="gray.600">
                                                {column.render("Header")}
                                            </Text>
                                        </Th>
                                    ))}
                                </Tr>
                            ))}
                        </Thead>
                        <Tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <Tr
                                        {...row.getRowProps()}
                                        key={i}
                                    >
                                        {row.cells.map((cell) => (
                                            <Td
                                                display={{ base: cell.column.id === "signedOffBy" || cell.column.id === "notes" ? "none" : "", lg: "revert" }}
                                                {...cell.getCellProps()}
                                                wordBreak="break-word"
                                            >
                                                {cell.render("Cell")}
                                            </Td>
                                        ))}
                                    </Tr>
                                );
                            })}
                        </Tbody>
                    </Table>

                    <Box
                        mt={3}
                        textAlign="center"
                        display={pageCount > 1 ? "block" : "none"}
                    >
                        <Pager
                            pageCount={pageCount}
                            pageIndex={pageIndex}
                            setPageIndex={setPageIndex}
                        />
                    </Box>
                </SlideFade>

                {renderImage()}
            </Box>
        );
    }

    return DataTable({
        data: currentItems,
        columns,
        loading,
        pageCount,
    });
};