import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	Badge,
	Box,
	Button,
	Center,
	Divider,
	HStack,
	Icon,
	Image,
	Progress,
	Skeleton,
	SlideFade,
	Spacer,
	Stack,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	useColorModeValue,
	useToast,
	VStack,
} from '@chakra-ui/react';
import {
	BsArrowCounterclockwise,
	BsArrowLeftCircle,
	BsCheckCircleFill,
	BsDash,
	BsExclamationCircleFill,
	BsEye,
	BsPlayBtn,
	BsPlayCircle
} from 'react-icons/bs';

import { VscMortarBoard } from 'react-icons/vsc';
import { useTable, useSortBy, usePagination } from 'react-table';
import { getCourse, getCourseImage } from '../../features/training/training.actions';
import { isEmpty, isNull } from 'lodash';
import { localDate } from '../../helpers/DayJsHelper';

export const Course = () => {
	const navigateTo = useNavigate();
	const { id } = useParams();

	const [loading, setLoading] = useState<boolean>(false);

	const toast = useToast();
	const [currentItems, setCurrentItems] = useState([]);
	const [courseData, setCourseData] = useState({
		name: "",
		image: "",
		validcoursepercent: 0,
		validcoursecount: 0,
		coursecount: 0
	});

	const columns = useMemo(
		() => [
			{
				Header: "",
				accessor: "cid",
				disableSortBy: true
			},
			{
				Header: "MODULE",
				accessor: "cname",
				sortType: "basic",
				Cell: (cell: any) => <Text color="gray.600">{cell.value || "no title"}</Text>
			},
			{
				Header: "STATUS",
				accessor: "state",
				sortType: "basic",
				Cell: (cell: any) => {
					return <Badge
						colorScheme={
							cell.value === "Passed" ? "brand"
								: cell.value === "Invalid" ? "danger"
									: "gray"
						}
						size="sm"
						variant="outline"

					>
						<Icon
							as={
								cell.value === "Awaiting Observation" ? BsEye :
									cell.value === "Passed" ? BsCheckCircleFill
										: cell.value === "Invalid" ? BsExclamationCircleFill
											: cell.value === "In Progress" ? BsPlayCircle
												: BsDash
							}
							mt="-2px"
							mr="2px"
							verticalAlign="middle"
						/> {cell.value}
					</Badge>
				}
			},
			{
				Header: "CURRENT ATTEMPT STARTED",
				accessor: "cadatestarted",
				sortType: "basic",
				Cell: (cell: any) => !isNull(cell.value) ? <Text color="gray.600">
					{localDate(cell.value)}
				</Text> : <Icon as={BsDash} />
			},
			{
				Header: "LAST COMPLETED",
				accessor: "lastSubmittedData",
				sortType: "basic",
				Cell: (cell: any) => !isNull(cell.value.date) ? <Text color="gray.600">
					{cell.value.passed} {localDate(cell.value.date)}
				</Text> : <Icon as={BsDash} />
			},
			{
				Header: "",
				accessor: "action",
				sortType: "basic",
				Cell: (cell: any) => {
					if (cell.value.status === "Unpublished") {
						return <Button disabled w="full" size="sm">
							Unpublished
						</Button>
					} else {
						return <Button
							w="full"
							size="sm"
							color="gray.600"
							onClick={() => navigateTo(`/training/module/${cell.value.moduleId}`)}
						>
							{cell.value.status} <Icon as={cell.value.status === "Redo" ? BsArrowCounterclockwise : BsPlayBtn} ml={2} />
						</Button>
					}
				}
			}
		], []);

	const fetchData = useCallback(async () => {
		setLoading(true);

		await getCourse(Number(id))
			.then(async (res: any) => {
				let formattedCurrentItems = res.courses
					.map((course: any) => {
						let status = "Start";
						let state = "In Progress";

						let lastSubmittedData = {
							passed: false,
							date: course.cahdatecompleted
						};

						if (course.cpublished === "0") {
							status = "Unpublished";
						} else if (course.caid !== null) {
							status = "Continue";
						} else if (course.cahid !== null) {
							status = "Redo";
						}

						//caid is null = no attempt
						//cah is null = no historical attempt
						if (Number(course.caid) > 0 && Number(course.cawaitingfortask) > 0) {
							state = "Awaiting Observation";
						} else if (isNull(course.cahid) && isNull(course.caid)) {
							state = "Not Started";
						} else if (course.caid != null) {
							state = "In Progress";
						} else if (course.valid) {
							state = "Passed";
						} else if (!course.valid) {
							state = "Invalid";
						}

						return {
							...course,
							state,
							lastSubmittedData,
							action: { moduleId: course.cid, status: status }
						}
					});

				setCurrentItems(formattedCurrentItems);

				const courseImage = await getCourseImage(res.cmodid, true, false);
				setCourseData({
					name: res.cmodname,
					image: courseImage || "",
					validcoursepercent: res.validcoursepercent,
					validcoursecount: res.validcoursecount,
					coursecount: res.coursecount
				});
			})
			.catch((error: any) => {
				console.log(error);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	useEffect(() => { fetchData() }, [fetchData]);

	function DataTable({
		data,
		columns,
		onHeaderClick
	}: any) {
		const {
			getTableProps,
			getTableBodyProps,
			headerGroups,
			page,
			prepareRow } = useTable({
				columns,
				data,
				manualPagination: true,
				manualSortBy: true,
				onHeaderClick,
				autoResetPage: false,
				initialState: {
					hiddenColumns: ["cid"]
				}
			},
				useSortBy,
				usePagination
			);

		return (
			<Box m={{ base: 0, md: 5 }}>
				<Button
					display={{ base: "none", md: "unset" }}
					w="fit-content"
					mb={2}
					size="sm"
					bg="white"
					boxShadow="sm"
					color="gray.600"
					fontWeight={600}
					_hover={{ bg: "gray.100" }}
					onClick={() => {
						navigateTo(`/training`);
					}}
				>
					<HStack>
						<Icon as={BsArrowLeftCircle} />
						<Text>
							Back to Courses
						</Text>
					</HStack>
				</Button>

				<Box
					px={{ base: 0, md: 5 }}
					pt={5}
					pb={3}
					bg={useColorModeValue("white", "gray.900")}
					boxShadow={"lg"}
					rounded={"lg"}
				>
					<Button
						display={{ base: "unset", md: "none" }}
						mx={3}
						mt={2}
						mb={8}
						size="sm"
						color="gray.600"
						variant="link"
						fontWeight={600}
						onClick={() => {
							navigateTo(`/training`);
						}}
					>
						<HStack>
							<Icon as={BsArrowLeftCircle} />
							<Text>
								Back to Courses
							</Text>
						</HStack>
					</Button>

					<HStack gap={4} mx={5} mb={5}>
						<Icon
							as={VscMortarBoard}
							verticalAlign="middle"
							color="green.500"
							fontSize="4xl"
						/>
						<SlideFade in={!loading} offsetY="20px">
							<Text
								fontWeight={{ base: 600, lg: 700 }}
								fontSize={{ base: "2xl", lg: '2xl' }}
								color="gray.700"
							>
								{courseData.name}
							</Text>
						</SlideFade>
					</HStack>

					<Divider width="unset" mx={{ base: 0, md: -5 }} />

					<Box
						m={4}
						mt={5}
						p={3}
						border="1px"
						borderRadius="12px"
						borderColor="gray.200"
					>
						<SlideFade in={!loading} offsetY="20px">
							<Stack direction={{ base: "column", md: "row" }} gap={3} alignItems="start">
								<VStack w="full" alignItems="start">
									<Text
										textAlign="left"
										fontWeight={600}
										color="gray.600"
										fontSize="lg"
									>
										Course Progress
									</Text>

									<Progress value={courseData.validcoursepercent} hasStripe colorScheme="brand" w="full" size="lg" />

									<Text
										fontWeight={500}
										fontSize="sm"
										color="gray.500"
									>
										Completed {courseData.validcoursecount} of {courseData.coursecount} modules
									</Text>
								</VStack>

								<Spacer />
								{
									loading ? <Skeleton w="350px" h="250px" textAlign="right" /> :
										!loading && !isEmpty(courseData.image) &&
										<Image
											src={courseData.image}
											textAlign="right"
											rounded="lg"
											maxW={{ md: "350px" }}
										/>
								}
							</Stack>
						</SlideFade>

						<SlideFade in={!loading} offsetY="20px">
							{
								currentItems?.length > 0 ? <>
									<VStack display={{ base: "flex", lg: "none" }} mt={3}>
										{
											currentItems.map((course: any, i: number) => (
												<Box
													key={i}
													w="full"
													p={2}
													className="hover-pop"
													cursor="pointer"
													border="1px solid"
													borderColor="gray.100"
													backgroundColor={i % 2 ? "white" : "gray.50"}
													borderRadius="8px"
													boxShadow="xs"
													onClick={() => {
														if (course.cpublished === "0") {
															toast({
																title: "This Course is not published",
																description: "",
																status: "error",
																duration: 5000,
																isClosable: true
															})
														} else
															navigateTo(`/training/module/${course.action.moduleId}`);
													}}
												>
													<VStack alignItems="start">
														<Text
															fontSize="md"
															fontWeight={500}
															color="gray.600"
															textAlign="start"
														>
															{course.cname || "Unnamed"}
														</Text>

														<Text
															fontSize="sm"
															fontWeight={500}
															color="gray.500"
															textAlign="start"
														>
															{
																course.cadatestarted ? <>
																	Started {!isNull(course.cadatestarted) ? localDate(course.cadatestarted) : "-"}
																</> : "Not Started"
															}
														</Text>

														<Text
															fontSize="sm"
															fontWeight={500}
															color="gray.500"
															textAlign="start"
														>
															{
																!isNull(course.lastSubmittedData.date) && <>
																	Last Submitted {!isNull(course.lastSubmittedData.date) ? localDate(course.lastSubmittedData.date) : "-"}
																</>
															}
														</Text>

														<HStack>
															<Badge
																colorScheme={
																	course.state === "Passed" ? "brand"
																		: course.state === "Invalid" ? "danger"
																			: "gray"
																}

																size="sm"
																variant="outline"

															>
																<Icon
																	as={
																		course.state === "Awaiting Observation" ? BsEye :
																			course.state === "Passed" ? BsCheckCircleFill
																				: course.state === "Invalid" ? BsExclamationCircleFill
																					: course.state === "In Progress" ? BsPlayCircle
																						: BsDash
																	}
																	mt="-2px"
																	mr="2px"
																	verticalAlign="middle"
																/> {course.state}
															</Badge>

															{
																course.cpublished === "0" && <Badge
																	colorScheme="danger"
																	size="sm"
																	variant="outline"

																>
																	<Icon
																		as={BsExclamationCircleFill}
																		mt="-2px"
																		mr="2px"
																		verticalAlign="middle"
																	/>
																	Unpublished
																</Badge>
															}
														</HStack>

														<Button
															w="full"
															onClick={() => navigateTo(`/training/module/${course.moduleId}`)}
														>
															{course.action.status}
														</Button>
													</VStack>
												</Box>
											))
										}
									</VStack>

									<Table
										{...getTableProps()}
										id="MessageTable"
										variant="striped"
										display={{ base: "none", lg: "inline-table" }}
										mt={3}
									>
										<Thead>
											{
												headerGroups.map(headerGroup => (
													<Tr {...headerGroup.getHeaderGroupProps()}>
														{
															headerGroup.headers.map(column => (
																<Th
																	{...column.getHeaderProps()}
																	onClick={() => onHeaderClick(column)}
																>
																	<Text fontWeight={700} color="gray.600">{column.render("Header")}</Text>
																</Th>
															))
														}
													</Tr>
												))
											}
										</Thead>
										<Tbody {...getTableBodyProps()}>
											{
												page.map((row, i) => {
													prepareRow(row);
													return (
														<Tr
															{...row.getRowProps()}
															key={i}
														>
															{
																row.cells.map(cell => {
																	return <Td wordBreak="break-word" {...cell.getCellProps()}>
																		{cell.render("Cell")}
																	</Td>
																})
															}
														</Tr>
													);
												})
											}
										</Tbody>
									</Table>
								</> :

									<Center m={5}>
										<VStack>
											<Text fontSize="xl" fontWeight={600}>
												No Modules Available
											</Text>
										</VStack>
									</Center>
							}
						</SlideFade>
					</Box>
				</Box>
			</Box>
		)
	}

	return DataTable({
		data: currentItems,
		columns,
		loading
	});
};