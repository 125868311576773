import { useEffect, useState } from 'react';
import {
    useLocation,
    useNavigate,
    useParams
} from 'react-router-dom';
import {
    Box,
    Divider,
    HStack,
    Icon,
    Text,
    Button,
    VStack,
    Skeleton,
    useToast
} from '@chakra-ui/react';
import {
    BsArrowLeftCircle,
    BsCheckCircleFill,
    BsExclamationCircleFill,
    BsEye,
} from 'react-icons/bs';

import { getGuidedReportItemAttachment, getGuidedTaskReport, getMandatoryReportItemAttachment, getMandatoryTaskReport, getReportItemAttachment, getTaskReport } from '../../features/tasks/task.actions';
import { TaskReportTable } from './TaskReportTable';
import { FaClipboardCheck } from 'react-icons/fa';

export const TaskReport = () => {
    const navigateTo = useNavigate();
    const toast = useToast();

    const { state }: any = useLocation();
    const { id } = useParams<string>();

    const [loading, setLoading] = useState<boolean>(false);
    const [reportData, setReportData] = useState<any>({
        taskName: "",
        teamName: "",
        path: "",
        dateSignedOff: "",
        signedOffBy: ""
    });

    const [reportPassed, setReportPassed] = useState<Boolean>(true);
    const [pageCount, setPageCount] = useState<Number>(0);
    const [pageIndex, setPageIndex] = useState<Number>(0);
    const [currentItems, setCurrentItems] = useState<any>({});

    useEffect(() => {
        setLoading(true);

        if (state?.navData?.isMandatory || state?.type === "mandatory") {
            getMandatoryTaskReport(Number(id))
                .then(async (res) => {
                    const status = res.report.pthstatus === "1" ? true : res.report.pthstatus === "2" ? false : true;

                    let formattedTaskReportData = [];
                    for (let i = 0; i < res.report.items.length; i++) {

                        let attachments: any = [];
                        if (res.report.items[i].attachments.length > 0) {

                            let attachmentsValid = true;

                            for (let j = 0; j < res.report.items[i].attachments.length; j++) {
                                let attachment = null;

                                try {
                                    attachment = await getMandatoryReportItemAttachment(
                                        res.report.pthid,
                                        res.report.items[i].tiid,
                                        res.report.items[i].attachments[j].ptihapath,
                                        true,
                                        true
                                    );

                                    attachments.push({
                                        datauri: attachment.datauri,
                                        tthid: res.report.pthid,
                                        tiid: res.report.items[i].tiid,
                                        ttihapath: res.report.items[i].attachments[j].ptihapath
                                    });
                                } catch (error: any) {
                                    console.log(error);
                                    attachmentsValid = false;
                                }
                            }

                            if (!attachmentsValid) {
                                toast({
                                    title: "We couldn't fetch all report attachments",
                                    description: "Please try again. If this issue persists please contact your system administrator.",
                                    status: "error",
                                    duration: 5000,
                                    isClosable: true
                                });
                            }
                        }

                        formattedTaskReportData.push({
                            id: res.report.items[i].tiid,
                            item: {
                                description: res.report.items[i].tidescription || "",
                                feedback: res.report.items[i].ptihfeedback || "",
                                attachments: attachments
                            },
                            status: res.report.items[i].ptihstatus === "1" ? "Passed" : res.report.items[i].ptihstatus === "2" ? "Failed" : "N/A",
                            signedOffBy: {
                                date: res.report.items[i].ptihdatechecked || "",
                                by: res.report.items[i].checkedby || ""
                            },
                            notes: res.report.items[i].ttihwrapupnotes || "-",
                            data: res.report.items[i].ptihdatainputstring
                        });
                    }

                    setReportData({
                        taskName: res.report.taskname,
                        teamName: res.report.teamname,
                        dateSignedOff: res.report.hrdatesignedoff,
                        signedOffBy: res.report.signedoffby,
                        targetUser: res.report.targetuser,
                        path: res.report.tthpath
                    });

                    setReportPassed(status);
                    setCurrentItems(formattedTaskReportData);
                })
                .catch((error: any) => {
                    console.log(error);
                })
                .finally(() => setLoading(false));
        } else if (state?.navData?.isGuided || state?.type === "guided") {
            getGuidedTaskReport(Number(id))
                .then(async (res) => {
                    const status = res.report.gthstatus === "1" ? true : res.report.gthstatus === "2" ? false : true;

                    let formattedTaskReportData = [];
                    for (let i = 0; i < res.report.items.length; i++) {

                        let attachments: any = [];
                        if (res.report.items[i].attachments.length > 0) {

                            let attachmentsValid = true;

                            for (let j = 0; j < res.report.items[i].attachments.length; j++) {
                                let attachment = null;

                                try {
                                    attachment = await getGuidedReportItemAttachment(
                                        res.report.gthid,
                                        res.report.items[i].tiid,
                                        res.report.items[i].attachments[j].gtihapath,
                                        true,
                                        true
                                    );

                                    attachments.push({
                                        datauri: attachment.datauri,
                                        tthid: res.report.gthid,
                                        tiid: res.report.items[i].tiid,
                                        ttihapath: res.report.items[i].attachments[j].gtihapath
                                    });
                                } catch (error: any) {
                                    console.log(error);
                                    attachmentsValid = false;
                                }
                            }

                            if (!attachmentsValid) {
                                toast({
                                    title: "We couldn't fetch all report attachments",
                                    description: "Please try again. If this issue persists please contact your system administrator.",
                                    status: "error",
                                    duration: 5000,
                                    isClosable: true
                                });
                            }
                        }

                        formattedTaskReportData.push({
                            id: res.report.items[i].tiid,
                            item: {
                                description: res.report.items[i].tidescription || "",
                                feedback: res.report.items[i].gtihfeedback || "",
                                attachments: attachments
                            },
                            status: res.report.items[i].gtihstatus === "1" ? "Passed" : res.report.items[i].gtihstatus === "2" ? "Failed" : "N/A",
                            signedOffBy: {
                                date: res.report.items[i].gtihdatechecked || "",
                                by: res.report.items[i].checkedby || ""
                            },
                            notes: res.report.items[i].ttihwrapupnotes || "",
                            data: res.report.items[i].gtihdatainputstring
                        });
                    }

                    setReportData({
                        taskName: res.report.taskname,
                        teamName: res.report.teamname,
                        dateSignedOff: res.report.hrdatesignedoff,
                        signedOffBy: res.report.signedoffby,
                        targetUser: res.report.targetuser,
                        path: res.report.tthpath
                    });

                    setReportPassed(status);
                    setCurrentItems(formattedTaskReportData);

                })
                .catch((error: any) => {
                    console.log(error);
                })
                .finally(() => setLoading(false));
        } else
            getTaskReport(Number(id))
                .then(async (res) => {
                    const status = res.report.tthstatus === "1" ? true : res.report.tthstatus === "2" ? false : true;

                    let formattedTaskReportData = [];
                    for (let i = 0; i < res.report.items.length; i++) {

                        let attachments: any = [];
                        if (res.report.items[i].attachments.length > 0) {

                            let attachmentsValid = true;

                            for (let j = 0; j < res.report.items[i].attachments.length; j++) {
                                let attachment = null;

                                try {
                                    attachment = await getReportItemAttachment(
                                        res.report.tthid,
                                        res.report.items[i].tiid,
                                        res.report.items[i].attachments[j].ttihapath,
                                        true,
                                        true
                                    );

                                    attachments.push({
                                        datauri: attachment.datauri,
                                        tthid: res.report.tthid,
                                        tiid: res.report.items[i].tiid,
                                        ttihapath: res.report.items[i].attachments[j].ttihapath
                                    });
                                } catch (error: any) {
                                    console.log(error);

                                    attachmentsValid = false;
                                }
                            }

                            if (!attachmentsValid) {
                                toast({
                                    title: "We couldn't fetch all report attachments",
                                    description: "Please try again. If this issue persists please contact your system administrator.",
                                    status: "error",
                                    duration: 5000,
                                    isClosable: true
                                });
                            }
                        }

                        formattedTaskReportData.push({
                            id: res.report.items[i].tiid,
                            item: {
                                description: res.report.items[i].tidescription || "",
                                feedback: res.report.items[i].ttihfeedback || "",
                                attachments: attachments
                            },
                            status: res.report.items[i].ttihstatus === "1" ? "Passed" : res.report.items[i].ttihstatus === "2" ? "Failed" : "-",
                            signedOffBy: {
                                date: res.report.items[i].ttihdatechecked || "",
                                by: res.report.items[i].checkedby.trim() || ""
                            },
                            notes: res.report.items[i].ttihwrapupnotes || "",
                            data: res.report.items[i].ttihdatainputstring
                        });
                    }

                    setReportData({
                        taskName: res.report.taskname,
                        teamName: res.report.teamname,
                        dateSignedOff: res.report.hrdatesignedoff,
                        signedOffBy: res.report.signedoffby,
                        path: res.report.tthpath
                    });

                    setReportPassed(status);
                    setCurrentItems(formattedTaskReportData);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => setLoading(false));
    }, []);

    return (
        <Box m={{ base: 0, md: 5 }}>
            <Button
                display={{ base: "none", md: "unset" }}
                w="fit-content"
                border="none"
                boxShadow="sm"
                mb={2}
                size="sm"
                color="gray.600"
                variant="outline"
                fontWeight={600}
                onClick={() => {
                    switch (state?.from) {
                        case "actions": navigateTo(-1);
                            break;
                        case "notification": navigateTo(-1);
                            break;
                        default: navigateTo(`/tasks/tasksummary/${state.teamTaskId}`, {
                            state: {
                                navData: state?.navData,
                                reportsNavData: state?.reportsNavData,
                                pageIndex: pageIndex,
                                pageCount: pageCount,
                                isGuided: state?.navData?.isGuided,
                                from: "tasks"
                            }
                        })
                    }
                }}
            >
                <HStack>
                    <BsArrowLeftCircle />
                    <Text>
                        {
                            state?.from === "actions" ? "Back to Action" :
                                state?.from === "notification" ? "Back to Notification" :
                                    "Back to Task Summary"
                        }
                    </Text>
                </HStack>
            </Button>

            <Box
                h={{ base: "full", md: "unset" }}
                px={{ base: 0, md: 5 }}
                pt={{ base: 5, md: 2 }}
                pb={3}
                bg="white"
                rounded={{ base: "none", md: "lg" }}
                boxShadow={{ base: "none", md: "lg" }}
            >
                <Button
                    display={{ base: "unset", md: "none" }}
                    mx={3}
                    mt={2}
                    mb={4}
                    size="sm"
                    color="gray.600"
                    variant="link"
                    fontWeight={600}
                    onClick={() => {
                        switch (state.from) {
                            case "actions": navigateTo(-1);
                                break;
                            default: navigateTo(`/tasks/tasksummary/${state.teamTaskId}`, {
                                state: {
                                    navData: state?.navData,
                                    pageIndex: pageIndex,
                                    pageCount: pageCount,
                                    isGuided: state?.navData?.isGuided,
                                    from: "tasks"
                                },
                            })
                        }
                    }}
                >
                    <HStack>
                        <BsArrowLeftCircle />
                        <Text>{state?.from === "actions" ? "Back to Action" : "Back to Task Summary"}</Text>
                    </HStack>
                </Button>

                <HStack p={3} pb={5}>
                    <Icon
                        mr={3}
                        as={state?.navData?.isGuided ? BsEye : FaClipboardCheck}
                        verticalAlign="middle"
                        fontSize="4xl"
                        color="green.500"
                    />

                    <Text
                        fontWeight={{ base: 600, lg: 700 }}
                        fontSize={{ base: "xl", lg: "2xl" }}
                        textAlign="left"
                        color="gray.700"
                    >
                        {state?.navData?.isGuided ? "Guided Task Report" : "Task Report"}
                    </Text>
                </HStack>

                <Divider w="unset" mx={{ base: 0, md: -5 }} />

                <Box m={3}>
                    <Skeleton
                        isLoaded={!loading}
                        rounded="lg"
                        w={{ base: "100%", xl: "50%" }}
                    >
                        <Box
                            mt={5}
                            p={5}
                            bg={reportPassed ? "green.50" : "red.50"}
                            border="1px solid"
                            borderColor={reportPassed ? "green.200" : "red.100"}
                            rounded="lg"
                        >
                            <VStack alignItems="start" color="gray.600">
                                <Text fontSize="lg" fontWeight={600}>
                                    {reportData.taskName} ({reportData.teamName})
                                </Text>
                                <HStack>
                                    <Icon
                                        as={reportPassed ? BsCheckCircleFill : BsExclamationCircleFill}
                                        color={reportPassed ? "brand.500" : "danger.500"}
                                    />
                                    <Text fontWeight={500} fontSize="sm">
                                        {
                                            state?.navData?.isGuided ? `${(reportPassed ? "Passed" : "Failed")} by ${reportData.targetUser} on ${reportData.dateSignedOff}`
                                                : `${(reportPassed ? "Passed" : "Failed")} by ${reportData.signedOffBy} on ${reportData.dateSignedOff}`
                                        }
                                    </Text>
                                </HStack>

                                {
                                    state?.navData?.isGuided && <HStack>
                                        <Icon
                                            as={BsEye}
                                            color={reportPassed ? "brand.500" : "danger.500"}
                                        />
                                        <Text fontWeight={500} fontSize="sm">Observed by {reportData.signedOffBy}</Text>
                                    </HStack>
                                }
                            </VStack>
                        </Box>
                    </Skeleton>
                </Box>

                <Box
                    m={3}
                    p={3}
                    border="1px solid"
                    borderColor="gray.200"
                    rounded="lg"
                    width={{ base: "fit-content", md: "unset" }}
                >
                    {
                        currentItems.length > 0 && !loading &&
                        <TaskReportTable
                            currentItems={currentItems}
                            pageCount={pageCount}
                            pageIndex={pageIndex}
                            setPageIndex={setPageIndex}
                            loading={loading}
                            isGuided={state?.navData?.isGuided}
                        />
                    }
                </Box>
            </Box>
        </Box>
    );
}