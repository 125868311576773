import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Image, Flex, Stack, Text, useBreakpointValue, Button, Icon } from "@chakra-ui/react";
import {
	BsHouse,
	BsAward,
	BsFileText,
	BsConeStriped,
	BsEnvelope,
	BsGraphUp,
	BsGear,
	BsFileBarGraph,
	BsWrench,
	BsArrowRepeat,
	BsExclamationCircle
} from "react-icons/bs";
import { VscMortarBoard } from "react-icons/vsc";
import { FaClipboardCheck } from "react-icons/fa";

import Logo from "../../assets/logos/obbi_logo_1051x640.png";
import { RootState } from "../../redux/rootReducer";
import { getClickthroughURL, selectClassicObbi } from "../../features/auth/auth.actions";
import { NavButton } from "./NavButton";
import { CheckProductTierFlag } from "../../helpers/Utilities";

export const Sidebar: React.FC<any> = ({ toggleMenu, messageCount, isAdmin }) => {
	const org = useSelector((state: RootState) => state.authReducer);
	const user = useSelector((state: RootState) => state.authReducer.user);

	const navigateTo = useNavigate();
	const isMobile = useBreakpointValue({ base: true, lg: false });

	const handleClickThrough = async (path: string) => {
		let url: string = await getClickthroughURL(path);
		window.open(url, "_self");
	};

	return (
		<Flex
			as="section"
			position="fixed"
			minHeight="full"
			height={'100%'}
			width="235px"
			overflowY='auto'
			zIndex={100}
			style={{ scrollbarWidth: "thin", scrollbarColor: "transparent transparent" }}
		>
			<Flex
				flex="1"
				bg="bg-surface"
				maxW={{ base: "full", sm: "xs" }}
				py={{ base: "6", sm: 5 }}
				px={{ base: "4", sm: "6" }}
				overflowY="auto"
			>
				<Stack shouldWrapChildren width="100%">
					{
						!isMobile && <Image
							src={Logo}
							alt="Obbi logo"
							className="hover-pop"
							w="100px"
							cursor="pointer"
							onClick={() => {
								navigateTo("/home");
								toggleMenu();
							}}
						/>
					}

					{
						isMobile && org.orgnewobbi === 1 && <Button
							mb={3}
							size="sm"
							variant="outline"
							onClick={() => {
								selectClassicObbi()
									.then(() => {
										handleClickThrough("Home");
									})
							}}
						>
							<Icon as={BsArrowRepeat} mr={2} /> Switch to Classic Obbi
						</Button>
					}

					<Stack pt={{ base: 0, lg: 4 }}>
						<NavButton
							label="Home"
							icon={BsHouse}
							bgColor={window.location.pathname.includes("home") ? "green.500" : ""}
							color={window.location.pathname.includes("home") ? "white" : ""}
							onClick={() => {
								navigateTo("/home");
								if (isMobile) toggleMenu();
							}}
						/>

						{
							user.isTeamManager && <>
								<NavButton
									label="Team Overview"
									icon={BsGraphUp}
									bgColor={window.location.pathname.includes("reports") ? "green.500" : ""}
									color={window.location.pathname.includes("reports") ? "white" : ""}
									onClick={() => {
										handleClickThrough("TeamUpdateReport");
										if (isMobile) toggleMenu();
									}}
								/>

								<NavButton
									label="Reports"
									icon={BsFileBarGraph}
									bgColor={window.location.pathname.includes("admin") ? "green.500" : ""}
									color={window.location.pathname.includes("admin") ? "white" : ""}
									onClick={() => {
										handleClickThrough("TeamReports");
										if (isMobile) toggleMenu();
									}}
								/>
							</>
						}
					</Stack>

					<Text pt={1} color="gray.500" fontSize="xs" fontWeight={600}>
						Compliance
					</Text>
					<Stack>
						<NavButton
							label="Tasks"
							icon={FaClipboardCheck}
							bgColor={window.location.pathname.includes("tasks") ? "green.500" : ""}
							color={window.location.pathname.includes("tasks") ? "white" : ""}
							onClick={() => {
								navigateTo("/tasks");
								if (isMobile) toggleMenu();
							}}
						/>
						<NavButton
							label="Actions"
							icon={BsWrench}
							bgColor={window.location.pathname.includes("actions") ? "green.500" : ""}
							color={window.location.pathname.includes("actions") ? "white" : ""}
							onClick={() => {
								navigateTo("/actions");
								if (isMobile) toggleMenu();
							}}
						/>
						<NavButton
							label="Certificates"
							icon={BsAward}
							bgColor={window.location.pathname.includes("certificates") ? "green.500" : ""}
							color={window.location.pathname.includes("certificates") ? "white" : ""}
							onClick={() => {
								navigateTo("/certificates");
								if (isMobile) toggleMenu();
							}}
						/>
					</Stack>

					<Text pt={1} color="gray.500" fontSize="xs" fontWeight={600}>
						Awareness
					</Text>
					<Stack>
						<NavButton
							label="Training"
							icon={VscMortarBoard}
							bgColor={window.location.pathname.includes("training") ? "green.500" : ""}
							color={window.location.pathname.includes("training") ? "white" : ""}
							onClick={() => {
								navigateTo("/training");
								if (isMobile) toggleMenu();
							}}
						/>

						<NavButton
							label="Documents"
							icon={BsFileText}
							bgColor={window.location.pathname.includes("documents") ? "green.500" : ""}
							color={window.location.pathname.includes("documents") ? "white" : ""}
							onClick={() => {
								navigateTo("/documents");
								if (isMobile) toggleMenu();
							}}
						/>

						<NavButton
							label="Messages"
							icon={BsEnvelope}
							bgColor={window.location.pathname.includes("messages") ? "green.500" : ""}
							color={window.location.pathname.includes("messages") ? "white" : ""}
							onClick={() => {
								navigateTo("/messages");
								if (isMobile) toggleMenu();
							}}
							toggleAlert={messageCount > 0}
						/>
					</Stack>

					<Text pt={1} color="gray.500" fontSize="xs" fontWeight={600}>
						Safety & Standards
					</Text>
					<Stack>
						{
							org?.orgriskassessmentsenabled && CheckProductTierFlag(user?.producttier?.ptriskassessmentsflag) &&
							<NavButton
								label="Risk Assessments"
								icon={BsExclamationCircle}
								bgColor={window.location.pathname.includes("riskassessments") ? "green.500" : ""}
								color={window.location.pathname.includes("riskassessments") ? "white" : ""}
								onClick={() => {
									navigateTo("/riskassessments");
									if (isMobile) toggleMenu();
								}}
							/>
						}

						<NavButton
							label="Incidents"
							icon={BsConeStriped}
							bgColor={window.location.pathname.includes("incidents") ? "green.500" : ""}
							color={window.location.pathname.includes("incidents") ? "white" : ""}
							onClick={() => {
								navigateTo("/incidents");
								if (isMobile) toggleMenu();
							}}
						/>
					</Stack>

					{
						isAdmin && <>
							<Text pt={1} color="gray.500" fontSize="xs" fontWeight={600}>
								Management
							</Text>
							<Stack mt={2}>
								<NavButton
									label="Admin"
									icon={BsGear}
									bgColor={window.location.pathname.includes("admin") ? "green.500" : ""}
									color={window.location.pathname.includes("admin") ? "white" : ""}
									onClick={() => {
										handleClickThrough("Admin");
										if (isMobile) toggleMenu();
									}}
								/>
							</Stack>
						</>
					}
				</Stack>
			</Flex>
		</Flex>
	);
};