import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import _, { isEmpty } from "lodash";

import { Box, Grid, HStack, Divider, Button, Square, Text, Icon, Image, SimpleGrid, GridItem, VStack, Avatar, Spacer, Badge, Stack, useBreakpointValue, SlideFade, FormControl, FormLabel, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Center, Spinner } from "@chakra-ui/react";
import { BsCheckCircle, BsDashCircle, BsExclamationCircle, BsExclamationTriangle, BsPeople, BsPlayCircle, BsStopCircle, BsWrench } from "react-icons/bs";

import { IoSkullOutline } from "react-icons/io5";
import { getHazard, getHazardAttachment } from "../../features/riskAssessments/riskAssessments.actions";
import { localDate } from "../../helpers/DayJsHelper";

import smile_green from "../../assets/images/smile_green.png";
import smile_yellow from "../../assets/images/smile_yellow.png";
import smile_orange from "../../assets/images/smile_orange.png";
import smile_red from "../../assets/images/smile_red.png";
import smile_black from "../../assets/images/smile_black.png";
import { Document, Page, pdfjs } from "react-pdf";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";
import { CheckProductTierFlag } from "../../helpers/Utilities";

export const HazardReader: React.FC<any> = ({
    publishing = false,
    hazard,
    riskAssessmentId,
    riskAssessmentVersionId
}) => {
    const navigateTo = useNavigate();
    const isMobile = useBreakpointValue({ base: true, md: false });

    const [loading, setLoading] = useState<boolean>(true);
    const user = useSelector((state: RootState) => state.authReducer.user);

    const [controls, setControls] = useState<any>([]);
    const [actions, setActions] = useState<any>([]);

    const [risk, setRisk] = useState({ riskText: "", score: 0 });

    const [currentImage, setCurrentImage] = useState("")
    const [isAttachmentOpen, setIsAttachmentOpen] = useState(false)
    const [currentCert, setCurrentCert] = useState({
        id: 0,
        isPdf: false,
        uri: "",
        fileName: ""
    });
    const [pdfIsOpen, setPdfIsOpen] = useState(false);

    const [hazardData, setHazardData] = useState<any>({
        rahid: "",
        rahdescription: "N/A",
        rahriskcategory: "N/A",
        rahpeopleaffected: "None",
        rahconsequences: "None",
        controls: [],
        attachments: [],
    });

    // PDF pages
    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }: any) {
        setNumPages(numPages);
    };

    const getColor = (risk: any) => (
        risk > 0 && risk < 5 ? "#37a169" :
            risk > 4 && risk < 11 ? "#D69E2E" :
                risk > 10 && risk < 16 ? "#ED8936" :
                    risk > 15 && risk < 21 ? "#E53E3E" :
                        "#1A202C"
    );

    const getControlIcon = (risk: any) => (
        risk > 0 && risk < 5 ? smile_green :
            risk > 4 && risk < 11 ? smile_yellow :
                risk > 10 && risk < 16 ? smile_orange :
                    risk > 15 && risk < 21 ? smile_red :
                        smile_black
    );

    const getHazardFunc = useCallback(async () => {
        setLoading(true);

        getHazard(
            riskAssessmentId,
            riskAssessmentVersionId,
            hazard?.rahid
        )
            .then((res: any) => {
                setHazardData(res.hazard);
                setControls(res.hazard?.controls || []);
                setActions(res.hazard?.actions || []);
            })
            .catch((error: any) => {
                console.log(error);
            })
            .finally(() => { setLoading(false); });
    }, []);

    const resetDocViewer = () => {
        setNumPages(0);
        setPageNumber(1);
    };

    const renderPdf = () => {
        return <Modal
            size="4xl"
            isOpen={pdfIsOpen}
            onClose={() => {
                setPdfIsOpen(false);
                resetDocViewer();
            }}
        >
            <ModalOverlay />

            <ModalContent mt={0}>
                <ModalHeader
                    style={{
                        height: "50px",
                        borderRadius: "5px 5px 0 0",
                        background: "#38a169",
                        color: "white",
                    }}
                >
                    <HStack gap={1} paddingRight={10}>
                        <Text wordBreak="break-word" fontSize="lg" maxW="50%">{currentCert.fileName}</Text>
                        <Spacer />

                        <Button
                            bg="white"
                            color="brand.500"
                            float="left"
                            size="sm"
                            disabled={pageNumber === 1}
                            onClick={() => setPageNumber(pageNumber - 1)}
                        >
                            Back
                        </Button>
                        <Text fontSize="md">
                            {pageNumber} of {numPages}
                        </Text>
                        <Button
                            bg="white"
                            color="brand.500"
                            float="right"
                            size="sm"
                            disabled={pageNumber === numPages}
                            onClick={() => setPageNumber(pageNumber + 1)}
                        >
                            Next
                        </Button>

                        <ModalCloseButton
                            color="white"
                            _hover={{ bg: "" }}
                            _active={{ bg: "" }}
                        />
                    </HStack>
                </ModalHeader>

                <ModalBody>
                    <Document
                        file={currentCert.uri}
                        onLoadSuccess={onDocumentLoadSuccess}
                        loading={
                            <Center my={48}>
                                <Spinner
                                    thickness='4px'
                                    speed='0.65s'
                                    color="brand.500"
                                    size="xl"
                                />
                            </Center>
                        }
                    >
                        <Page
                            renderAnnotationLayer={false}
                            renderTextLayer={false}
                            pageNumber={pageNumber}
                        />
                    </Document>
                </ModalBody>
            </ModalContent>
        </Modal>
    };

    const renderImageForHazardAttachment = () => {
        return <Modal
            size="4xl"
            isOpen={isAttachmentOpen}
            onClose={() => { setIsAttachmentOpen(false) }}
        >
            <ModalOverlay />
            <ModalContent mt={0}>
                <ModalHeader />
                <ModalCloseButton
                    _hover={{ bg: "" }}
                    _active={{ bg: "" }}
                />
                <ModalBody>
                    <Image
                        src={currentImage}
                        m="5px auto 15px auto"
                        p={0}
                    />
                </ModalBody>
            </ModalContent>
        </Modal>
    };

    useEffect(() => {
        setRisk({
            ...risk,
            riskText: hazard?.rahriskcategory || "",
            score: Number(hazard?.rahrisklevel) || 0
        });

        if (publishing) {
            setHazardData(hazard);
            setControls(hazard?.controls);
            setActions(hazard?.actions);
            setLoading(false);
        } else {
            getHazardFunc();
        }
    }, []);

    return (
        <Box w="full">
            <SlideFade in={!loading}>

                {/* Title Bar */}
                <Box
                    px={{ base: 3, md: 5 }}
                    mx={{ base: 0, md: -5 }}
                    border="1px solid"
                    borderColor="gray.200"
                    boxShadow="sm"
                    pt={isMobile ? 2 : 1}
                    pb={isMobile ? 2 : 3}
                    bg={hazardData?.rahnewrisklevel ? getColor(hazardData?.rahnewrisklevel) : 'gray.500'}
                    color="white"
                >
                    <Stack
                        direction={{ base: "column", md: "row" }}
                        mx={0}
                        w="unset"
                    >
                        <VStack alignItems="start" mt={{ base: 0, md: "7px" }}>
                            <HStack fontWeight={600}>
                                {
                                    !isMobile && <Icon as={BsExclamationTriangle} fontSize="md" color={'white'} />
                                }
                                <Text color={'white'} fontSize={{ base: "md", md: "lg" }}>{hazardData?.rahdescription}</Text>
                            </HStack>
                        </VStack>
                    </Stack>
                </Box>

                {/* Hazard Info */}
                <Box
                    p={{ base: 3, md: 5 }}
                    pt={4}
                    pb={isMobile ? 3 : 1}
                    mx={{ base: 0, md: -5 }}
                    bg="white"
                    roundedBottom="lg"
                >
                    <Grid
                        templateColumns={{ md: 'repeat(1, 1fr)', xl: '2fr 2fr 1fr' }}
                        gap={isMobile ? 5 : 10}
                    >
                        <GridItem>
                            <HStack mb={2} fontSize={{ base: "sm", md: "md" }}>
                                <Icon as={BsExclamationCircle} color="gray.600" />
                                <Text color="gray.600" fontWeight={600} fontSize="sm">Consequences</Text>
                            </HStack>

                            <Text color="gray.500" fontSize={{ base: "xs", md: "11pt" }} fontWeight={500} mt={2}>
                                {hazardData?.rahconsequences}
                            </Text>
                        </GridItem>

                        <GridItem>
                            <HStack mb={2} fontSize={{ base: "sm", md: "md" }}>
                                <Icon as={BsPeople} color="gray.600" />
                                <Text color="gray.600" fontWeight={600} fontSize="sm">People Affected</Text>
                            </HStack>

                            <Text color="gray.500" fontSize={{ base: "xs", md: "11pt" }} fontWeight={500} mt={2}>
                                {hazardData?.rahpeopleaffected}
                            </Text>
                        </GridItem>

                        <GridItem>
                            <Box>
                                <HStack mb={2}>
                                    <Icon as={BsExclamationTriangle} color="gray.500" />
                                    <Text
                                        color="gray.600"
                                        fontWeight={600}
                                        fontSize="sm"
                                        mb={2}
                                    >
                                        Risk Before Controls
                                    </Text>
                                </HStack>
                                <HStack gap={2}>

                                    <Button
                                        variant="link"
                                        size="xs"
                                        border="1px solid"
                                        borderColor={getColor(hazardData?.rahrisklevel) || "gray.200"}
                                        cursor='default'
                                    >
                                        <Square
                                            m={0.5}
                                            rounded="7px"
                                            size="40px"
                                            bg={getColor(hazardData?.rahrisklevel)}
                                        >
                                            {
                                                (hazardData?.rahriskcategory.indexOf("Fatal") > -1) && <Icon
                                                    as={IoSkullOutline}
                                                    color="white"
                                                    mr="2px"
                                                />
                                            }

                                            <Text
                                                color="white"
                                                fontWeight={700}
                                                fontSize={(hazardData?.rahriskcategory.indexOf("Fatal") > -1) ? "10pt" : "lg"}
                                            >
                                                {hazardData?.rahrisklevel}
                                            </Text>
                                        </Square>

                                        <Text
                                            color={getColor(hazardData?.rahrisklevel)}
                                            fontSize="10pt"
                                            fontWeight={600}
                                            mx={3}
                                        >
                                            {hazardData?.rahriskcategory}
                                        </Text>
                                    </Button>
                                </HStack>
                            </Box>
                        </GridItem>
                    </Grid>

                    <Divider my={isMobile ? 4 : 5} />

                    <Grid
                        gap={isMobile ? 5 : 10}
                        templateColumns={{
                            md: 'repeat(1, 1fr)',
                            xl: '2fr 2fr 1fr'
                        }}
                    >
                        <Box mb={!isMobile && hazardData?.attachments?.length > 0 ? 5 : 0}>
                            <Text
                                color="gray.600"
                                fontWeight={600}
                                fontSize="sm"
                                mb={2}
                            >
                                Existing Safety Controls
                            </Text>

                            {
                                isEmpty(controls) ?
                                    <Box
                                        textAlign="center"
                                        border="1px solid"
                                        borderColor="gray.200"
                                        rounded="lg"
                                        p={3}
                                        mb={{ base: 0, md: 0 }}
                                    >
                                        <VStack gap={2}>
                                            <Box w="full">
                                                <Text
                                                    color="gray.600"
                                                    fontSize={{ base: "sm", md: "lg" }}
                                                    fontWeight={600}
                                                >
                                                    No Controls
                                                </Text>

                                                <Text
                                                    color="gray.500"
                                                    fontSize={{ base: "9pt", md: "sm" }}
                                                    fontWeight={400}
                                                    mt={1}
                                                >
                                                    No Controls have been added to this Hazard
                                                </Text>
                                            </Box>
                                        </VStack>
                                    </Box> :

                                    <SimpleGrid
                                        columns={1}
                                        gap={3}
                                        mb={{ base: 0, md: 5 }}
                                    >
                                        {
                                            controls.map((c: any, index: any) => {
                                                return <HStack w="full" key={index}>
                                                    <Image
                                                        w="16px"
                                                        mt="7px"
                                                        alignSelf="baseline"
                                                        src={getControlIcon(hazardData?.rahnewrisklevel)}
                                                    />

                                                    <Text
                                                        color="gray.500"
                                                        textAlign="left"
                                                        fontSize={{ base: "xs", md: "11pt" }}
                                                        fontWeight={500}
                                                        wordBreak="break-word"
                                                        whiteSpace="break-spaces"
                                                    >
                                                        {c.racdescription}
                                                    </Text>
                                                </HStack>
                                            })
                                        }
                                    </SimpleGrid>
                            }
                        </Box>

                        <Box w="full" mb={0}>
                            <Text
                                color="gray.600"
                                fontWeight={600}
                                fontSize="sm"
                                mb={2}
                            >
                                Follow up Actions
                            </Text>

                            {
                                isEmpty(actions) ?
                                    <Box
                                        textAlign="center"
                                        border="1px solid"
                                        borderColor="gray.200"
                                        rounded="lg"
                                        p={3}
                                        mb={{ base: 4, md: 0 }}
                                    >
                                        <VStack gap={2}>
                                            <Box w="full">
                                                <Text
                                                    color="gray.600"
                                                    fontSize={{ base: "sm", md: "lg" }}
                                                    fontWeight={600}
                                                >
                                                    No Actions
                                                </Text>

                                                <Text
                                                    color="gray.500"
                                                    fontSize={{ base: "9pt", md: "sm" }}
                                                    fontWeight={400}
                                                    mt={1}
                                                >
                                                    No Actions have been added to this Hazard
                                                </Text>
                                            </Box>
                                        </VStack>
                                    </Box> :

                                    <SimpleGrid columns={1} gap={2} mb={{ base: 2, md: 0 }}>
                                        {
                                            actions.map((a: any, index: any) => {
                                                return <Box
                                                    key={index}
                                                    border="1px solid"
                                                    borderColor="gray.200"
                                                    rounded="lg"
                                                    p={{ base: "8px", md: "11.5px" }}
                                                >
                                                    <VStack alignItems="start">
                                                        <HStack w="full">
                                                            <Icon
                                                                as={BsWrench}
                                                                color={getColor(hazardData?.rahnewrisklevel)}
                                                            />
                                                            <Text
                                                                color="gray.500"
                                                                textAlign="left"
                                                                fontWeight={500}
                                                                fontSize={{ base: "xs", md: "11pt" }}
                                                                wordBreak="break-word"
                                                                whiteSpace="break-spaces"
                                                                cursor={CheckProductTierFlag(user?.producttier?.ptriskassessmentactionsflag) && (a.isTeamManager || a.isTeamMember) ? "pointer" : "default"}
                                                                _hover={
                                                                    CheckProductTierFlag(user?.producttier?.ptriskassessmentactionsflag) && (a.isTeamManager || a.isTeamMember) ? {
                                                                        textDecoration: "underline"
                                                                    } : {}
                                                                }
                                                                onClick={() => {
                                                                    if (CheckProductTierFlag(user?.producttier?.ptriskassessmentactionsflag) && (a.isTeamManager || a.isTeamMember)) {
                                                                        navigateTo(`/actions/action/${a?.action?.actionid}`,
                                                                            { state: { from: "riskassessment" } }
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                {a.raadescription}
                                                            </Text>
                                                        </HStack>

                                                        <HStack w="full" mt={1}>
                                                            <HStack w="full">
                                                                <Avatar name={a?.action?.teamname || null} size={isMobile ? "2xs" : "xs"} mt={isMobile ? -0.5 : 0} />
                                                                <Text
                                                                    color="gray.500"
                                                                    fontSize={{ base: "8pt", md: "xs" }}
                                                                    fontWeight={500}
                                                                >
                                                                    {a?.action?.teamname || "Unassigned"}
                                                                </Text>
                                                            </HStack>

                                                            <Spacer />

                                                            <Text
                                                                w="full"
                                                                color="gray.500"
                                                                fontSize={{ base: "8pt", md: "xs" }}
                                                                fontWeight={500}
                                                            >
                                                                {
                                                                    a?.action?.duedate ?
                                                                        `Due: ${localDate(a?.action?.duedate)}` : "No due date"
                                                                }
                                                            </Text>

                                                            {
                                                                CheckProductTierFlag(user?.producttier?.ptriskassessmentactionsflag) &&
                                                                <>
                                                                    <Spacer />

                                                                    <Badge
                                                                        size="sm"
                                                                        color={
                                                                            a?.isNotStarted ? "gray.600" :
                                                                                a?.isOngoing ? "telegram" :
                                                                                    a?.isBlocked ? "red.700" :
                                                                                        a?.isCompleted ? "green.700" :
                                                                                            "gray.600"
                                                                        }
                                                                        colorScheme={
                                                                            a?.isNotStarted ? "gray" :
                                                                                a?.isOngoing ? "telegram" :
                                                                                    a?.isBlocked ? "danger" :
                                                                                        a?.isCompleted ? "green" :
                                                                                            "gray"
                                                                        }
                                                                    >
                                                                        <HStack>
                                                                            <Icon
                                                                                as={
                                                                                    a?.isNotStarted ? BsDashCircle :
                                                                                        a?.isOngoing ? BsPlayCircle :
                                                                                            a?.isBlocked ? BsStopCircle :
                                                                                                a?.isCompleted ? BsCheckCircle :
                                                                                                    BsDashCircle
                                                                                }
                                                                            />
                                                                            <Text fontWeight={500} fontSize={{ base: "8pt", md: "xs" }}>
                                                                                {a?.statustext}
                                                                            </Text>
                                                                        </HStack>
                                                                    </Badge>
                                                                </>
                                                            }
                                                        </HStack>
                                                    </VStack>
                                                </Box>
                                            })
                                        }
                                    </SimpleGrid>
                            }
                        </Box>

                        <GridItem mb={5}>
                            <Box>
                                <HStack mb={2}>
                                    <Icon as={BsExclamationTriangle} color="gray.500" />
                                    <Text
                                        color="gray.600"
                                        fontWeight={600}
                                        fontSize="sm"
                                        mb={2}
                                    >
                                        Risk After Controls
                                    </Text>
                                </HStack>
                                <Box
                                    textAlign="center"
                                >
                                    <HStack gap={2}>
                                        {hazardData?.rahnewrisklevel ?
                                            <Button
                                                variant="link"
                                                size="xs"
                                                border="1px solid"
                                                borderColor={getColor(hazardData?.rahnewrisklevel) || "gray.200"}
                                                cursor={'default'}
                                            >
                                                <Square
                                                    m={0.5}
                                                    rounded="7px"
                                                    size="40px"
                                                    bg={getColor(hazardData?.rahnewrisklevel)}
                                                >
                                                    {
                                                        (hazardData?.rahnewriskcategory?.indexOf("Fatal") > -1) && <Icon
                                                            as={IoSkullOutline}
                                                            color="white"
                                                            mr="2px"
                                                        />
                                                    }

                                                    <Text
                                                        color="white"
                                                        fontWeight={700}
                                                        fontSize={(hazardData?.rahnewriskcategory?.indexOf("Fatal") > -1) ? "10pt" : "lg"}
                                                    >
                                                        {hazardData?.rahnewrisklevel}
                                                    </Text>
                                                </Square>

                                                <Text
                                                    color={getColor(hazardData?.rahnewrisklevel)}
                                                    fontSize="10pt"
                                                    fontWeight={600}
                                                    mx={3}
                                                >
                                                    {hazardData?.rahnewriskcategory}
                                                </Text>
                                            </Button>
                                            :
                                            <Button disabled>No Risk Set</Button>
                                        }
                                    </HStack>
                                </Box>
                            </Box>
                        </GridItem>
                    </Grid>

                    <Box w="100%" mt={5}>
                        {
                            hazardData?.attachments?.length > 0 &&
                            <VStack alignItems="start">
                                <FormControl>
                                    <SlideFade in={!loading} offsetY="20px">
                                        <FormLabel
                                            color="gray.600"
                                            fontWeight={600}
                                            fontSize="sm"
                                            mb={2}
                                        >
                                            Hazard Attachments
                                        </FormLabel>

                                        <Box
                                            display={'flex'}
                                            flexDirection={'row'}
                                            flexWrap={'wrap'}
                                            justifyContent={'flex-start'}
                                            alignItems={'center'}
                                            gap={3}
                                        >
                                            {
                                                hazardData?.attachments.map((a: any) => {
                                                    const thumbnail = `${process.env.REACT_APP_OBBI_API_URL}/riskAssessments/hazardAttachment?id=${riskAssessmentId}&version=${riskAssessmentVersionId}&hazard=${a.rahahazard}&attachment=${a.rahapath}&datauri=false&thumbnail=true`;
                                                    return (
                                                        <Box>
                                                            <Image
                                                                className="hover-pop"
                                                                cursor="pointer"
                                                                src={thumbnail}
                                                                fallbackSrc='https://via.placeholder.com/160'
                                                                alt={a.rahafilename}
                                                                boxSize='160px'
                                                                boxShadow="xs"
                                                                objectFit="cover"
                                                                border="1px solid"
                                                                borderColor="gray.200"
                                                                rounded="lg"
                                                                onClick={() => {
                                                                    getHazardAttachment(riskAssessmentId, riskAssessmentVersionId, a.rahahazard, a.rahapath, (a.ispdf || a.isdoc))
                                                                        .then((res: any) => {
                                                                            if (a.ispdf || a.isdoc) {
                                                                                setCurrentCert({
                                                                                    id: Number(a.rahahazard),
                                                                                    isPdf: a.ispdf,
                                                                                    fileName: a.rahafilename,
                                                                                    uri: res.datauri
                                                                                });
                                                                                setPdfIsOpen(true);
                                                                            } else {
                                                                                setCurrentImage(res.datauri);
                                                                                setIsAttachmentOpen(true);
                                                                            }
                                                                        });
                                                                }}
                                                            />
                                                        </Box>
                                                    )
                                                })
                                            }
                                        </Box>
                                    </SlideFade>
                                </FormControl>
                            </VStack>
                        }
                    </Box>
                </Box>
            </SlideFade>

            {renderImageForHazardAttachment()}
            {renderPdf()}
        </Box>
    );
};