import { Fragment, useState } from "react";
import { isEmpty, isNull, isUndefined } from "lodash";
import { Box, Icon, HStack, Text, Spinner, useBreakpointValue } from "@chakra-ui/react";
import { Combobox, Transition } from '@headlessui/react';
import { BsChevronDown, BsPlus, BsSearch } from "react-icons/bs";
import { getAction } from "../../features/actions/actions.actions";

export const ActionCategoryCombobox: React.FC<any> = ({
    categories,
    actionData,
    disabled,
    setCreateActionBody,
    createActionBody,
    update,
    setCategories,
    isCreate
}) => {
    const isMobile = useBreakpointValue({ base: true, md: false });

    const [selected, setSelected] = useState<any>("");
    const [query, setQuery] = useState("");
    const [loading, setLoading] = useState(false);

    const getDisplayValue = () =>
        (!isNull(actionData?.actioncategory) && !isUndefined(actionData?.actioncategory)) ? `${actionData?.actioncategory}` :
            (!isEmpty(selected) && !isUndefined(selected)) ? selected : ""

    const getDefaultValue = () =>
        (!isNull(actionData?.actioncategory) && !isUndefined(actionData?.actioncategory)) ? `${actionData?.actioncategory}` :
            "";

    const filteredCategories =
        query === ""
            ? categories
            : categories.filter((category: any) => {
                return (category.actioncategory.toLowerCase().includes(query.toLowerCase()))
            });

    const updateCategory = (e: any) => {
        setSelected(e);

        if (isCreate) {
            setCreateActionBody({
                ...createActionBody,
                category: e
            });
        } else {
            filteredCategories.length === 0 && query.length > 0 && setLoading(true);

            update(actionData?.actionid, "category", e);

            getAction(actionData?.actionid)
                .then((res: any) => {
                    setCategories(res.availableCategories);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => setLoading(false));
        }
    };

    const renderOptions = () => {
        let options = [];

        options.push(<Combobox.Option
            key="Uncategorised"
            value=""
        >
            {({ active }) => (
                <HStack
                    w="100%"
                    p={2}
                    bgColor={active ? "gray.50" : ""}
                    cursor="pointer"
                >
                    <Text
                        fontWeight={500}
                        fontSize="sm"
                        color="gray.600"
                        textAlign="left"
                    >
                        Uncategorised
                    </Text>
                </HStack>
            )}
        </Combobox.Option>);

        filteredCategories.map((category: any, i: number) => (
            options.push(
                <Combobox.Option
                    key={`${category.actioncategory}${i}`}
                    value={category.actioncategory}
                >
                    {({ active }) => (
                        <HStack
                            w="100%"
                            p={2}
                            bgColor={active ? "gray.50" : ""}
                            cursor="pointer"
                        >
                            <Text
                                fontWeight={500}
                                fontSize="sm"
                                color="gray.600"
                                wordBreak="break-word"
                                textAlign="left"
                            >
                                {!isNull(category.actioncategory) ? `${category.actioncategory}` : ""}
                            </Text>
                        </HStack>
                    )}
                </Combobox.Option>)
        ))

        return options;
    };

    return (
        <Combobox
            value={selected}
            disabled={disabled}
            defaultValue={getDefaultValue}
            onChange={(e: any) => updateCategory(e)}
        >
            <Box
                border="1px solid"
                borderColor={!disabled ? "#e2e8f0" : ""}
                rounded="lg"
                style={{
                    height: '32px',
                    display: 'flex',
                    justifyContent: 'flex-start',
                }}
                alignItems="center"
                justifyContent={'center'}
            >
                <Box mx={1} w="100%">
                    {
                        loading ? <HStack mr={2} padding={1}>
                            <Spinner size="sm" color="green" />
                            <Text color="gray.600" fontSize="10pt" fontWeight={500}>Adding...</Text>
                        </HStack> :

                            <Combobox.Button hidden={disabled} style={{ width: "100%" }}>
                                <HStack
                                    alignItems="center"
                                    width="100%"
                                    px={2}
                                >
                                    <Combobox.Input
                                        className="combobox"
                                        defaultValue={getDefaultValue}
                                        displayValue={getDisplayValue}
                                        onChange={(event: any) => {
                                            setQuery(event.target.value);
                                        }}
                                        style={{
                                            flexGrow: 1,
                                            padding: "4px",
                                            backgroundColor: "transparent",
                                            fontSize: isMobile ? "xs" : "sm",
                                            fontWeight: 500
                                        }}
                                    />

                                    <Icon
                                        as={BsChevronDown}
                                        color="gray.500"
                                        verticalAlign="middle"
                                    />
                                </HStack>
                            </Combobox.Button>
                    }
                </Box>
            </Box>

            <Transition
                as={Fragment}
                leave="transition ease-in duration-600"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={() => setQuery("")}
            >
                <Combobox.Options
                    as={Box}
                    disabled={disabled}
                    rounded="lg"
                    style={{
                        boxShadow: "0px 0px 10px rgba(48, 49, 51, 0.05)",
                        listStyle: "none",
                        zIndex: 999,
                        position: "absolute",
                        backgroundColor: "white",
                        border: "1px solid #E2E8F0",
                        borderTop: "none",
                        width: "100%"
                    }}
                >
                    {
                        filteredCategories.length === 0 && query !== "" ?
                            <HStack mt={2} p={2}>
                                <Icon as={BsSearch} color="gray.500" />
                                <Text
                                    color="gray.500"
                                    fontSize="10pt"
                                    fontWeight={500}
                                    cursor="pointer"
                                >
                                    Nothing found
                                </Text>
                            </HStack> : renderOptions()
                    }

                    {
                        filteredCategories.length === 0 && query.length > 0 && (
                            <Combobox.Option
                                value={query}
                                style={{
                                    background: "#37a169",
                                    borderBottomLeftRadius: "7px",
                                    borderBottomRightRadius: "7px"
                                }}
                            >
                                <HStack mt={2} p={2}>
                                    <Icon as={BsPlus} color="white" />
                                    <Text
                                        color="white"
                                        fontSize="10pt"
                                        fontWeight={500}
                                        cursor="pointer"
                                    >
                                        Add "{query}"
                                    </Text>
                                </HStack>
                            </Combobox.Option>
                        )
                    }
                </Combobox.Options>
            </Transition>
        </Combobox>
    )
};