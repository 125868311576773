import {
    Fragment,
    useCallback,
    useEffect,
    useMemo,
    useState
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    Badge,
    Box,
    Center,
    HStack,
    Icon,
    SlideFade,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    VStack,
} from '@chakra-ui/react';
import {
    useTable,
    useSortBy,
    usePagination
} from "react-table";
import {
    BsArrowDown,
    BsArrowUp,
    BsExclamationCircleFill
} from 'react-icons/bs';

import { getDrafts } from "../../features/messages/message.actions";
import { Pager } from "../common/Pager";
import { localDateTime } from "../../helpers/DayJsHelper";

export const DraftTable: React.FC<any> = ({
    draftCount,
    tabIndex,
    index,
    setPageIndex
}) => {
    const { state }: any = useLocation();
    const navigateTo = useNavigate();

    const pageSize = "6";

    const [firstTimeLoading, setFirstTimeLoading] = useState(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [currentItems, setCurrentItems] = useState([]);

    const [pageCount, setPageCount] = useState(0);
    const [sort, setSort] = useState<string>("datesentdesc");

    const columns = useMemo(
        () => [
            {
                Header: "",
                accessor: "id",
                disableSortBy: true
            },
            {
                Header: <>
                    MESSAGE {
                        sort === "subject" ?
                            <Icon as={BsArrowUp} color="gray.600" /> :
                            sort === "subjectdesc" ?
                                <Icon as={BsArrowDown} color="gray.600" /> :
                                <></>
                    }
                </>,
                accessor: "title",
                Cell: (cell: any) => {
                    return <HStack>
                        {
                            cell.value.critical === "1" ?
                                <Icon color="red.500" justifySelf="center" as={BsExclamationCircleFill} />
                                : <Fragment />
                        }
                        <Text color="gray.600">{cell.value.title || "No title"}</Text>
                    </HStack>
                }
            },
            {
                Header: <>
                    CREATED {
                        sort === "datecreated" ?
                            <Icon as={BsArrowUp} color="gray.600" /> :
                            sort === "datecreateddesc" ?
                                <Icon as={BsArrowDown} color="gray.600" /> :
                                <></>
                    }
                </>,
                accessor: "created",
                Cell: (cell: any) => <Text color="gray.600">
                    {localDateTime(cell.value)}
                </Text>
            }
        ], [sort]
    );

    const sortColumn = (term: string) => {
        switch (term) {
            case "title":
                if (sort === "subject") {
                    setSort("subjectdesc");
                } else if (sort === "subjectdesc") {
                    setSort("subject");
                } else setSort("subjectdesc");
                break;
            case "created":
                if (sort === "datecreated") {
                    setSort("datecreateddesc");
                } else if (sort === "datecreateddesc") {
                    setSort("datecreated");
                } else setSort("datecreateddesc");
                break;
        }
    };

    const fetchData = useCallback(({
        sort,
        index
    }) => {
        setLoading(true);
        const pageIndex = (index + 1).toString();

        getDrafts(sort, pageIndex)
            .then((messages: any) => {
                const formattedCurrentItems = messages.map((message: any) => {
                    return {
                        id: message.msgid,
                        title: { critical: message.msgkind, title: message.msgsubject },
                        created: message.msgdatecreated,
                    }
                });

                setCurrentItems(formattedCurrentItems);
                setPageCount(Math.ceil(draftCount / Number(pageSize)));
            })
            .catch((error) => {

            })
            .finally(() => {
                setLoading(false);
                setFirstTimeLoading(false);
            });
    }, [draftCount]);

    useEffect(() => {
        fetchData({
            sort,
            index
        });
    }, [fetchData, index, sort]);

    function DataTable({
        data,
        columns,
        loading,
        pageCount: controlledPageCount,
        onHeaderClick
    }: any) {
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            page,
            prepareRow,
            pageCount
        } = useTable({
            columns,
            data,
            manualPagination: true,
            manualSortBy: true,
            pageCount: controlledPageCount,
            onHeaderClick,
            autoResetPage: false,
            initialState: {
                hiddenColumns: ["id"]
            }
        },
            useSortBy,
            usePagination
        );

        return (
            <Box
                p={3}
                border="1px"
                borderColor={firstTimeLoading ? "transparent" : "gray.200"}
                rounded="lg"
            >
                <SlideFade in={!loading} offsetY="20px">
                    <VStack display={{ base: "flex", lg: "none" }}>
                        {
                            currentItems.map((message: any, i: number) => (
                                <Box
                                    key={`inbox_${i}`}
                                    w="full"
                                    p={2}
                                    className="hover-pop"
                                    cursor="pointer"
                                    border="1px solid"
                                    borderColor="gray.200"
                                    backgroundColor={i % 2 ? "white" : "gray.50"}
                                    rounded="lg"
                                    boxShadow="xs"
                                    onClick={() => {
                                        navigateTo(`/messages/create/${message.id}`,
                                            {
                                                state: {
                                                    tabIndex: tabIndex,
                                                    pageIndex: index,
                                                    pageCount: state?.pageCount,
                                                    filter: state?.filter
                                                }
                                            });
                                    }}
                                >
                                    <VStack alignItems="start">
                                        <Text
                                            fontSize="sm"
                                            fontWeight={500}
                                            color="gray.600"
                                            textAlign="start"
                                            textOverflow="ellipsis"
                                            maxWidth="75ch"
                                            whiteSpace="nowrap"
                                            overflow="hidden"
                                        >
                                            {message.title.title || "Untitled Message Draft"}
                                        </Text>

                                        <Text
                                            fontSize="xs"
                                            fontWeight={500}
                                            color="gray.500"
                                            textAlign="start"
                                        >
                                            {localDateTime(message.created)}
                                        </Text>

                                        {
                                            message.title.critical === '1' && <Badge
                                                colorScheme="red"
                                                backgroundColor="white"
                                                size="sm"
                                                variant='outline'

                                            >
                                                <Icon
                                                    as={BsExclamationCircleFill}
                                                    mt="-2px"
                                                    mr="2px"
                                                    verticalAlign="middle"
                                                /> Critical
                                            </Badge>
                                        }
                                    </VStack>
                                </Box>
                            ))
                        }
                    </VStack>

                    {
                        currentItems.length < 1 && !loading ?
                            <Center m={5}>
                                <VStack>
                                    <Text
                                        fontSize="2xl"
                                        fontWeight={600}
                                    >
                                        No drafts
                                    </Text>
                                    <Text
                                        fontWeight={400}
                                        color="gray.500"
                                    >
                                        You have no message drafts saved
                                    </Text>
                                </VStack>
                            </Center> :

                            <SlideFade in={!loading} offsetY="20px">
                                <Table
                                    {...getTableProps()}
                                    id="DraftTable"
                                    variant="striped"
                                    display={{ base: "none", lg: "inline-table" }}
                                >
                                    <Thead>
                                        {
                                            headerGroups.map(headerGroup => (
                                                <Tr {...headerGroup.getHeaderGroupProps()}>
                                                    {
                                                        headerGroup.headers.map((column, i) => (
                                                            <Th
                                                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                                                onClick={() => onHeaderClick(column)}
                                                                key={`draft_header_${i}`}
                                                            >
                                                                <Text fontWeight={700} color="gray.600">
                                                                    {column.render("Header")}
                                                                </Text>
                                                            </Th>
                                                        ))
                                                    }
                                                </Tr>
                                            ))
                                        }
                                    </Thead>

                                    <Tbody {...getTableBodyProps()}>
                                        {
                                            page.map((row, i) => {
                                                prepareRow(row);
                                                return (
                                                    <Tr
                                                        {...row.getRowProps()}
                                                        className="hover-pop"
                                                        key={`draft_row_${i}`}
                                                        borderBottom="1px solid"
                                                        borderColor={"gray.100"}
                                                        cursor="pointer"
                                                        rounded="lg"
                                                        onClick={() => {
                                                            navigateTo(`/messages/create/${row.values.id}`,
                                                                {
                                                                    state: {
                                                                        tabIndex: tabIndex,
                                                                        pageIndex: index,
                                                                        pageCount: state?.pageCount,
                                                                        filter: state?.filter
                                                                    }
                                                                });
                                                        }}
                                                    >
                                                        {
                                                            row.cells.map(cell => {
                                                                return <Td {...cell.getCellProps()}>
                                                                    {cell.render("Cell")}
                                                                </Td>
                                                            })
                                                        }
                                                    </Tr>
                                                );
                                            })
                                        }
                                    </Tbody>
                                </Table>
                            </SlideFade>
                    }

                    <Box
                        mt={3}
                        textAlign="center"
                        display={pageCount > 1 ? "block" : "none"}
                    >
                        <Pager
                            pageCount={pageCount}
                            pageIndex={index}
                            setPageIndex={setPageIndex}
                        />
                    </Box>
                </SlideFade>
            </Box>
        )
    }

    return DataTable({
        data: currentItems,
        columns,
        loading,
        pageCount,
        onHeaderClick: (c: any) => sortColumn(c.id)
    });
}