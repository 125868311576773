import {
    Box,
    Button,
    Center,
    Flex,
    HStack,
    Icon,
    Image,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spacer,
    Square,
    Stack,
    Text,
    VStack
} from "@chakra-ui/react";

import hammer from "../../assets/images/hammer.svg";
import { BsFileLock, BsLock, BsLockFill, BsTelephone } from "react-icons/bs";
import { FaLock } from "react-icons/fa";
import { BiLinkAlt } from "react-icons/bi";

export const ContactSalesModal: React.FC<any> = ({
    salesModalisOpen,
    setSalesModalIsOpen,
    title,
    lineOneTitle,
    lineOne,
    lineTwoTitle,
    lineTwo,
    image,
    buttonOneText,
    buttonOneLink,
    buttonTwoText,
    buttonTwoLink,
    limitReached,
    limit,
    limitType
}) => {
    return (
        <Modal
            size="xxl"
            isOpen={salesModalisOpen}
            onClose={() => { setSalesModalIsOpen(false); }}
        >
            <ModalOverlay />

            <ModalContent>
                <ModalHeader>

                    <ModalCloseButton />
                </ModalHeader>

                <ModalBody mb={10}>
                    <Box
                        borderRadius={8}
                        borderColor="#E7E1F8"
                        borderWidth={1}
                        py={1}
                        px={3}
                        backgroundColor="#F9F7FC"
                        marginBottom={3}
                    >

                        <HStack>
                            <FaLock />
                            <Text
                                fontWeight={600}
                            >
                                Pro feature
                            </Text>
                        </HStack>
                    </Box>
                    {limitReached &&
                        <Box
                            w="full"
                            p={3}
                            bgGradient='linear(to-r, red.500, red.400)'
                            rounded="lg"
                            mb={5}
                        >
                            <Text
                                color="white"
                                fontSize="xl"
                                fontWeight={600}
                            >
                                You have reached the limit of {limit} {limitType} for your organisation!
                            </Text>
                        </Box>
                    }
                    <Box>
                        <Text
                            color="gray.600"
                            fontSize="3xl"
                            fontWeight={600}
                            mb={5}
                        >
                            {title}
                        </Text>
                        <Stack
                            direction={{ base: "column", md: "row" }}
                            w="full"
                        >
                            <Box
                                w={{ base: "full", md: "50%" }}
                            >
                                <VStack
                                    gap={5}
                                    justifyContent={'left'}
                                    alignItems={'left'}
                                >
                                    <Box>

                                        <Text
                                            color="gray.800"
                                            fontSize="md"
                                            fontWeight={600}
                                        >
                                            {lineOneTitle}
                                        </Text>
                                        <Text
                                            color="gray.500"
                                            fontSize="md"
                                            fontWeight={500}
                                        >
                                            {lineOne}
                                        </Text>
                                    </Box>
                                    <Box>
                                        <Text
                                            color="gray.800"
                                            fontSize="md"
                                            fontWeight={600}
                                        >
                                            {lineTwoTitle}
                                        </Text>
                                        <Text
                                            color="gray.500"
                                            fontSize="md"
                                            fontWeight={500}
                                        >
                                            {lineTwo}
                                        </Text>
                                    </Box>
                                    <HStack
                                        pt={5}
                                        justifyContent={'flex-start'}
                                        alignItems={'flex-start'}
                                    >
                                        <Button
                                            colorScheme="brand"
                                            _hover={{
                                                bg: "#248451"
                                            }}
                                            onClick={buttonOneLink ? () => {
                                                window.window.location.href = buttonOneLink
                                                setSalesModalIsOpen(false);
                                            } : () => { }}
                                        >
                                            <HStack>
                                                <BsTelephone />
                                                <Text>
                                                    {buttonOneText}
                                                </Text>
                                            </HStack>
                                        </Button>
                                        {buttonTwoText &&
                                            <Button
                                                color="brand.500"
                                                variant="outline"
                                                onClick={buttonTwoLink ?
                                                    () => {
                                                        window.window.location.href = buttonTwoLink
                                                        setSalesModalIsOpen(false);
                                                    } :
                                                    () => { }}
                                            >
                                                <HStack>
                                                    <BiLinkAlt size={20} />
                                                    <Text>
                                                        {buttonTwoText}
                                                    </Text>
                                                </HStack>
                                            </Button>
                                        }
                                    </HStack>
                                </VStack>
                            </Box>
                            <Spacer />
                            <Box
                                display={{ base: "none", md: "block" }}
                                bg="gray.50"
                                rounded="lg"
                                border="1px solid"
                                borderColor="gray.200"
                                width={'30%'}
                            >
                                <Image
                                    src={image || hammer}
                                    w='fill-available'
                                    p={8}
                                />
                            </Box>
                        </Stack>
                    </Box>
                </ModalBody>

                {/* <ModalFooter p={5}>
                    <HStack>
                        <Button
                            colorScheme="brand"
                            _hover={{
                                bg: "#248451"
                            }}
                            onClick={buttonOneLink ? () => {
                                window.window.location.href = buttonOneLink
                                setSalesModalIsOpen(false);
                            } : () => { }}
                        >
                            <HStack>
                                <BsTelephone />
                                <Text>
                                    {buttonOneText}
                                </Text>
                            </HStack>
                        </Button>
                        {buttonTwoText &&
                            <Button
                                color="brand.500"
                                variant="outline"
                                onClick={buttonTwoLink ?
                                    () => {
                                        window.window.location.href = buttonTwoLink
                                        setSalesModalIsOpen(false);
                                    } :
                                    () => { }}
                            >
                                <HStack>
                                    <BiLinkAlt size={20} />
                                    <Text>
                                        {buttonTwoText}
                                    </Text>
                                </HStack>
                            </Button>
                        }
                    </HStack>
                </ModalFooter> */}
            </ModalContent>
        </Modal>
    )
};