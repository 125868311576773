import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import _, { isEmpty, isNull, isUndefined } from 'lodash';
import { Document, Page, pdfjs } from "react-pdf";
import { fileTypeFromBlob } from 'file-type';
import {
	Box,
	Divider,
	Grid,
	HStack,
	Icon,
	Text,
	Button,
	VStack,
	Avatar,
	Image,
	Badge,
	Spacer,
	SlideFade,
	Stack,
	Switch,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Textarea,
	ModalFooter,
	Editable,
	EditablePreview,
	EditableTextarea,
	FormControl,
	Input,
	FormLabel,
	useToast,
	SimpleGrid,
	Center,
	Spinner,
	useBreakpointValue,
	SkeletonText
} from '@chakra-ui/react';
import {
	BsChatText,
	BsChevronRight,
	BsDashCircle,
	BsExclamationCircleFill,
	BsGear,
	BsHandThumbsUp,
	BsJournalBookmarkFill,
	BsPersonDash,
	BsPlayCircle,
	BsPlusSquare,
	BsTrash,
	BsUnlock,
	BsWrench,
	BsClipboardCheck,
	BsArrowLeftCircle,
	BsXCircle,
	BsCheckCircle,
	BsExclamationCircle,
	BsClock,
	BsPaperclip
} from 'react-icons/bs';

import "react-datepicker/dist/react-datepicker.css";

import { addAttachment, addNote, createAction, deleteActionAttachment, getAction, getAttachment, getCategories, reopenAction, updateAction } from '../../features/actions/actions.actions';
import { RootState } from '../../redux/rootReducer';
import { ActionCombobox } from './ActionCombobox';
import { ActionTeamCombobox } from './ActionTeamCombobox';
import { getCurrentTeamMembers, getCurrentTeams } from '../../features/common/common.actions';
import { DropZone } from '../common/DropZone';
import { ActionCategoryCombobox } from './ActionCategoryCombobox';
import { localDate, localDateTimeIgnoreToday } from '../../helpers/DayJsHelper';
import { CheckProductTierFlag } from '../../helpers/Utilities';
import { getMyTeams } from '../../features/admin/admin.actions';

export const Action = () => {
	const { id } = useParams();
	const { state }: any = useLocation();
	const toast = useToast();
	const isMobile = useBreakpointValue({ base: true, md: false });

	const [isCreate, setIsCreate] = useState(state?.create || false);
	const [completed, setCompleted] = useState(state?.completed || false);
	const [isCreatingAction, setIsCreatingAction] = useState(false);

	const navigateTo = useNavigate();
	const user = useSelector((state: RootState) => state.authReducer.user);

	const [loading, setLoading] = useState<boolean>(true);
	const [loadingNotes, setLoadingNotes] = useState<boolean>(true);
	const [fileUploadLoading, setFileUploadLoading] = useState(false);

	const [date, setDate] = useState<any>("");
	const [actionData, setActionData] = useState<any>({});
	const [noteData, setNoteData] = useState<any>([]);

	const [teams, setTeams] = useState<any>([]);
	const [users, setUsers] = useState<any>([]);
	const [categories, setCategories] = useState<any>([]);
	const [attachments, setAttachments] = useState<any[]>([]);

	const [addNoteIsOpen, setAddNoteIsOpen] = useState<boolean>(false);
	const [note, setNote] = useState("");

	const [completeActionIsOpen, setCompleteActionIsOpen] = useState<boolean>(false);
	const [createActionIsOpen, setCreateActionIsOpen] = useState<boolean>(false);
	const [deleteIsOpen, setDeleteIsOpen] = useState({ id: "", open: false });

	const [isOpen, setIsOpen] = useState(false);
	const [pdfIsOpen, setPdfIsOpen] = useState(false);

	const [currentImage, setCurrentImage] = useState("")
	const [currentCert, setCurrentCert] = useState({
		id: 0,
		isPdf: false,
		uri: "",
		fileName: ""
	});

	// PDF pages
	const [numPages, setNumPages] = useState(0);
	const [pageNumber, setPageNumber] = useState(1);

	const resetDocViewer = () => {
		setNumPages(0);
		setPageNumber(1);
	};

	const [teamValidationMessage, setTeamValidationMessage] = useState("");
	const [descriptionValidationMessage, setDescriptionValidationMessage] = useState("");

	const [createActionBody, setCreateActionBody] = useState<any>({
		team: 0,
		user: 0,
		priority: "",
		description: "",
		duedate: "",
		category: ""
	});

	function toggleNoteSort() {
		let sortedNotes = [...noteData];
		sortedNotes = _.reverse(sortedNotes);
		setNoteData(sortedNotes);
	};

	const renderAddNoteModal = () => {
		return <Modal
			size="xl"
			isOpen={addNoteIsOpen}
			onClose={() => { setAddNoteIsOpen(false); }}
		>
			<ModalOverlay />

			<ModalContent>
				<ModalHeader>
					<HStack>
						<Text fontWeight={700}>Add a Note</Text>
					</HStack>
					<ModalCloseButton />
				</ModalHeader>

				<ModalBody>
					<Text fontWeight={500} color="gray.500" fontSize="sm">Description </Text>
					<Textarea
						rows={4}
						onChange={(e: any) => {
							setNote(e.target.value);
						}}
					/>
				</ModalBody>

				<ModalFooter p={5}>
					<HStack>
						<Button
							size="sm"
							_hover={{ bg: "" }}
							onClick={() => {
								setAddNoteIsOpen(false);
							}}
						>
							Cancel
						</Button>

						<Button
							bg="brand.500"
							color="white"
							size="sm"
							_hover={{ bg: "" }}
							onClick={() => addActionNote()}
						>
							<Icon as={BsPlusSquare} mr={2} />
							Add Note
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	};

	const renderCompleteActionModal = () => {
		return <Modal
			size="xl"
			isOpen={completeActionIsOpen}
			onClose={() => { setCompleteActionIsOpen(false); }}
		>
			<ModalOverlay />

			<ModalContent>
				<ModalHeader>
					<HStack>
						<Text fontWeight={700}>Complete this Action</Text>
					</HStack>
					<ModalCloseButton />
				</ModalHeader>

				<ModalBody>
					<Text fontWeight={500} fontSize="md" color="gray.500">
						Are you sure you want to mark this Action as Completed?
					</Text>
				</ModalBody>

				<ModalFooter p={5}>
					<HStack>
						<Button
							size="sm"
							_hover={{ bg: "" }}
							onClick={() => {
								setCompleteActionIsOpen(false);
							}}
						>
							Cancel
						</Button>

						<Button
							bg="brand.500"
							color="white"
							size="sm"
							_hover={{ bg: "" }}
							onClick={() => {
								update(actionData.actionid, "status", "99");
							}}
						>
							<Icon as={BsCheckCircle} mr={2} />
							Complete Action
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	};

	const renderCreateActionModal = () => {
		return <Modal
			size="xl"
			isOpen={createActionIsOpen}
			onClose={() => { setCreateActionIsOpen(false); }}
		>
			<ModalOverlay />

			<ModalContent>
				<ModalHeader>
					<HStack>
						<Text fontWeight={700}>Create this Action</Text>
					</HStack>
					<ModalCloseButton />
				</ModalHeader>

				<ModalBody>
					<Text fontWeight={500} fontSize="md" color="gray.500">
						Are you sure you want to create this Action?
					</Text>
				</ModalBody>

				<ModalFooter p={5}>
					<HStack>
						<Button
							size="sm"
							_hover={{ bg: "" }}
							onClick={() => {
								setCreateActionIsOpen(false);
							}}
						>
							Cancel
						</Button>

						<Button
							bg="brand.500"
							color="white"
							size="sm"
							_hover={{ bg: "" }}
							onClick={() => create()}
						>
							{isCreatingAction ?
								<HStack>
									<Spinner size="sm" color="white" />
									<Text>
										Creating
									</Text>
								</HStack>
								:
								<HStack>
									<Icon as={BsCheckCircle} />
									<Text>
										Create
									</Text>
								</HStack>
							}
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	};

	const renderDeleteAttachmentModal = () => {
		return <Modal
			size="lg"
			isOpen={deleteIsOpen.open}
			onClose={() => { setDeleteIsOpen({ id: "", open: false }) }}
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>
					<HStack>
						<Text fontWeight={700}>Delete Attachment</Text>
					</HStack>
					<ModalCloseButton />
				</ModalHeader>

				<ModalBody>
					<Text fontWeight={500} color="gray.500" fontSize="sm">
						Are you sure you want to delete this attachment?
					</Text>
				</ModalBody>

				<ModalFooter p={5}>
					<HStack>
						<Button
							size="sm"
							_hover={{ bg: "" }}
							onClick={() => {
								setDeleteIsOpen({ id: deleteIsOpen.id, open: false });
							}}
						>
							Cancel
						</Button>

						<Button
							bg="danger.500"
							color="white"
							size="sm"
							_hover={{ bg: "" }}
							onClick={() => {
								deleteAttachment(deleteIsOpen.id);
							}}
						>
							<Icon as={BsTrash} mr={2} />
							Delete
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	};

	const renderPdf = () => {
		return <Modal
			size="4xl"
			isOpen={pdfIsOpen}
			onClose={() => {
				setPdfIsOpen(false);
				resetDocViewer();
			}}
		>
			<ModalOverlay />

			<ModalContent mt={0}>
				<ModalHeader
					style={{
						height: "50px",
						borderRadius: "5px 5px 0 0",
						background: "#38a169",
						color: "white",
					}}
				>
					<HStack gap={1} paddingRight={10}>
						<Text wordBreak="break-word" fontSize="lg" maxW="50%">{currentCert.fileName}</Text>
						<Spacer />

						<Button
							bg="white"
							color="brand.500"
							float="left"
							size="sm"
							disabled={pageNumber === 1}
							onClick={() => setPageNumber(pageNumber - 1)}
						>
							Back
						</Button>
						<Text fontSize="md">
							{pageNumber} of {numPages}
						</Text>
						<Button
							bg="white"
							color="brand.500"
							float="right"
							size="sm"
							disabled={pageNumber === numPages}
							onClick={() => setPageNumber(pageNumber + 1)}
						>
							Next
						</Button>

						<ModalCloseButton
							color="white"
							_hover={{ bg: "" }}
							_active={{ bg: "" }}
						/>
					</HStack>
				</ModalHeader>

				<ModalBody>
					<Document
						file={currentCert.uri}
						loading={
							<Center my={48}>
								<Spinner
									thickness='4px'
									speed='0.65s'
									color="brand.500"
									size="xl"
								/>
							</Center>
						}
						onLoadSuccess={onDocumentLoadSuccess}
					>
						<Page
							renderAnnotationLayer={false}
							renderTextLayer={false}
							pageNumber={pageNumber}
						/>
					</Document>
				</ModalBody>
			</ModalContent>
		</Modal>
	};

	const renderImage = () => {
		return <Modal
			size="4xl"
			isOpen={isOpen}
			onClose={() => { setIsOpen(false) }}
		>
			<ModalOverlay />
			<ModalContent mt={0}>
				<ModalHeader />
				<ModalCloseButton
					_hover={{ bg: "" }}
					_active={{ bg: "" }}
				/>
				<ModalBody>
					<Image
						src={currentImage}
						m="5px auto 15px auto"
						p={0}
					/>
				</ModalBody>
			</ModalContent>
		</Modal>
	};

	const addActionNote = () => {
		addNote(actionData.actionid, note)
			.then((res: any) => {
				setNoteData(res.action.notes);
			})
			.catch((error: any) => {
				console.log(error);
			})
			.finally(() => {
				setAddNoteIsOpen(false);
			});
	};

	const reopen = (id: string) => {
		reopenAction(id)
			.then((res: any) => {
				setActionData(res.action);
				setNoteData(res.action.notes);
			})
			.catch((error) => {
				console.log(error)
			})
			.finally(() => {
				setCompleted(false);
			});
	};

	const validateCreateActionBody = () => {
		let isValid = true;

		if (createActionBody.team === 0) {
			isValid = false;
			setTeamValidationMessage("You must assign a team");
		} else
			setTeamValidationMessage("");

		if (createActionBody.description === "") {
			isValid = false;
			setDescriptionValidationMessage("You must provide a description");
		} else
			setDescriptionValidationMessage("");

		return isValid;
	};

	const create = () => {
		setIsCreatingAction(true);

		createAction(createActionBody)
			.then((res: any) => {
				setActionData(res.action);
				setNoteData(res.action.notes);
				navigateTo(`/actions/action/${res.action.actionid}`);
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				setIsCreate(false);
				setCreateActionIsOpen(false);
				setIsCreatingAction(false);
			});
	};

	const update = (
		id: string,
		name: string,
		value: string
	) => {
		updateAction(id, name, value)
			.then((res: any) => {
				if (!res.success) {
					console.log(res.message);

					toast({
						title: res.message,
						description: "",
						status: "error",
						duration: 6000,
						isClosable: true
					});

					return;
				}

				setActionData(res.action);
				setNoteData(res.action.notes);

				const attachmentThumbnails: any[] = [];
				for (let attachment of res.action.attachments) {
					const thumbnail =
						`${process.env.REACT_APP_OBBI_API_URL}/actions/attachment?id=${attachment.aaaction}&attachment=${attachment.aapath}&pdf=${attachment.ispdf}&datauri=false&thumbnail=true`;

					attachmentThumbnails.push({
						thumbnail: thumbnail,
						fileName: attachment.aafilename,
						path: attachment.aapath,
						actionId: attachment.aaaction,
						isPdf: attachment.ispdf,
						isDoc: attachment.isdoc,
						pdfExists: attachment.pdfExists
					});
				}
				setAttachments(attachmentThumbnails);
			})
			.catch((error) => {
				console.log(error)
			})
			.finally(() => {
				if (value === "99") {
					setCompleted(true);
				} else {
					setCompleted(false);
				}
				setCompleteActionIsOpen(false);
			});
	};

	const getUsers = (id: string) => {
		getCurrentTeamMembers(id)
			.then((res: any) => {
				setUsers(res);

				setCreateActionBody(Object.assign(
					createActionBody,
					{ user: 0 })
				);
			})
			.catch((error) => {
				console.log(error);
				toast({
					title: error.message,
					description: "",
					status: "error",
					duration: 6000,
					isClosable: true
				});
			});
	};

	const deleteAttachment = (attachmentGuid: string) => {
		deleteActionAttachment(actionData.actionid, attachmentGuid)
			.then(() => {
				let updatedAttachments = attachments;
				updatedAttachments = updatedAttachments.filter(attachment => attachment.path !== attachmentGuid);

				setAttachments(updatedAttachments);
			})
			.catch((error: any) => {
				toast({
					title: error.message,
					description: "",
					status: "error",
					duration: 6000,
					isClosable: true
				});
			})
			.finally(() => setDeleteIsOpen({ id: "", open: false }));
	};

	const fetchAttachment = async (
		id: string,
		attachment: string,
		isPdf: boolean,
		isDoc: boolean,
		pdfExists: boolean
	) => {
		const pdf = isPdf || isDoc;

		if (!pdfExists && pdf) {
			alert("This attachment is currently being converted. Please try again later.");
			return;
		}

		await getAttachment(id, attachment, pdf)
			.then((res: any) => {
				if (pdf) {
					setCurrentCert({
						id: Number(id),
						isPdf: isPdf,
						fileName: res.attachment.aafilename,
						uri: res.datauri
					});
					setPdfIsOpen(true);
				} else {
					setCurrentImage(res.datauri);
					setIsOpen(true);
				}
			})
			.catch((error: any) => {
				console.log(error);
			});
	};

	const onDropAction = async (file: any) => {
		const fileExtension = await fileTypeFromBlob(file);
		const supportedFileTypes = ["gif", "png", "jpg", "jpeg", "docx", "pdf"];

		if (!supportedFileTypes.includes(fileExtension?.ext ?? "")) {
			toast({
				title: "Unsupported attachment type",
				description: "",
				status: "error",
				duration: 6000,
				isClosable: true
			});

			return;
		}
		if (attachments.length > 4) {
			toast({
				title: "You can only upload up to 5 attachments",
				description: "",
				status: "error",
				duration: 6000,
				isClosable: true
			});

			return;
		}
		setFileUploadLoading(true);

		let formData = new FormData();
		formData.append("id", actionData.actionid);
		formData.append("file", file);

		addAttachment(formData)
			.then((res: any) => {
				const thumbnail = `${process.env.REACT_APP_OBBI_API_URL}/actions/attachment?id=${res?.attachment?.aaaction}&attachment=${res?.attachment?.aapath}&datauri=false&thumbnail=true`;
				const attachment = {
					thumbnail: thumbnail,
					fileName: res?.attachment?.aafilename,
					path: res?.attachment?.aapath,
					actionId: res?.attachment?.aaaction,
					isPdf: res?.attachment?.ispdf,
					isDoc: res?.attachment?.isdoc,
					pdfExists: res?.attachment?.pdfExists
				};
				setAttachments(attachments => [...attachments, attachment]);
			})
			.catch((error) => {
				toast({
					title: error?.response?.data?.message,
					description: "",
					status: "error",
					duration: 6000,
					isClosable: true
				});
			})
			.finally(() => setFileUploadLoading(false));
	};

	useEffect(() => {
		setLoading(true);

		// Edit and Completed states only
		if (!isUndefined(id)) {
			getAction(id)
				.then((res: any) => {
					if (completed || res.action?.isCompleted) {
						setCompleted(true);
					} else {
						getCurrentTeams()
							.then((res: any) => {
								const availableTeams = res.manages;
								setTeams(availableTeams);
							})
							.catch((error) => {
								console.log(error);
							});

						if (res.action.actionteam !== 0) {
							getUsers(res.action.actionteam);
						}
					}

					setDate(res.action.duedate);
					setActionData(res.action);
					setNoteData(res.action.notes);
					setCategories(res.availableCategories);

					const attachmentThumbnails: any[] = [];
					for (let attachment of res.action.attachments) {
						const thumbnail =
							`${process.env.REACT_APP_OBBI_API_URL}/actions/attachment?id=${attachment.aaaction}&attachment=${attachment.aapath}&pdf=${attachment.ispdf}&datauri=false&thumbnail=true`;

						attachmentThumbnails.push({
							thumbnail: thumbnail,
							fileName: attachment.aafilename,
							path: attachment.aapath,
							actionId: attachment.aaaction,
							isPdf: attachment.ispdf,
							isDoc: attachment.isdoc,
							pdfExists: attachment.pdfExists
						});
					}
					setAttachments(attachmentThumbnails);
				})
				.catch((error) => {
					console.log(error);

					toast({
						title: "Cannot find this Action",
						description: "Please contact your System Administrator.",
						status: "error",
						duration: 5000,
						isClosable: true
					});

					navigateTo("/actions");
				})
				.finally(() => {
					setLoading(false);
					setLoadingNotes(false);
				});
		} else {
			// Create state only
			if (!user.isTeamManager) {
				toast({
					title: "Access Denied",
					description: "",
					status: "error",
					duration: 6000,
					isClosable: true
				});

				navigateTo("/actions");
			} else {
				getCurrentTeams()
					.then((res: any) => {
						const availableTeams = res.manages;
						setTeams(availableTeams);
						setUsers([]);
					})
					.catch((error) => {
						console.log(error);

						toast({
							title: "Cannot retrieve your Teams list",
							description: "Please contact your System Administrator.",
							status: "error",
							duration: 5000,
							isClosable: true
						});
					})
					.finally(() => {
						setLoading(false);
						setLoadingNotes(false);
					});

				getCategories()
					.then((res: any) => {
						setCategories(res.availableCategories);
					})
					.catch((error) => {
						console.log(error);
					});
			}
		}
	}, [completed]);

	function onDocumentLoadSuccess({ numPages }: any) {
		setNumPages(numPages);
	}

	useEffect(() => {
		if ((!isEmpty(actionData) && actionData?.actionteam !== "0") || createActionBody.team !== 0) {
			getUsers(actionData?.actionteam || createActionBody.team);
		}
	}, [actionData?.actionteam, createActionBody.team]);

	useEffect(() => {
		pdfjs.GlobalWorkerOptions.workerSrc =
			`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
	});

	return (
		<Box m={{ base: 0, md: 5 }}>
			{
				state?.from === "riskassessment" ?
					<Button
						display={{ base: "none", md: "unset" }}
						w="fit-content"
						mb={2}
						border="none"
						boxShadow="sm"
						size="sm"
						color="gray.600"
						variant="outline"
						fontWeight={600}
						onClick={() => {
							navigateTo(-1);
						}}
					>
						<HStack alignItems="center">
							<Icon as={BsArrowLeftCircle} />
							<Text>Back to Risk Assessment</Text>
						</HStack>
					</Button>

					: state?.from === "riskassessmentactions" ?
						<Button
							display={{ base: "none", md: "unset" }}
							w="fit-content"
							mb={2}
							border="none"
							boxShadow="sm"
							size="sm"
							color="gray.600"
							variant="outline"
							fontWeight={600}
							onClick={() => {
								navigateTo("/riskassessments", {
									state: {
										tabIndex: 1
									}
								});
							}}
						>
							<HStack alignItems="center">
								<Icon as={BsArrowLeftCircle} />
								<Text>Back to Risk Assessment Actions</Text>
							</HStack>
						</Button> :
						state?.from === 'notification' ?
							<Button
								mb={2}
								display={{ base: "none", md: "unset" }}
								w="fit-content"
								hidden={state?.from !== "notification"}
								leftIcon={<BsArrowLeftCircle />}
								size="sm"
								variant="outline"
								color="gray.600"
								fontWeight={600}
								onClick={() => {
									navigateTo(-1);
								}}
							>
								Back to Notification
							</Button>
							:
							<Button
								mb={2}
								display={{ base: "none", md: "unset" }}
								w="fit-content"
								border="none"
								boxShadow="sm"
								size="sm"
								color="gray.600"
								variant="outline"
								fontWeight={600}
								onClick={() => {
									navigateTo("/actions");
								}}
							>
								<HStack alignItems="center">
									<Icon as={BsArrowLeftCircle} />
									<Text>Back to Actions</Text>
								</HStack>
							</Button>
			}

			<Box
				h={{ base: "full", md: "unset" }}
				p={{ base: 0, md: 5 }}
				pt={{ base: 5, md: 2 }}
				bg="white"
				rounded={{ base: "none", md: "lg" }}
				boxShadow={{ base: "none", md: "lg" }}
			>
				{
					state?.from === "riskassessment" ?
						<Button
							mx={3}
							mb={{ base: 8, lg: 0 }}
							display={{ base: "unset", md: "none" }}
							size="sm"
							color="gray.600"
							variant="link"
							fontWeight={600}
							onClick={() => {
								navigateTo(-1);
							}}
						>
							<HStack>
								<Icon as={BsArrowLeftCircle} />
								<Text>Back to Risk Assessment</Text>
							</HStack>
						</Button> :

						state?.from === "riskassessmentactions" ?
							<Button
								mx={3}
								mb={{ base: 8, lg: 0 }}
								display={{ base: "unset", md: "none" }}
								size="sm"
								color="gray.600"
								variant="link"
								fontWeight={600}
								onClick={() => {
									navigateTo("/riskassessments", {
										state: {
											tabIndex: 1
										}
									});
								}}
							>
								<HStack>
									<Icon as={BsArrowLeftCircle} />
									<Text>Back to Risk Assessment Actions</Text>
								</HStack>
							</Button> :

							state?.from === 'notification' ?
								<Button
									mx={3}
									mb={{ base: 8, lg: 0 }}
									display={{ base: "unset", md: "none" }}
									size="sm"
									color="gray.600"
									variant="link"
									fontWeight={600}
									onClick={() => {
										navigateTo(-1);
									}}
								>
									<HStack>
										<Icon as={BsArrowLeftCircle} />
										<Text>Back to Notification</Text>
									</HStack>
								</Button> :

								<Button
									mx={3}
									mb={{ base: 8, lg: 0 }}
									display={{ base: "unset", md: "none" }}
									size="sm"
									color="gray.600"
									variant="link"
									fontWeight={600}
									onClick={() => {
										navigateTo("/actions");
									}}
								>
									<HStack>
										<Icon as={BsArrowLeftCircle} />
										<Text>Back to Actions</Text>
									</HStack>
								</Button>
				}

				<VStack
					m={3}
					gap={{ base: 3, md: 0 }}
					alignItems="start"
				>
					<Stack
						w="100%"
						mb={2}
						direction={{ base: "column", md: "row" }}
						alignItems={{ base: "start", md: "end" }}
					>
						<Stack direction={{ lg: "column", xl: "row" }} ml={2}>
							<VStack alignItems="start">
								<HStack>
									{
										((isCreate && createActionBody.priority === "1") || actionData?.actionpriority === "1") &&
										<Icon
											as={BsExclamationCircleFill}
											fontSize="lg"
											color="danger.500"
											alignSelf="center"
										/>
									}

									<Text
										fontWeight={700}
										fontSize={{ base: 'xl', xl: "2xl" }}
										color="gray.600"
										overflow="hidden"
										maxWidth="28ch"
										textOverflow="ellipsis"
										whiteSpace="nowrap"
									>
										{
											!loading && (isCreate ? "Create Action" : actionData.description || "Untitled")
										}
									</Text>
								</HStack>

								{
									!isCreate && <Badge
										hidden={loading}
										size="md"
										style={{ alignSelf: "baseline" }}
										colorScheme={
											actionData?.isCompleted ? "green" :
												(actionData?.overdue || actionData?.isBlocked) ? "red" :
													actionData?.nearlydue ? "orange" :
														actionData?.isOngoing ? "telegram" : "gray"
										}
									>
										<HStack>
											<Icon as={
												actionData?.isCompleted ? BsCheckCircle :
													actionData?.overdue ? BsExclamationCircle :
														actionData?.nearlydue ? BsClock :
															actionData?.isOngoing ? BsPlayCircle :
																actionData?.isNotStarted ? BsDashCircle :
																	actionData?.isBlocked && BsExclamationCircle
											}
											/>
											<Text>
												{
													actionData?.isCompleted ? "Completed" :
														actionData?.overdue ? "Overdue" :
															actionData?.nearlydue ? "Due Soon" :
																actionData?.isOngoing ? "In Progress" :
																	actionData?.isNotStarted ? "Not Started" :
																		actionData?.isBlocked && "Blocked"
												}
											</Text>
										</HStack>
									</Badge>
								}
							</VStack>
						</Stack>

						<Spacer />

						{
							loading ? <SkeletonText noOfLines={1} mt={2} /> : !isCreate &&
								<Text
									float="right"
									color="gray.500"
									textAlign="left"
									fontSize="sm"
									alignSelf="baseline"
								>
									Created by&nbsp;

									<Avatar
										name={`${actionData.creatorforename} ${actionData.creatorsurname}`}
										size="xs"
										color="white"
									/>&nbsp;

									{actionData.creatorforename} {actionData.creatorsurname} on {localDate(actionData.actiondatecreated)}
								</Text>
						}
					</Stack>
				</VStack>

				<Divider
					w="unset"
					mx={{ base: 0, md: -5 }}
				/>

				<Grid
					templateColumns={{ lg: 'repeat(2, 1fr)', xl: 'repeat(4, 1fr)' }}
					gap={5}
					m={5}
				>
					<Box>
						<VStack alignItems="start">
							{
								<FormControl
									w="100%"
									colorScheme="green"
									isRequired={isCreate && createActionBody.team === 0}
									mb={{ base: isCreate ? 0 : 3 }}
								>
									<FormLabel
										fontWeight={500}
										fontSize="sm"
										color="gray.500"
										mb={completed ? "3px" : "8px"}
									>
										Assigned to Team
									</FormLabel>

									{
										loading && !isCreate ? <SkeletonText noOfLines={1} mt="16.5px" /> : !completed && (user.isAdmin || user.isTeamManager) ?
											<SlideFade in={!loading} offsetY="20px">
												<ActionTeamCombobox
													teams={teams || []}
													actionData={actionData}
													disabled={completed || (user?.producttier?.ptactioneditingflag != undefined && user?.producttier?.ptactioneditingflag === "0")}
													setCreateActionBody={setCreateActionBody}
													createActionBody={createActionBody}
													update={update}
												/>
											</SlideFade> :

											<HStack mt="13px">
												<Avatar
													name={actionData.teamname}
													color="white"
													size="xs"
												/>
												<Text
													color="gray.600"
													fontSize="10pt"
													fontWeight={500}
												>
													{actionData.teamname}
												</Text>
											</HStack>
									}

									{
										teamValidationMessage !== "" && <Text
											fontWeight={500}
											fontSize="sm"
											color="danger.500"
										>
											{teamValidationMessage}
										</Text>
									}
								</FormControl>
							}

							<FormControl w="100%" colorScheme="green">
								{
									!isCreate && <FormLabel
										fontWeight={500}
										fontSize="sm"
										color="gray.500"
										mb={completed ? "3px" : "8px"}
									>
										Assigned to Team Member
									</FormLabel>
								}

								{
									loading && !isCreate ? <SkeletonText noOfLines={1} mt="16.5px" /> :
										(!isCreate && !completed) &&
											(user.isAdmin || user.isTeamManager) ?

											<SlideFade in={!loading} offsetY="20px">
												<ActionCombobox
													users={users || []}
													actionData={actionData}
													disabled={completed || (user?.producttier?.ptactioneditingflag != undefined && user?.producttier?.ptactioneditingflag === "0")}
													createActionBody={createActionBody}
													create={setCreateActionBody}
													update={update}
												/>
											</SlideFade> :

											!loading &&
												(!isCreate && !completed) &&
												!(user.isAdmin || user.isTeamManager) ?

												<VStack w="100%" alignItems="start" hidden={loading}>
													<HStack mt="13px">
														<Avatar
															size="xs"
															name={
																!isNull(actionData.userforename) ?
																	`${actionData.userforename} ${actionData.usersurname}` : ""
															}
															color="white"
														/>
														<Text
															fontWeight={500}
															fontSize="10pt"
															color="gray.600"
															wordBreak="break-word"
															textAlign="left"
														>
															{
																!isNull(actionData.userforename) ?
																	`${actionData.userforename} ${actionData.usersurname}` : "Unassigned"
															}
														</Text>
													</HStack>

													<Button
														variant="outline"
														color="gray.600"
														size="sm"
														hidden={completed}
														onClick={() => {
															const id = Number(actionData.actionuser) === user.id ? "" : user.id;
															update(actionData.actionid, "user", id);
														}}
													>
														<Icon
															as={Number(actionData.actionuser) === user.id ? BsPersonDash : BsHandThumbsUp}
															color="gray.500"
															mr={2}
														/>

														{
															Number(actionData.actionuser) === user.id ?
																"Unassign me" : "Assign to me"
														}
													</Button>
												</VStack> : !loading && completed &&

												<HStack mt="10px">
													<Avatar
														size="xs"
														color="white"
														name={
															!isNull(actionData.userforename) ?
																`${actionData.userforename} ${actionData.usersurname}` : ""
														}
													/>
													<Text
														fontWeight={500}
														fontSize="10pt"
														color="gray.600"
														wordBreak="break-word"
														textAlign="left"
													>
														{
															!isNull(actionData.userforename) ?
																`${actionData.userforename} ${actionData.usersurname}` : "Unassigned"
														}
													</Text>
												</HStack>
								}
							</FormControl>
						</VStack>
					</Box>

					{
						<VStack alignItems="start">
							<Text
								fontWeight={500}
								fontSize="sm"
								color="gray.500"
							>
								Due Date
							</Text>

							<FormControl w="100%">
								{
									loading && !isCreate ? <SkeletonText noOfLines={1} mt={2} /> : isCreate ? <SlideFade in={!loading} offsetY="20px">
										<Input
											className="actionDueDateInput"
											type="date"
											size="sm"
											fontSize="sm"
											onKeyDown={(e: any) => e.preventDefault()}
											onWheel={(e: any) => e.target.blur()}
											onChange={(e: any) => {
												const updated = Object.assign(
													createActionBody,
													{ "duedate": e.target.value });

												setCreateActionBody({ ...updated });
											}}
										/>
									</SlideFade> : completed ? <SlideFade in={!loading} offsetY="20px">
										<Text
											fontWeight={500}
											fontSize="10pt"
											color="gray.600"
										>
											{localDate(actionData?.actionduedate) || "None"}
										</Text>
									</SlideFade> :

										<SlideFade in={!loading} offsetY="20px">
											<Input
												className="actionDueDateInput"
												size="sm"
												type="date"
												defaultValue={date}
												disabled={completed || (user?.producttier?.ptactioneditingflag != undefined && user?.producttier?.ptactioneditingflag === "0")}
												onKeyDown={(e: any) => { e.preventDefault() }}
												onWheel={(e: any) => e.target.blur()}
												onChange={(e: any) => {
													setDate(e.target.value);
													update(actionData?.actionid, "duedate", e.target.value);
												}}
											/>
										</SlideFade>
								}
							</FormControl>
						</VStack>
					}

					{
						<VStack alignItems="start">
							<Text
								fontWeight={500}
								fontSize="sm"
								color="gray.500"
							>
								Category
							</Text>

							<FormControl colorScheme="green" w="100%">
								{
									loading && !isCreate ? <SkeletonText noOfLines={1} mt={2} /> : completed ? <SlideFade in={!loading} offsetY="20px">
										<Text
											fontWeight={500}
											fontSize="10pt"
											color="gray.600"
										>
											{actionData.actioncategory || createActionBody.category || "Uncategorised"}
										</Text>
									</SlideFade> :

										<SlideFade in={!loading} offsetY="20px">
											<Box>
												<ActionCategoryCombobox
													categories={categories || []}
													actionData={actionData}
													disabled={completed || (user?.producttier?.ptactioneditingflag != undefined && user?.producttier?.ptactioneditingflag === "0")}
													createActionBody={createActionBody}
													setCreateActionBody={setCreateActionBody}
													update={update}
													setCategories={setCategories}
													isCreate={isCreate}
												/>
											</Box>
										</SlideFade>
								}
							</FormControl>
						</VStack>
					}

					{
						<VStack alignItems="start">
							<Text
								fontWeight={500}
								fontSize="sm"
								color="gray.500"
								mb={1}
							>
								Is this Action Critical?
							</Text>

							<FormControl colorScheme="green" w="100%">
								<SlideFade in={!loading} offsetY="20px">
									<Switch
										defaultChecked={actionData.actionpriority === "1"}
										size='md'
										colorScheme='brand'
										disabled={completed || (user?.producttier?.ptactioneditingflag != undefined && user?.producttier?.ptactioneditingflag === "0")}
										verticalAlign="sub"
										_selected={{
											outline: "2px solid",
											outlineColor: "brand.500"
										}}
										onChange={(e: any) => {
											if (isCreate) {
												setCreateActionBody({
													...Object.assign(
														createActionBody,
														{ "priority": e.target.checked ? "1" : "0" })
												});
											} else {
												const newPriority =
													actionData.actionpriority === "1" ? actionData.actionpriority = "0" :
														actionData.actionpriority = "1";

												update(actionData.actionid, "priority", newPriority);
											}
										}}
									/>
								</SlideFade>
							</FormControl>
						</VStack>
					}

					{
						<VStack
							alignItems="start"
							gridColumnStart={{ base: 1, lg: 5 }}
							gridColumnEnd={{ base: 3, lg: 6 }}
							hidden={isEmpty(actionData.taskid)}
						>
							<HStack>
								<Icon
									as={BsClipboardCheck}
									color="gray.500"
									fontSize="lg"
								/>

								<Text
									fontWeight={500}
									fontSize="sm"
									color="gray.500"
								>
									Associated Task
								</Text>
							</HStack>

							<Box
								className='hover-pop'
								boxShadow="sm"
								p="3"
								w="100%"
								cursor="pointer"
								rounded="lg"
								border="1px solid"
								borderColor="brand.500"
								onClick={() => {
									if (!isNull(actionData.actionteamtaskhistory)) {
										navigateTo(`/tasks/taskreport/${actionData.actionteamtaskhistory}`,
											{ state: { from: "actions", type: "team" } })
									}

									if (!isNull(actionData.actionguidedtaskhistory)) {
										navigateTo(`/tasks/taskreport/${actionData.actionguidedtaskhistory}`,
											{ state: { from: "actions", type: "guided" } })
									}

									if (!isNull(actionData.actionperiodictaskhistory)) {
										navigateTo(`/tasks/taskreport/${actionData.actionperiodictaskhistory}`,
											{ state: { from: "actions", type: "mandatory" } })
									}
								}}
							>
								<HStack>
									<VStack alignItems="start">
										<Text
											fontSize="sm"
											fontWeight={500}
											color="gray.700"
											textAlign="left"
										>
											{actionData.taskname}
										</Text>

										<Text
											fontWeight={400}
											fontSize="9pt"
											color="gray.500"
											textAlign="left"
										>
											{`By ${actionData.reportforename} ${actionData.reportsurname} on `} {localDate(actionData.reportdatesignedoff)}
										</Text>
									</VStack>

									<Spacer />

									<Icon
										as={BsChevronRight}
										fontSize="xl"
										fontWeight={400}
										color="brand.500"
									/>
								</HStack>
							</Box>
						</VStack>
					}

					{
						<VStack
							alignItems="start"
							gridColumnStart={{ base: 1, lg: 5 }}
							gridColumnEnd={{ base: 3, lg: 6 }}
							hidden={isEmpty(actionData.actionriskassessmentaction)}
						>
							<HStack>
								<Icon
									as={BsExclamationCircle}
									color="gray.500"
									fontSize="lg"
								/>

								<Text
									fontWeight={500}
									fontSize="sm"
									color="gray.500"
								>
									Associated Risk Assessment
								</Text>
							</HStack>

							<Box
								className='hover-pop'
								boxShadow="sm"
								p="3"
								w="100%"
								cursor="pointer"
								rounded="lg"
								border="1px solid"
								borderColor="brand.500"
								onClick={async () => {
									try {

										if (actionData?.riskassessment?.rastate === "99") {
											toast({
												title: "Unable to view risk assessment",
												description: "This Risk Assessment has been deleted",
												status: "error",
												duration: 6000,
												isClosable: true
											});

											return;
										}

										if (isEmpty(actionData?.riskassessment?.publishedversion) && actionData?.riskassessment?.usercanedit === false) {
											toast({
												title: "Unable to view risk assessment",
												description: "This Risk Assessment is currently in an unpublished state.  To view the risk assessment it must be published.",
												status: "error",
												duration: 6000,
												isClosable: true
											});

											return;
										}

										if (!isNull(actionData?.riskassessment)) {

											if (actionData?.riskassessment?.publishedversion === null && actionData?.riskassessment?.usercanedit === true) {
												navigateTo(`/riskassessments/edit/${actionData?.riskassessment?.raid}`, {
													state: {
														step: 1,
														isReview: true
													}
												});

												return;
											}

											navigateTo(`/riskassessments/version/${actionData.riskassessment?.rapublishedversion}`,
												{
													state: {
														from: "actions",
														raid: actionData.riskassessment?.raid,
														ravid: actionData.riskassessment?.rapublishedversion
													}
												})
										}
									} catch (error: any) {
										console.error(error);
										toast({
											title: "Error accessing risk assessment",
											description: error?.message,
											status: "error",
											duration: 6000,
											isClosable: true
										});
									}
								}}
							>
								<HStack>
									<VStack alignItems="start">
										<Text
											fontSize="sm"
											fontWeight={500}
											color="gray.700"
											textAlign="left"
										>
											{actionData?.riskassessment?.raname}
										</Text>
									</VStack>

									<Spacer />

									<Icon
										as={BsChevronRight}
										fontSize="xl"
										fontWeight={400}
										color="brand.500"
									/>
								</HStack>
								{actionData?.riskassessment?.rastate === "99" ?
									<Badge
										size="md"
										style={{ alignSelf: "baseline" }}
										colorScheme={"red"}
										mt={3}
									>
										<HStack>
											<Icon as={BsExclamationCircle} />
											<Text>
												Deleted
											</Text>
										</HStack>
									</Badge>
									:
									<>
										{
											actionData?.riskassessment?.publishedversion === null &&
											<Badge
												size="md"
												style={{ alignSelf: "baseline" }}
												colorScheme={"gray"}
												mt={3}
											>
												<HStack>
													<Icon as={BsDashCircle} />
													<Text>
														Unpublished
													</Text>
												</HStack>
											</Badge>
										}
									</>
								}
							</Box>
						</VStack>
					}
				</Grid >

				<Divider w="unset" mx={5} />

				<SimpleGrid
					columns={{ base: 1, xl: 2 }}
					gap={{ base: 5, lg: 5 }}
					m={5}
				>
					<Box gridRowStart={{ xl: 1 }} gridRowEnd={{ xl: 2 }}>
						<VStack alignItems="start" w="full" display="inline">
							<FormControl isRequired={isCreate && createActionBody.description === ""}>
								<FormLabel
									fontWeight={500}
									fontSize="sm"
									color="gray.500"
								>
									<Box display="inline-block">
										<HStack>
											<Icon as={BsChatText} fontSize="xs" />
											<Text>Description</Text>
										</HStack>
									</Box>
								</FormLabel>

								{
									loading && !isCreate ? <SkeletonText noOfLines={3} /> : <SlideFade in={!loading} offsetY="20px">
										<Editable
											defaultValue={actionData.actiondescription}
											placeholder="Enter action description"
											isPreviewFocusable={true}
											wordBreak="break-word"
											isDisabled={completed || (user?.producttier?.ptactioneditingflag != undefined && user?.producttier?.ptactioneditingflag === "0")}
											onSubmit={(e: any) => {
												if (isCreate) {
													setCreateActionBody(Object.assign(
														createActionBody,
														{ "description": e })
													);
												} else {
													update(actionData.actionid, "description", e);
												}
											}}
										>
											<EditablePreview
												fontWeight={500}
												fontSize="sm"
												color="gray.600"
												_hover={{ cursor: completed || (user?.producttier?.ptactioneditingflag != undefined && user?.producttier?.ptactioneditingflag === "0") ? "default" : "pointer" }}
											/>

											<EditableTextarea
												fontSize="sm"
												_focusVisible={{
													boxShadow: "none",
													outline: "2px solid",
													outlineColor: "brand.500"
												}}
											/>
										</Editable>

										{
											descriptionValidationMessage !== "" &&
											<Text
												fontWeight={500}
												fontSize="sm"
												color="danger.500"
											>
												{descriptionValidationMessage}
											</Text>
										}
									</SlideFade>
								}
							</FormControl>
						</VStack>
					</Box>

					{
						attachments.length > 0 && <VStack alignItems="start">
							<FormControl isRequired={isCreate && createActionBody.description === ""}>
								<SlideFade in={!loading} offsetY="20px">
									<FormLabel
										fontWeight={500}
										fontSize="sm"
										color="gray.500"
									>
										<HStack>
											<Icon as={BsPaperclip} fontSize="md" />
											<Text>Attachments</Text>
										</HStack>
									</FormLabel>

									<Grid
										templateColumns={{
											base: 'repeat(2, 3fr)',
											lg: 'repeat(3, 2fr)'
										}}
										alignItems="start"
										gap={2}
									>
										{
											attachments.map((a: any) => {
												return (
													<Box minW="160px">
														<Image
															className="hover-pop"
															cursor="pointer"
															src={a.thumbnail}
															fallbackSrc='https://via.placeholder.com/160'
															alt={a.fileName}
															boxSize='160px'
															boxShadow="xs"
															objectFit="cover"
															border="1px solid"
															borderColor="gray.200"
															rounded="lg"
															onClick={() => {
																fetchAttachment(a.actionId, a.path, a.isPdf, a.isDoc, a.pdfExists)
															}}
														/>

														{
															!completed && <Button
																className="hover-pop"
																variant="link"
																size="xs"
																color="gray.500"
																_hover={{ color: "" }}
																onClick={() => {
																	setDeleteIsOpen({ id: a.path, open: true });
																}}
															>
																<Text textDecoration="underline">Delete</Text>
															</Button>
														}
													</Box>
												)
											})
										}
									</Grid>
								</SlideFade>
							</FormControl>
						</VStack>
					}

					{
						!loading && (!isCreate && !completed && CheckProductTierFlag(user?.producttier?.ptactioneditingflag)) && <Box
							textAlign="start"
							gridColumnStart={{ base: 1, xl: 2 }}
							gridColumnEnd={2}
						>
							<Text
								fontWeight={500}
								fontSize="sm"
								color="gray.500"
								mb={2}
							>
								Upload Attachments
							</Text>

							<DropZone
								onDropAction={onDropAction}
								fileUploadLoading={fileUploadLoading}
								additionalAcceptedFileTypes={["PDF", "Docx"]}
							/>
						</Box>
					}
				</SimpleGrid>

				{
					((!loading && !completed) ||
						(completed && (actionData.isAdmin || actionData.isTeamManager))) &&
					<>
						<Divider w="unset" mx={{ base: 0, md: -5 }} />

						<HStack
							mt={5}
							mx={{ base: 3, md: 0 }}
						>
							<Button
								bg="brand.500"
								color="white"
								size="sm"
								_hover={{ bg: "" }}
								hidden={!isCreate}
								onClick={() => validateCreateActionBody() && setCreateActionIsOpen(true)}
							>
								<Icon as={BsPlusSquare} mr={2} /> Create Action
							</Button>

							<Button
								bg="brand.500"
								color="white"
								size="sm"
								_hover={{ bg: "" }}
								hidden={!actionData.isNotStarted}
								onClick={() => update(actionData.actionid, "status", "1")}
							>
								<Icon as={BsPlayCircle} mr={2} /> Start Action
							</Button>

							<Button
								variant="outline"
								color="gray.600"
								size="sm"
								hidden={!actionData.isOngoing}
								onClick={() => update(actionData.actionid, "status", "0")}
							>
								<Icon
									as={BsArrowLeftCircle}
									mr={2}
								/>
								Set to Not Started
							</Button>

							<Button
								hidden={
									!actionData.isCompleted ||
									(!actionData.isTeamManager && !actionData.isAdmin)
								}
								variant="outline"
								color="gray.600"
								size="sm"
								_hover={{ bg: "" }}
								onClick={() => reopen(actionData.actionid)}
							>
								<Icon as={BsUnlock} mr={2} /> Reopen Action
							</Button>

							<Button
								color="danger.500"
								borderColor="danger.500"
								variant="outline"
								size="sm"
								_hover={{ bg: "" }}
								hidden={
									actionData.isBlocked ||
									actionData.isCompleted ||
									isCreate
								}
								onClick={() => update(actionData.actionid, "status", "2")}
							>
								<HStack>
									<Icon as={BsXCircle} />
									<Text display={{ base: "none", md: "block" }}>
										Block Action
									</Text>
									<Text display={{ base: "block", md: "none" }}>
										Block
									</Text>
								</HStack>
							</Button>

							<Button
								bg="brand.500"
								color="white"
								size="sm"
								_hover={{ bg: "" }}
								hidden={!actionData.isBlocked}
								onClick={() => update(actionData.actionid, "status", "1")}
							>
								<Icon as={BsCheckCircle} mr={2} /> Unblock
							</Button>

							<Button
								bg="brand.500"
								color="white"
								size="sm"
								_hover={{ bg: "" }}
								hidden={!actionData.isOngoing}
								onClick={() => setCompleteActionIsOpen(true)}
							>
								<HStack>
									<Icon as={BsCheckCircle} />
									<Text display={{ base: "none", md: "block" }}>
										Complete Action
									</Text>
									<Text display={{ base: "block", md: "none" }}>
										Complete
									</Text>
								</HStack>
							</Button>
						</HStack>
					</>
				}
			</Box >

			<Box
				p={{ base: 0, md: 5 }}
				mt={5}
				pb={1}
				bg="white"
				rounded={{ base: "none", md: "lg" }}
				boxShadow={{ base: "none", md: "lg" }}
				textAlign={'center'}
				hidden={isCreate}
			>
				<Divider
					w="unset"
					mb={5}
					mx={{ base: 0, md: -5 }}
					display={{ base: "block", md: "none" }}
				/>

				<HStack m={{ base: 5, md: 0 }} mx={{ md: 5 }}>
					<Text
						fontWeight={700}
						fontSize={'xl'}
						color="gray.700"
					>
						<Icon
							as={BsJournalBookmarkFill}
							mr={3}
							verticalAlign="middle"
							fontSize="4xl"
							color="brand.500"
						/> Notes
					</Text>

					<Spacer />

					<>
						<Button
							color="gray.600"
							variant="outline"
							hidden={noteData.length < 2}
							size="sm"
							leftIcon={<Icon as={BsJournalBookmarkFill} color="gray.500" />}
							onClick={() => {
								toggleNoteSort();
							}}
						>
							Sort Notes
						</Button>
						<Button
							color="gray.600"
							variant="outline"
							size="sm"
							leftIcon={<Icon as={BsPlusSquare} color="gray.500" />}
							onClick={() => setAddNoteIsOpen(true)}
							hidden={completed || (user?.producttier?.ptactioneditingflag != undefined && user?.producttier?.ptactioneditingflag === "0")}
						>
							Add Note
						</Button>
					</>
				</HStack>

				<Box hidden={loading || isEmpty(noteData)}>
					<Divider w="unset" mt={5} mx={{ base: 0, md: -5 }} />

					<SlideFade in={!loadingNotes}>
						<Box
							m={5}
							mb={1}
							p={2}
							border="1px"
							borderRadius="12px"
							borderColor="gray.200"
						>
							{
								!isEmpty(noteData) && noteData.map((note: any, i: number) => {
									return <VStack
										key={`action_note_${i}`}
										alignItems="start"
										m={2}
										p={2}
										bg="gray.50"
										border="1px solid"
										borderColor="gray.100"
										rounded="lg"
									>
										<HStack>
											{
												note.antype !== "3" &&
												<Icon as={BsGear} color="gray.500" />
											}

											<Text fontSize="sm" textAlign="left">
												{note.antext}
											</Text>
										</HStack>

										{
											note.antype === "3" &&
											<Text fontSize="xs">
												<Avatar
													size="2xs"
													mr={1}
													color="white"
													name={`${note.creatorforename} ${note.creatorsurname}`}
												/>{`${note.creatorforename} ${note.creatorsurname}`}
											</Text>
										}

										<Text fontSize="xs" color="gray.500">
											{localDateTimeIgnoreToday(note.andatecreated)}
										</Text>
									</VStack>
								})
							}
						</Box>
					</SlideFade>
				</Box>
			</Box>

			{renderAddNoteModal()}
			{renderCompleteActionModal()}
			{renderCreateActionModal()}
			{renderDeleteAttachmentModal()}
			{renderImage()}
			{renderPdf()}
		</Box >
	)
};