import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import {
	Box,
	HStack,
	Image,
	Text,
	Button,
	Select,
	SlideFade,
	Spacer,
	Grid,
	GridItem,
	VStack,
	Center,
	Icon,
	Badge,
	TabList,
	Tab,
	Tabs,
	Stack,
	TabPanel,
	TabPanels,
	Spinner,
	useBreakpointValue,
	Avatar,
	Divider,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";
import { BsBarChart, BsCalendar2Week, BsChevronRight, BsClockHistory, BsExclamationCircleFill, BsExclamationDiamondFill, BsX } from "react-icons/bs";
import { FaClipboardCheck } from "react-icons/fa";

import { getActionInsights, getCertificateInsights, getDocumentInsights, getRiskAssessmentInsights, getTasks, getTrainingInsights } from "../../features/homepage/homepage.actions";
import { HomepageBanner } from "../shell/HomepageBanner";
import { localTime, localDate } from "../../helpers/DayJsHelper";
import { CheckProductTierFlag } from "../../helpers/Utilities";

import blocked from "./svg/blocked.svg";
import inProgress from "./svg/in_progress.svg";
import notStarted from "./svg/not_started.svg";
import toAcknowledge from "./svg/to_acknowledge.svg";
import overdue from "./svg/overdue.svg";
import incident from "./svg/new_incidents.svg";
import nothing from "./svg/nothing.svg";
import observationsInProgress from "./svg/observations_in_progress.svg";
import noTasks from "../../assets/images/no-tasks.svg";

export const HomePage = () => {
	const navigateTo = useNavigate();
	const { state }: any = useLocation();

	const isDesktop = useBreakpointValue({ base: false, lg: true });

	const org = useSelector((state: RootState) => state.authReducer);
	const user = useSelector((state: RootState) => state.authReducer.user);

	const messageFolders = useSelector(
		(state: RootState) => state.messageReducer.messageFolders
	);

	const [firstTimeLoading, setFirstTimeLoading] = useState<boolean>(true);
	const [loading, setLoading] = useState<boolean>(true);

	const [selectedTeam, setSelectedTeam] = useState(0);

	const [filter, setFilter] = useState<string>(
		state !== null && state.filter ? state.filter : ""
	);

	const [mandatoryTasks, setMandatoryTasks] = useState([]);
	const [tasks, setTasks] = useState([]);
	const [availableTeams, setAvailableTeams] = useState([]);
	const [taskCounts, setTaskCounts] = useState({
		overdueTaskCount: 0,
		tasksDueTodayCount: 0,
		tasksDueThisWeekCount: 0
	});

	const [trainingInsights, setTrainingInsights] = useState<any>({
		notStarted: 0,
		inProgress: 0,
		failedExpired: 0,
		requiredObservationCount: 0,
		inProgressObservationCount: 0
	});

	const [actionInsights, setActionInsights] = useState<any>({
		overdue: 0,
		assignedToMe: 0
	});

	const [documentInsights, setDocumentInsights] = useState<any>({
		toAcknowledge: 0
	});

	const [certficateInsights, setCertficateInsights] = useState<any>({
		expired: 0
	});

	const [riskAssessmentInsights, setRiskAssessmentInsights] = useState<any>({
		expired: 0,
		toAcknowledge: 0
	});

	const hasMandatoryTasks = () =>
		mandatoryTasks.findIndex((mt: any) => { return mt.required }) !== -1;

	const getTimeOfDay = () => {
		const today = new Date();
		const currentHour = today.getHours();

		if (currentHour >= 0 && currentHour < 12) {
			return "Good Morning";
		} else if (currentHour >= 12 && currentHour < 18) {
			return "Good Afternoon";
		} else if (currentHour >= 18 && currentHour <= 23) {
			return "Good Evening";
		}
	};

	const getInsights = () => {
		getTrainingInsights()
			.then((res: any) => {
				setTrainingInsights({
					notStarted: res.notStartedCount,
					inProgress: res.inProgressCount,
					failedExpired: res.notValidCount,
					requiredObservationCount: res.observationsRequiredCount,
					inProgressObservationCount: res.observationsInProgressCount,
				});
			})
			.catch((error: any) => {
				console.log(error);
			});

		getActionInsights()
			.then((res: any) => {
				setActionInsights({
					overdue: res.overdueCount,
					assignedToMe: res.assignedToMeCount
				});
			})
			.catch((error: any) => {
				console.log(error);
			});

		getDocumentInsights()
			.then((res: any) => {
				setDocumentInsights({
					toAcknowledge: res.needAcknowledgedCount
				});
			})
			.catch((error: any) => {
				console.log(error);
			});

		getCertificateInsights()
			.then((res: any) => {
				setCertficateInsights({
					expired: res.expiredCount
				});
			})
			.catch((error: any) => {
				console.log(error);
			});

		getRiskAssessmentInsights()
			.then((res: any) => {
				setRiskAssessmentInsights({
					expired: res.expiredCount,
					toAcknowledge: res.needAcknowledgedCount
				});
			})
			.catch((error: any) => {
				console.log(error);
			});
	};

	const getTaskData = useCallback(
		async () => {
			setLoading(true);
			await getTasks(selectedTeam, filter)
				.then((res: any) => {
					setAvailableTeams(res.teams);
					setTasks(res.tasks);

					if (CheckProductTierFlag(user?.producttier?.ptmandatorytasksflag)) {
						setMandatoryTasks(res.mandatoryTasks);
					}

					setTaskCounts(res.counts);
				})
				.catch((error: any) => {
					console.log(error);
				})
				.finally(() => {
					setLoading(false);
					setFirstTimeLoading(false);
				});
		}, [selectedTeam, filter]);

	useEffect(() => {
		getInsights();
	}, [user.emailVerified]);

	useEffect(() => {
		getTaskData();
	}, [getTaskData, selectedTeam]);

	const renderInsights = () => {
		return <>
			<Box
				mt={{ base: 5, md: 0 }}
				h={{ base: "full", md: "unset" }}
				p={5}
				pt={3}
				bg="white"
				w="100%"
				rounded="lg"
				boxShadow={{ base: "none", xl: "lg" }}
				border={{ base: "1px solid", xl: "none" }}
				borderColor="gray.100"
			>
				<Text
					color="gray.600"
					fontWeight={500}
					fontSize={{ base: "md", md: "lg" }}
					mb={3}
				>
					Training and Awareness
				</Text>

				<Grid
					templateColumns={{ base: "repeat(1, 4fr)", md: "repeat(2, 1fr)" }}
					gap={3}
				>
					<Box
						w="100%"
						p={isDesktop ? 3 : 2}
						bg="gray.50"
						rounded="lg"
						border="1px solid"
						borderColor="gray.200"
						boxShadow="xs"
						className="hover-pop"
						cursor="pointer"
						onClick={() => {
							navigateTo("/training", {
								state: {
									filter: "notstarted",
									from: "home"
								},
							});
						}}
					>
						<HStack gap={1}>
							{
								firstTimeLoading ? <Spinner thickness="3px" color="gray.500" /> :
									<Text
										fontSize={trainingInsights.notStarted > 99 ? "19pt" : "3xl"}
										fontWeight={700}
										color={trainingInsights.notStarted < 1 ? "gray.500" : "gray.500"}
									>
										{trainingInsights.notStarted}
									</Text>
							}

							<Text fontSize={{ base: "sm", md: "9pt" }} fontWeight={500} color="gray.600">Courses Not Started</Text>
							<Spacer />

							<Image
								display={{ base: "none", sm: "flex" }}
								src={notStarted}
								ml={{ base: 2, md: 5 }}
								mr={{ base: 6, md: 12 }}
							/>
						</HStack>
					</Box>

					<Box
						w="100%"
						p={isDesktop ? 3 : 2}
						bg="gray.50"
						rounded="lg"
						border="1px solid"
						borderColor="gray.200"
						boxShadow="xs"
						className="hover-pop"
						cursor="pointer"
						onClick={() => {
							navigateTo("/training", {
								state: {
									filter: "inprogress",
									from: "home"
								},
							});
						}}
					>
						<HStack gap={1}>
							<Text
								fontSize={trainingInsights.inProgress > 99 ? "19pt" : "3xl"}
								fontWeight={700}
								color={trainingInsights.inProgress < 1 ? "gray.500" : "brand.500"}
							>
								{firstTimeLoading ? <Spinner thickness="3px" color="brand.500" /> : trainingInsights.inProgress}
							</Text>

							<Text fontSize={{ base: "sm", md: "9pt" }} fontWeight={500} color="gray.600">Courses in Progress</Text>

							<Spacer />

							<Image
								display={{ base: "none", sm: "flex" }}
								src={inProgress}
								ml={{ base: 2, md: 5 }}
								mr={{ base: 6, md: 12 }}
							/>
						</HStack>
					</Box>

					<Box
						w="100%"
						p={isDesktop ? 3 : 2}
						bg="gray.50"
						rounded="lg"
						border="1px solid"
						borderColor="gray.200"
						boxShadow="xs"
						className="hover-pop"
						cursor="pointer"
						onClick={() => {
							navigateTo("/training", {
								state: {
									filter: "notvalid",
									from: "home"
								},
							});
						}}
					>
						<HStack gap={1}>
							{
								firstTimeLoading ? <Spinner thickness="3px" color="gray.500" /> :
									<Text
										fontSize={trainingInsights.failedExpired > 99 ? "19pt" : "3xl"}
										fontWeight={700}
										color={trainingInsights.failedExpired < 1 ? "gray.500" : "red.500"}
									>
										{trainingInsights.failedExpired}
									</Text>
							}

							<Text fontSize={{ base: "sm", md: "9pt" }} fontWeight={500} color="gray.600">Failed or Expired Courses</Text>

							<Spacer />

							<Image
								display={{ base: "none", sm: "flex" }}
								src={incident}
								ml={{ base: 2, md: 5 }}
								mr={{ base: 6, md: 12 }}
							/>
						</HStack>
					</Box>

					<Box
						w="100%"
						p={isDesktop ? 3 : 2}
						bg="gray.50"
						rounded="lg"
						border="1px solid"
						borderColor="gray.200"
						boxShadow="xs"
						className="hover-pop"
						cursor="pointer"
						onClick={() => {
							navigateTo("/documents", {
								state: {
									filter: "needack",
									from: "home"
								},
							});
						}}
					>
						<HStack gap={1}>
							{
								firstTimeLoading ? <Spinner thickness="3px" color="danger.500" /> :
									<Text
										fontSize={documentInsights.toAcknowledge > 99 ? "19pt" : "3xl"}
										fontWeight={700}
										color={documentInsights.toAcknowledge < 1 ? "gray.500" : "danger.500"}
									>
										{documentInsights.toAcknowledge}
									</Text>
							}

							<Text fontSize={{ base: "sm", md: "9pt" }} fontWeight={500} color="gray.600">Documents to Acknowledge</Text>

							<Spacer />

							<Image
								display={{ base: "none", sm: "flex" }}
								src={toAcknowledge}
								ml={{ base: 2, md: 5 }}
								mr={{ base: 6, md: 12 }}
							/>
						</HStack>
					</Box>

					{
						(trainingInsights?.requiredObservationCount > 0 && user.isTeamManager && CheckProductTierFlag(user?.producttier?.ptobservedtasksflag)) && <Box
							w="100%"
							p={isDesktop ? 3 : 2}
							bg="gray.50"
							rounded="lg"
							border="1px solid"
							borderColor="gray.200"
							boxShadow="xs"
							className="hover-pop"
							cursor="pointer"
							onClick={() => {
								navigateTo("/tasks", {
									state: {
										navData: { tabIndex: 1, filter: "required" },
										from: "home"
									}
								});
							}}
						>
							<HStack gap={1}>
								{
									firstTimeLoading ? <Spinner thickness="3px" color="danger.500" /> :
										<Text
											fontSize={trainingInsights.requiredObservationCount > 99 ? "19pt" : "3xl"}
											fontWeight={700}
											color={trainingInsights.requiredObservationCount < 1 ? "gray.500" : "danger.500"}
										>
											{trainingInsights.requiredObservationCount}
										</Text>
								}

								<Text fontSize={{ base: "sm", md: "9pt" }} fontWeight={500} color="gray.600">Task Observations Required</Text>

								<Spacer />

								<Image
									display={{ base: "none", sm: "flex" }}
									src={nothing}
									ml={{ base: 2, md: 5 }}
									mr={{ base: 6, md: 12 }}
								/>
							</HStack>
						</Box>
					}

					{
						(trainingInsights.inProgressObservationCount > 0 && user.isTeamManager && CheckProductTierFlag(user?.producttier?.ptobservedtasksflag)) &&
						<Box
							w="100%"
							p={isDesktop ? 3 : 2}
							bg="gray.50"
							rounded="lg"
							border="1px solid"
							borderColor="gray.200"
							boxShadow="xs"
							className="hover-pop"
							cursor="pointer"
							onClick={() => {
								navigateTo("/tasks", {
									state: {
										navData: { tabIndex: 1, filter: "inProgress" },
										from: "home"
									}
								});
							}}
						>
							<HStack gap={1}>
								{
									firstTimeLoading ? <Spinner thickness="3px" color="danger.500" /> :
										<Text
											fontSize={trainingInsights.inProgressObservationCount > 99 ? "19pt" : "3xl"}
											fontWeight={700}
											color={trainingInsights.inProgressObservationCount < 1 ? "gray.500" : "danger.500"}
										>
											{trainingInsights.inProgressObservationCount}
										</Text>
								}

								<Text fontSize={{ base: "sm", md: "9pt" }} fontWeight={500} color="gray.600">Task Observations in Progress</Text>

								<Spacer />

								<Image
									display={{ base: "none", sm: "flex" }}
									src={observationsInProgress}
									ml={{ base: 2, md: 5 }}
									mr={{ base: 6, md: 12 }}
								/>
							</HStack>
						</Box>
					}
				</Grid>
			</Box>

			{
				org?.orgriskassessmentsenabled && CheckProductTierFlag(user?.producttier?.ptriskassessmentsflag) && <Box
					h={{ base: "full", md: "unset" }}
					p={5}
					pt={3}
					bg="white"
					w="100%"
					rounded="lg"
					boxShadow={{ base: "none", xl: "lg" }}
					border={{ base: "1px solid", xl: "none" }}
					borderColor="gray.100"
				>
					<Text
						mb={3}
						color="gray.600"
						fontWeight={500}
						fontSize={{ base: "md", md: "lg" }}
					>
						Safety
					</Text>

					<Grid
						templateColumns={{ base: "repeat(1, 4fr)", md: "repeat(2, 1fr)" }}
						gap={3}
					>
						<Box
							w="100%"
							hidden={user.isTeamManager ? false : true}
							p={isDesktop ? 3 : 2}
							bg="gray.50"
							rounded="lg"
							border="1px solid"
							borderColor="gray.200"
							boxShadow="xs"
							className="hover-pop"
							cursor="pointer"
							onClick={() => {
								navigateTo("/riskAssessments", {
									state: {
										filter: "expired",
										from: "home"
									},
								});
							}}
						>
							<HStack gap={1}>
								{
									firstTimeLoading ? <Spinner thickness="3px" color="danger.500" /> :
										<Text
											fontSize={riskAssessmentInsights.expired > 99 ? "19pt" : "3xl"}
											fontWeight={700}
											color={riskAssessmentInsights.expired < 1 ? "gray.500" : "danger.500"}
										>
											{riskAssessmentInsights.expired}
										</Text>
								}

								<Text fontSize={{ base: "sm", md: "9pt" }} fontWeight={500} color="gray.600">Risk Assessments to Review</Text>

								<Spacer />

								<Image
									display={{ base: "none", sm: "flex" }}
									src={incident}
									ml={{ base: 2, md: 5 }}
									mr={{ base: 6, md: 12 }}
								/>
							</HStack>
						</Box>

						<Box
							w="100%"
							p={isDesktop ? 3 : 2}
							bg="gray.50"
							rounded="lg"
							border="1px solid"
							borderColor="gray.200"
							boxShadow="xs"
							className="hover-pop"
							cursor="pointer"
							onClick={() => {
								navigateTo("/riskAssessments", {
									state: {
										filter: "needAcknowledged",
										from: "home"
									}
								});
							}}
						>
							<HStack gap={1}>
								{
									firstTimeLoading ? <Spinner thickness="3px" color="orange.500" /> :
										<Text
											fontSize={riskAssessmentInsights.toAcknowledge > 99 ? "19pt" : "3xl"}
											fontWeight={700}
											color={riskAssessmentInsights.toAcknowledge < 1 ? "gray.500" : "orange.500"}
										>
											{riskAssessmentInsights.toAcknowledge}
										</Text>
								}

								<Text fontSize={{ base: "sm", md: "9pt" }} fontWeight={500} color="gray.600">Risk Assessments to Acknowledge</Text>

								<Spacer />

								<Image
									display={{ base: "none", sm: "flex" }}
									src={toAcknowledge}
									ml={{ base: 2, md: 5 }}
									mr={{ base: 6, md: 12 }}
								/>
							</HStack>
						</Box>
					</Grid>
				</Box>
			}

			{
				(
					CheckProductTierFlag(user?.producttier?.ptactionsflag) ||
					CheckProductTierFlag(user?.producttier?.ptcertificatesflag)
				) &&

				<Box
					h={{ base: "full", md: "unset" }}
					p={5}
					pt={3}
					bg="white"
					w="100%"
					rounded="lg"
					boxShadow={{ base: "none", xl: "lg" }}
					border={{ base: "1px solid", xl: "none" }}
					borderColor="gray.100"
				>
					<Text
						mb={3}
						color="gray.600"
						fontWeight={500}
						fontSize={{ base: "md", md: "lg" }}
					>
						Compliance
					</Text>

					<Grid
						templateColumns={{ base: "repeat(1, 4fr)", md: "repeat(2, 1fr)" }}
						gap={3}
					>
						{
							CheckProductTierFlag(user?.producttier?.ptactionsflag) &&
							<>
								<Box
									w="100%"
									p={isDesktop ? 3 : 2}
									bg="gray.50"
									rounded="lg"
									border="1px solid"
									borderColor="gray.200"
									boxShadow="xs"
									className="hover-pop"
									cursor="pointer"
									onClick={() => {
										navigateTo("/actions", {
											state: {
												filter: "overdue",
												from: "home"
											},
										});
									}}
								>
									<HStack gap={1}>
										{
											firstTimeLoading ? <Spinner thickness="3px" color="danger.500" /> :
												<Text
													fontSize={actionInsights.overdue > 99 ? "19pt" : "3xl"}
													fontWeight={700}
													color={actionInsights.overdue < 1 ? "gray.500" : "danger.500"}
												>
													{actionInsights.overdue}
												</Text>
										}

										<Text fontSize={{ base: "sm", md: "9pt" }} fontWeight={500} color="gray.600">Overdue Actions</Text>

										<Spacer />

										<Image
											display={{ base: "none", sm: "flex" }}
											src={overdue}
											ml={{ base: 2, md: 5 }}
											mr={{ base: 6, md: 12 }}
										/>
									</HStack>
								</Box>

								<Box
									w="100%"
									p={isDesktop ? 3 : 2}
									bg="gray.50"
									rounded="lg"
									border="1px solid"
									borderColor="gray.200"
									boxShadow="xs"
									className="hover-pop"
									cursor="pointer"
									onClick={() => {
										navigateTo("/actions", {
											state: {
												filter: "assignedtome",
												from: "home"
											}
										});
									}}
								>
									<HStack gap={1}>
										{
											firstTimeLoading ? <Spinner thickness="3px" color="orange.500" /> :
												<Text
													fontSize={actionInsights.assignedToMe > 99 ? "19pt" : "3xl"}
													fontWeight={700}
													color={actionInsights.assignedToMe < 1 ? "gray.500" : "orange.500"}
												>
													{actionInsights.assignedToMe}
												</Text>
										}

										<Text fontSize={{ base: "sm", md: "9pt" }} fontWeight={500} color="gray.600">Actions Assigned to me</Text>

										<Spacer />

										<Image
											display={{ base: "none", sm: "flex" }}
											src={blocked}
											ml={{ base: 2, md: 5 }}
											mr={{ base: 6, md: 12 }}
										/>
									</HStack>
								</Box>
							</>
						}

						{CheckProductTierFlag(user?.producttier?.ptcertificatesflag) &&
							<Box
								w="100%"
								p={isDesktop ? 3 : 2}
								bg="gray.50"
								rounded="lg"
								border="1px solid"
								borderColor="gray.200"
								boxShadow="xs"
								className="hover-pop"
								cursor="pointer"
								onClick={() => {
									navigateTo("/certificates", {
										state: {
											filter: "expired",
											from: "home"
										}
									});
								}}
							>
								<HStack gap={1}>
									{
										firstTimeLoading ? <Spinner thickness="3px" color="orange.500" /> :
											<Text
												fontWeight={700}
												fontSize={certficateInsights.expired > 99 ? "19pt" : "3xl"}
												color={certficateInsights.expired < 1 ? "gray.500" : "danger.500"}
											>
												{certficateInsights.expired}
											</Text>
									}

									<Text
										fontSize={{ base: "sm", md: "9pt" }}
										fontWeight={500}
										color="gray.600"
									>
										Expired Certificates
									</Text>

									<Spacer />

									<Image
										display={{ base: "none", sm: "flex" }}
										src={incident}
										ml={{ base: 2, md: 5 }}
										mr={{ base: 6, md: 12 }}
									/>
								</HStack>
							</Box>
						}
					</Grid>
				</Box>
			}
		</>
	};

	const renderTasks = () => {
		return <>
			<HStack gap={2} mb={5}>
				<Icon
					as={FaClipboardCheck}
					fontSize="4xl"
					color="green.500"
				/>
				<Text
					fontWeight={{ base: 600, lg: 700 }}
					fontSize={{ base: "lg", lg: "2xl" }}
					color="gray.700"
				>
					Upcoming Tasks
				</Text>
			</HStack>

			{
				!firstTimeLoading && hasMandatoryTasks() && <Box w="full" mb={2}>
					<Text color="gray.500" fontSize="sm">Mandatory Tasks must be completed today.</Text>
				</Box>
			}

			<SlideFade in={!firstTimeLoading} offsetY="20px">
				<VStack>
					{
						mandatoryTasks?.map((task: any) => {
							if (task.required) return <Box
								key={`task_${task.ttid}`}
								className="hover-pop"
								w="100%"
								rounded="lg"
								boxShadow="xs"
								cursor="pointer"
								border="1px solid"
								borderColor="purple.100"
								bg="purple.50"
								onClick={() => {
									navigateTo(`/tasks/tasksummary/${task.ttid}`, { state: { from: "home" } });
								}}
							>
								<HStack m={3}>
									<VStack alignItems="start" w="full">
										<HStack w="full">
											<Text
												fontSize="sm"
												color="purple.500"
												fontWeight={600}
											>
												{task.taskname}
											</Text>

											<Spacer />

											<Badge
												colorScheme="purple"
												size="sm"
												variant="outline"
											>
												<HStack>
													<Icon as={BsExclamationCircleFill} />
													<Text fontSize="xs">Mandatory</Text>
												</HStack>
											</Badge>
										</HStack>

										<Stack direction={{ base: "column", md: "row" }}>
											<HStack>
												<Avatar
													name={task.teamname}
													size="xs"
													color="white"
												/>
												<Text
													fontSize="xs"
													fontWeight={400}
													color="gray.600"
												>
													{task.teamname}
												</Text>
											</HStack>

											<Spacer display={{ base: "none", md: "block" }} />

											<HStack>
												<Icon as={BsCalendar2Week} color="gray.500" />
												<Text
													fontSize="xs"
													fontWeight={400}
													color="gray.600"
												>
													Due Today
												</Text>
											</HStack>

											<Spacer display={{ base: "none", md: "block" }} />

											<HStack>
												<Icon as={BsClockHistory} color="gray.500" />
												<Text
													fontSize="xs"
													fontWeight={400}
													color="gray.600"
												>
													{
														localTime(task.periodictaskstarttime)
													}

													&nbsp;-&nbsp;

													{
														localTime(task.periodictaskendtime)
													}
												</Text>
											</HStack>
										</Stack>
									</VStack>
								</HStack>
							</Box>
						})
					}

					{
						!firstTimeLoading && hasMandatoryTasks() && <Box w="full" py={2.5}>
							<Divider />
						</Box>
					}

					<Stack
						w="full"
						pb={3}
						gap={1}
						direction={{ base: "column", xl: "row" }}
					>
						{
							!firstTimeLoading && availableTeams.length > 1 && <>
								<Select
									size={isDesktop ? "sm" : "md"}
									color="gray.600"
									rounded="lg"
									_focus={{ borderColor: "brand.500", borderWidth: "1px" }}
									value={selectedTeam || 0}
									onChange={(e: any) => {
										setSelectedTeam(e.target.value);
									}}
									placeholder={isEmpty(availableTeams) ? "" : availableTeams.length > 1 ? "All Teams" : ""}
								>
									{
										availableTeams.map((team: any) => {
											return <option key={`team_${team.teamid}`} value={team.teamid}>
												{team.teamname}
											</option>
										})
									}
								</Select>

								<Spacer display={{ base: "none", xl: "block" }} />
							</>
						}

						<HStack gap={1}>
							<Button
								className={filter === "overdue" ? "" : "hover-pop"}
								border="1px solid"
								borderColor={filter === "overdue" ? "brand.500" : "gray.200"}
								boxShadow="xs"
								rounded="lg"
								bg={filter === "overdue" ? "white" : "gray.50"}
								color="gray.500"
								fontWeight={400}
								size="sm"
								px={isDesktop ? 3 : 2}
								_hover={{ bg: "" }}
								_active={{ bg: "" }}
								onClick={() => {
									if (filter === "overdue") {
										setFilter("all");
									} else {
										setFilter("overdue");
									}
								}}
							>
								<HStack>
									{
										firstTimeLoading ? <Spinner thickness="3px" size="sm" color="red.500" />
											: <Badge size="sm" color="white" bg="red.500">{taskCounts.overdueTaskCount}</Badge>
									}

									<Text color="gray.600" fontSize={isDesktop ? "sm" : "xs"}>Overdue</Text>

									{
										filter === "overdue" && <Icon as={BsX} />
									}
								</HStack>
							</Button>

							<Button
								className={filter === "today" ? "" : "hover-pop"}
								border="1px solid"
								borderColor={filter === "today" ? "brand.500" : "gray.200"}
								boxShadow="xs"
								rounded="lg"
								bg={filter === "today" ? "white" : "gray.50"}
								color="gray.500"
								fontWeight={400}
								size="sm"
								px={isDesktop ? 3 : 2}
								_hover={{ bg: "" }}
								_active={{ bg: "" }}
								onClick={() => {
									if (filter === "today") {
										setFilter("all");
									} else {
										setFilter("today");
									}
								}}
							>
								<HStack>
									{
										firstTimeLoading ? <Spinner thickness="3px" size="sm" color="orange.400" />
											: <Badge size="sm" color="white" bg="orange.400">{taskCounts.tasksDueTodayCount}</Badge>
									}

									<Text color="gray.600" fontSize={isDesktop ? "sm" : "xs"}>
										{isDesktop ? " Due Today" : " Today"}
									</Text>

									{
										filter === "today" && <Icon as={BsX} />
									}
								</HStack>
							</Button>

							<Button
								className={filter === "thisweek" ? "" : "hover-pop"}
								border="1px solid"
								borderColor={filter === "thisweek" ? "brand.500" : "gray.200"}
								boxShadow="xs"
								rounded="lg"
								bg={filter === "thisweek" ? "white" : "gray.50"}
								color="gray.500"
								fontWeight={400}
								size="sm"
								px={isDesktop ? 3 : 2}
								_hover={{ bg: "" }}
								_active={{ bg: "" }}
								onClick={() => {
									if (filter === "thisweek") {
										setFilter("all");
									} else {
										setFilter("thisweek");
									}
								}}
							>
								<HStack>
									{
										firstTimeLoading ? <Spinner thickness="3px" size="sm" color="gray.600" />
											: <Badge size="sm" color="white" bg="gray.600">{taskCounts.tasksDueThisWeekCount}</Badge>
									}

									<Text color="gray.600" fontSize={isDesktop ? "sm" : "xs"}>This Week</Text>

									{
										filter === "thisweek" && <Icon as={BsX} />
									}
								</HStack>
							</Button>
						</HStack>
					</Stack>

					{
						isEmpty(tasks) ? <Center m={5}>
							<VStack>
								<Image mb={8} src={noTasks} />
								<Text fontSize="xl" fontWeight={600}>
									No Tasks Found
								</Text>
							</VStack>
						</Center> :

							<SlideFade in={!loading} style={{ width: "100%" }}>
								<VStack>
									{
										tasks.map((task: any) => {
											return <Box
												key={`task_${task.ttid}`}
												className="hover-pop"
												w="100%"
												rounded="lg"
												boxShadow="xs"
												cursor="pointer"
												border="1px solid"
												borderColor={
													task.canstartperiodictask && !task.canstartguidedtask && !task.canstartteamtask ? "purple.100" :
														task.overdue ? "red.100" :
															task.duetoday ? "orange.100" : "gray.200"
												}
												bg={
													task.canstartperiodictask && !task.canstartguidedtask && !task.canstartteamtask ? "purple.50" :
														task.overdue ? "red.50" :
															task.duetoday ? "#FFFAF0" : "gray.50"
												}
												onClick={() => {
													navigateTo(`/tasks/tasksummary/${task.ttid}`, { state: { from: "home" } });
												}}
											>
												<HStack m={3}>
													<VStack alignItems="start" w="full">
														<HStack w="full">
															<Text
																fontSize="sm"
																fontWeight={600}
																color={task.overdue ? "red.700" : task.duetoday ? "orange.700" : "gray.700"}
															>
																{task.taskname}
															</Text>

															<Spacer />

															<Badge
																colorScheme={task.overdue ? "red" : task.duetoday ? "orange" : "gray"}
																size="sm"
																variant="outline"
															>
																<HStack>
																	<Icon as={
																		task.overdue ? BsExclamationCircleFill :
																			task.duetoday ? BsExclamationDiamondFill :
																				BsCalendar2Week
																	} />
																	<Text>{task.whendue}</Text>
																</HStack>
															</Badge>
														</HStack>

														<Stack direction={{ base: "column", md: "row" }}>
															<HStack>
																<Avatar name={task.teamname} color="white" size="xs" /> :
																<Text
																	fontSize="xs"
																	fontWeight={400}
																	color="gray.600"
																>
																	{task.teamname}
																</Text>
															</HStack>

															<Spacer display={{ base: "none", md: "block" }} />

															{
																task.isscheduled && task.hasdatedue && <>
																	<HStack>
																		<Icon as={BsCalendar2Week} color="gray.500" />
																		<Text
																			fontSize="xs"
																			fontWeight={400}
																			color="gray.600"
																		>
																			Due {localDate(task.ttdatedue)}
																		</Text>
																	</HStack>

																	<Spacer display={{ base: "none", md: "block" }} />

																	<HStack>
																		<Icon as={BsClockHistory} color="gray.500" />
																		<Text
																			fontSize="xs"
																			fontWeight={400}
																			color="gray.600"
																		>
																			{localTime(task.ttdatedue)}
																		</Text>
																	</HStack>
																</>
															}
														</Stack>
													</VStack>
												</HStack>
											</Box>
										})
									}
								</VStack>
							</SlideFade>
					}
				</VStack>
			</SlideFade>

			<Center
				mt={6}
				mb={1.5}
				alignSelf="end"
			>
				<Button
					color="gray.600"
					size="sm"
					variant="link"
					onClick={() => { navigateTo("/tasks", { state: { from: "home" } }); }}
				>
					View all Tasks <Icon as={BsChevronRight} fontSize="xs" color="gray.600" ml={2} />
				</Button>
			</Center>
		</>
	};

	return (
		<Box
			m={{ base: 0, md: 5 }}
			bg="gray.100"
			minH="100vh"
		>
			{
				!user?.loggingOut && <Box pt={{ base: 3, md: 0 }}>
					<HomepageBanner
						welcome={`👋 ${getTimeOfDay()} ${user.forename}`}
						message=""
						unreadCriticalCount={messageFolders.unreadCriticalCount}
					/>
				</Box>
			}

			<Grid
				templateColumns={{ base: "repeat(1, 1fr)", xl: "repeat(5, 1fr)" }}
				gap={5}
			>
				<GridItem colSpan={3}>
					<Box
						p={5}
						pt={{ base: 3, xl: 5 }}
						m={{ base: 3, md: 0 }}
						bg="white"
						rounded="lg"
						boxShadow="lg"
					>
						<Tabs
							colorScheme="green"
							display={{ base: "block", xl: "none" }}
						>
							<TabList>
								<Tab width="50%" textAlign="start"><Icon as={FaClipboardCheck} mr={2} /> Tasks</Tab>
								<Tab width="50%" textAlign="start"><Icon as={BsBarChart} mr={2} /> Insights</Tab>
							</TabList>

							<TabPanels>
								<TabPanel p="25px 0 0">
									{renderTasks()}
								</TabPanel>
								<TabPanel p={0}>
									<VStack gap={2}>
										{renderInsights()}
									</VStack>
								</TabPanel>
							</TabPanels>
						</Tabs>

						<Box display={{ base: "none", xl: "block" }}>
							{renderTasks()}
						</Box>
					</Box>
				</GridItem>

				<GridItem colSpan={2} display={{ base: "none", xl: "block" }}>
					<VStack gap={3} m={{ base: 3, md: 0 }}>
						{renderInsights()}
					</VStack>
				</GridItem>
			</Grid>
		</Box>
	);
};
