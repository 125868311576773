import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
	Badge,
	Box,
	Button,
	Divider,
	Flex,
	HStack,
	Icon,
	Spacer,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
	useColorModeValue
} from "@chakra-ui/react";
import { BsEnvelope, BsPlusSquare } from "react-icons/bs";

import { RootState } from "../../redux/rootReducer";

import { DraftTable } from "./DraftTable";
import { InboxTable } from "./InboxTable";
import { SentTable } from "./SentTable";

export const Messages = () => {
	const { state }: any = useLocation();
	const navigateTo = useNavigate();

	const messageFolders = useSelector((state: RootState) => state.messageReducer.messageFolders);

	const [pageData, setPageData] = useState({
		tabIndex: state?.tabIndex || 0,
		pageIndex: state?.pageIndex || 0,
		pageCount: state?.pageCount || 0,
	});

	const setPageIndex = (index: number) => {
		setPageData({
			...pageData,
			pageIndex: index,
		});
	};

	const onTabIndexChange = (index: number) => {
		setPageData({
			...pageData,
			tabIndex: index,
			pageIndex: 0,

		});
	};

	const [clearAllInboxFilters, setClearAllInboxFilters] = useState(false);
	const [inboxClearAllFilterSelected, setInboxClearAllFilterSelected] = useState(false);

	return (
		<Box m={{ base: 0, md: 5 }} minH={{ base: "100vh", md: "unset" }}>
			<Box
				height={{ base: "max-content", md: "unset" }}
				bg={useColorModeValue("white", "gray.900")}
				boxShadow={{ base: "none", md: "lg" }}
				rounded={{ base: "none", md: "lg" }}
				px={{ base: 0, md: 5 }}
				pt={5}
				pb={3}
			>
				<HStack
					m={5}
					mt={1}
					gap={2}
				>
					<Text
						fontWeight={700}
						fontSize={{ base: "lg", lg: "2xl" }}
						color="gray.700"
					>
						<Icon
							as={BsEnvelope}
							mr={5}
							mt={-1}
							verticalAlign="middle"
							color="brand.500"
							fontSize="4xl"
						/>{" "}
						Messages
					</Text>

					<Spacer />

					<Button
						display={{ base: "block", md: "none" }}
						bg="brand.500"
						_hover={{
							bg: "#248451"
						}}
						color="white"
						size="sm"
						onClick={() => navigateTo("/messages/create")}
					>
						<HStack>
							<Icon as={BsPlusSquare} />
							<Text>New</Text>
						</HStack>
					</Button>
				</HStack>

				<Divider
					w="unset"
					mx={{ base: 0, md: -5 }}
				/>

				<Tabs
					colorScheme="green"
					defaultIndex={state?.tabIndex || 0}
					onChange={(index) => onTabIndexChange(index)}
				>
					<Flex
						justify={{ base: "center", md: "space-between" }}
						pt={2}
						pb={1}
					>
						<TabList
							mx={{ base: 0, md: 5 }}
							w={{ base: "full", md: "unset" }}
							justifyContent="center"
						>
							<Tab>
								<Text
									color={pageData.tabIndex === 0 ? "green.500" : "gray.500"}
									fontWeight={pageData.tabIndex === 0 ? 700 : 500}
								>
									Inbox
								</Text>

								<Badge
									bg={pageData.tabIndex === 0 ? "green.500" : "gray.500"}
									color="white"
									ml={2}
								>
									{messageFolders.inboxCount || 0}
								</Badge>
							</Tab>
							<Tab>
								<Text
									color={pageData.tabIndex === 1 ? "green.500" : "gray.500"}
									fontWeight={pageData.tabIndex === 1 ? 700 : 500}
								>
									Drafts
								</Text>

								<Badge
									bg={pageData.tabIndex === 1 ? "green.500" : "gray.500"}
									color="white"
									ml={2}
								>
									{messageFolders.draftCount || 0}
								</Badge>
							</Tab>
							<Tab>
								<Text
									color={pageData.tabIndex === 2 ? "green.500" : "gray.500"}
									fontWeight={pageData.tabIndex === 2 ? 700 : 500}
								>
									Sent
								</Text>
							</Tab>
						</TabList>

						<Button
							display={{ base: "none", md: "flex" }}
							float="right"
							bg="brand.500"
							_hover={{
								bg: "#248451"
							}}
							mt={5}
							mx={5}
							size="sm"
							color="white"
							onClick={() => navigateTo("/messages/create")}
						>
							<Icon as={BsPlusSquare} mr={2}></Icon> New Message
						</Button>
					</Flex>

					<TabPanels>
						<TabPanel>
							<InboxTable
								inboxCount={messageFolders.inboxCount}
								tabIndex={pageData.tabIndex}
								index={pageData.pageIndex}
								setPageIndex={setPageIndex}
								setPageData={setPageData}
								clearAllFilters={clearAllInboxFilters}
								setClearAllFilters={setClearAllInboxFilters}
								setFilterSelected={setInboxClearAllFilterSelected}
							/>
						</TabPanel>

						<TabPanel>
							<DraftTable
								draftCount={messageFolders.draftCount}
								tabIndex={pageData.tabIndex}
								index={pageData.pageIndex}
								setPageIndex={setPageIndex}
							/>
						</TabPanel>

						<TabPanel>
							<SentTable
								sentCount={messageFolders.sentCount}
								tabIndex={pageData.tabIndex}
								index={pageData.pageIndex}
								setPageIndex={setPageIndex}
							/>
						</TabPanel>
					</TabPanels>
				</Tabs>
			</Box>
		</Box>
	);
};
