import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
	Box,
	Button,
	Divider,
	FormControl,
	FormHelperText,
	FormLabel,
	HStack,
	Icon,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Stack,
	Text,
	useToast,
	VStack,
	Wrap,
} from "@chakra-ui/react";
import { BsArrowLeftCircle, BsCheckCircleFill, BsEnvelope, BsExclamationCircleFill, BsFillCheckCircleFill, BsFillExclamationCircleFill, BsLock } from "react-icons/bs";

import { RootState } from "../../redux/rootReducer";

import { Card } from "./Card";
import { CardContent } from "./CardContent";
import { CardHeader } from "./CardHeader";
import { UserAvatar } from "./UserAvatar";
import { changeEmail, getClickthroughURL, resetPassword } from "../../features/auth/auth.actions";
import _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";

export const Profile = () => {
	const { state }: any = useLocation();
	const navigateTo = useNavigate();

	const user = useSelector((state: RootState) => state.authReducer.user);
	const toast = useToast();

	const [isChangePasswordOpen, setIsChangePasswordOpen] = useState(false);
	const [currentPassword, setCurrentPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [twoFa, setTwoFa] = useState("");

	const [currentPasswordError, setCurrentPasswordError] = useState("");
	const [matchPasswordError, setMatchPasswordError] = useState("");

	const [isVerifyEmailOpen, setIsVerifyEmailOpen] = useState(false);
	const [emailAddress, setEmailAddress] = useState("");
	const [confirmEmailAddress, setConfirmEmailAddress] = useState("");

	const [confirmError, setConfirmError] = useState("");
	const [error, setError] = useState("");

	const handleClickThrough = async (path: string) => {
		let url: string = await getClickthroughURL(path);
		window.open(url, "_self");
	};

	const isValid = (password: string) => {
		if (password.length < 8) {
			return false;
		} else
			return true;
	};

	const NumberCountIsValid = (password: string) => /\d/.test(password);
	const UppercaseCountIsValid = (password: string) => /[A-Z]/.test(password);

	const canReset = () => {
		let reset = false;

		if (currentPassword.length < 1) {
			setCurrentPasswordError("You must provide your current password");

			return reset;
		} else
			setCurrentPasswordError("");

		if (newPassword !== confirmPassword) {
			setMatchPasswordError("This does not match the new password you entered");

			return reset;
		} else
			setMatchPasswordError("");

		if (isValid(newPassword) &&
			NumberCountIsValid(newPassword) &&
			UppercaseCountIsValid(newPassword)
		) {
			reset = true;
		}

		return reset;
	};

	const reset = () => {
		if (canReset()) {
			resetPassword({
				currentPassword,
				newPassword,
				confirmPassword,
				twoFa
			})
				.then((res: any) => {
					if (res.success) {
						setIsChangePasswordOpen(false);

						toast({
							title: "Your password was successfully updated",
							status: "success",
							duration: 5000,
							isClosable: true,
						});
					} else {
						setCurrentPasswordError(res.message);
					}
				})
				.catch(() => {
					toast({
						title: "We couldn't change your password",
						description: "Please try again. If this issue persists please contact your system administrator.",
						status: "error",
						duration: 5000,
						isClosable: true
					});
				});
		}
	};

	const generateAlphaString = (isCap: boolean) => {
		const baseValue = isCap ? 65 : 97;
		return (baseValue + Math.floor(Math.random() * 26));
	};

	const suggestPassword = () => {
		let suggestedPassword = String.fromCharCode(generateAlphaString(true));

		for (let i = 0; i < 6; i++) {
			suggestedPassword += String.fromCharCode(generateAlphaString(false));
		}

		for (let i = 0; i < 3; i++) {
			suggestedPassword += Math.floor(Math.random() * 10).toString();
		}

		suggestedPassword = _.shuffle(suggestedPassword).join("");

		setNewPassword(suggestedPassword);
		setConfirmPassword(suggestedPassword);
	};

	const canVerify = () => {
		if (emailAddress.length < 1) {
			setError("You must provide your email address");

			return false;
		} else
			setError("");

		if (confirmEmailAddress.length < 1) {
			setConfirmError("You must confirm your email address");

			return false;
		} else
			setConfirmError("");

		if (emailAddress !== confirmEmailAddress) {
			setConfirmError("This does not match the email address you supplied");

			return false;
		} else
			setConfirmError("");

		return true;
	};

	const sendEmail = () => {
		if (canVerify()) {
			changeEmail({ emailAddress, confirmEmailAddress })
				.then((res: any) => {
					if (res.success) {
						setIsVerifyEmailOpen(false);

						toast({
							title: "A verification email has been successfully sent",
							status: "success",
							duration: 5000,
							isClosable: true
						});
					} else {
						setConfirmError(res.message);
					}
				})
				.catch(() => {
					toast({
						title: "We couldn't send a verification email",
						description: "Please try again. If this issue persists please contact your system administrator.",
						status: "error",
						duration: 5000,
						isClosable: true
					});
				})
		}
	};

	const renderResetPasswordModal = () => {
		return <Modal
			size="lg"
			isOpen={isChangePasswordOpen}
			onClose={() => { setIsChangePasswordOpen(false); }}
		>
			<ModalOverlay />

			<ModalContent>
				<ModalHeader color="gray.600">
					<HStack>
						<Icon as={BsLock} />

						<Text>
							Change Your Password
						</Text>
					</HStack>
				</ModalHeader>

				<ModalCloseButton
					_hover={{ bg: "" }}
					_active={{ bg: "" }}
				/>

				<ModalBody>
					<Stack spacing="5">
						<FormControl isRequired>
							<FormLabel htmlFor="passwordOld">Enter Your Current Password</FormLabel>
							<Input
								id="passwordOld"
								type="password"
								onChange={(e: any) => {
									setCurrentPassword(e.target.value);
								}}
							/>

							{
								currentPasswordError && <Text color="danger.500" fontSize="sm" fontWeight={500}>{currentPasswordError}</Text>
							}
						</FormControl>

						<FormControl isRequired>
							<FormLabel htmlFor="passwordNew">New Password</FormLabel>
							<Input
								id="passwordNew"
								value={newPassword}
								onChange={(e: any) => {
									setNewPassword(e.target.value);
								}}
							/>

							<VStack alignItems="start" mt={2}>
								<HStack>
									<Icon as={isValid(newPassword) ? BsCheckCircleFill : BsExclamationCircleFill} color={isValid(newPassword) ? "brand.500" : "danger.500"} />
									<FormHelperText color="fg.muted"> At least 8 characters long</FormHelperText>
								</HStack>
								<HStack>
									<Icon as={NumberCountIsValid(newPassword) ? BsCheckCircleFill : BsExclamationCircleFill} color={NumberCountIsValid(newPassword) ? "brand.500" : "danger.500"} />
									<FormHelperText color="fg.muted"> At least 1 number</FormHelperText>
								</HStack>
								<HStack>
									<Icon as={UppercaseCountIsValid(newPassword) ? BsCheckCircleFill : BsExclamationCircleFill} color={UppercaseCountIsValid(newPassword) ? "brand.500" : "danger.500"} />
									<FormHelperText color="fg.muted"> At least 1 uppercase character</FormHelperText>
								</HStack>
							</VStack>
						</FormControl>

						<FormControl isRequired>
							<FormLabel htmlFor="passwordConfirm">Confirm New Password</FormLabel>
							<Input
								id="passwordConfirm"
								value={confirmPassword}
								onChange={(e: any) => {
									setConfirmPassword(e.target.value)
								}}
							/>

							{
								matchPasswordError !== "" && <Text color="danger.500" fontSize="sm" fontWeight={500} mt={2}>{matchPasswordError}</Text>
							}
						</FormControl>

						{
							user.has2FA && <FormControl isRequired>
								<FormLabel htmlFor="twoFa">Two Factor Authentication Code</FormLabel>
								<Input
									id="twoFa"
									type="password"
									onChange={(e: any) => {
										setTwoFa(e.target.value);
									}}
								/>
							</FormControl>
						}
					</Stack>
				</ModalBody>

				<ModalFooter>
					<VStack w="100%">
						<HStack mb={1} w="100%">
							<Button onClick={() => { setIsVerifyEmailOpen(false); }} >
								Cancel
							</Button>

							<Button onClick={() => { suggestPassword(); }} >
								Suggest Password
							</Button>
						</HStack>

						<Button
							w="100%"
							className="hover-pop"
							colorScheme="brand"
							_active={{ bg: "" }}
							_hover={{ bg: "" }}
							onClick={() => { reset(); }}
						>
							Change Password
						</Button>
					</VStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	};

	const renderVerifyEmailModal = () => {
		return <Modal
			size="lg"
			isOpen={isVerifyEmailOpen}
			onClose={() => { setIsVerifyEmailOpen(false); }}
		>
			<ModalOverlay />

			<ModalContent>
				<ModalHeader color="gray.600">
					<HStack>
						<Icon as={BsEnvelope} />

						<Text>
							Verify Your Email
						</Text>
					</HStack>
				</ModalHeader>

				<ModalCloseButton
					_hover={{ bg: "" }}
					_active={{ bg: "" }}
				/>

				<ModalBody>
					<Stack spacing="5">
						<FormControl isRequired>
							<FormLabel htmlFor="email">Please enter your email address</FormLabel>
							<Input
								id="email"
								defaultValue={user.email || ""}
								type="email"
								onChange={(e: any) => {
									setEmailAddress(e.target.value);
								}}
							/>

							{
								error !== "" && <Text color="danger.500" fontSize="sm" fontWeight={500} mt={2}>{error}</Text>
							}
						</FormControl>

						<FormControl isRequired>
							<FormLabel htmlFor="emailConfirm">Confirm your email address</FormLabel>
							<Input
								id="emailConfirm"
								type="email"
								onChange={(e: any) => {
									setConfirmEmailAddress(e.target.value);
								}}
							/>

							{
								confirmError !== "" && <Text color="danger.500" fontSize="sm" fontWeight={500} mt={2}>{confirmError}</Text>
							}
						</FormControl>
					</Stack>
				</ModalBody>

				<ModalFooter>
					<HStack>
						<Button onClick={() => { setIsVerifyEmailOpen(false); }} cursor="pointer">
							Cancel
						</Button>

						<Button
							className="hover-pop"
							colorScheme="brand"
							cursor="pointer"
							_active={{ bg: "" }}
							_hover={{ bg: "" }}
							onClick={() => { sendEmail(); }}
						>
							Get Verification Email
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	};

	useEffect(() => {
		if (user?.email !== "") setEmailAddress(user.email);
	}, [user.email]);

	return (
		<Box
			m={{ base: 0, md: 5 }}
			height={{ base: "calc(100%)", md: "unset" }}
		>
			{
				state?.from === "home" && <Button
					display={{ base: "none", md: "unset" }}
					w="fit-content"
					size="sm"
					mb={2}
					variant="outline"
					color="gray.600"
					fontWeight={600}
					onClick={() => {
						navigateTo("/");
					}}
				>
					<HStack>
						<Icon as={BsArrowLeftCircle} />
						<Text>
							Back to Home
						</Text>
					</HStack>
				</Button>
			}

			<Card>
				{
					state?.from === "home" &&
					<Button
						display={{ base: "unset", md: "none" }}
						size="sm"
						mb={8}
						textAlign="start"
						color="gray.600"
						variant="link"
						fontWeight={600}
						onClick={() => {
							navigateTo("/");
						}}
					>
						<HStack>
							<Icon as={BsArrowLeftCircle} />
							<Text>
								Back to Home
							</Text>
						</HStack>
					</Button>
				}

				<Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: '4', md: '10' }}>
					<UserAvatar
						color="white"
						name={`${user?.forename} ${user?.surname}`}
					/>
					<CardContent>
						<CardHeader
							title={`${user?.forename} ${user?.surname}`}
							action={null}
						/>

						<Text mt="1" fontWeight="medium">
							{user?.jobrole || "Employee"} at {user?.orgname}
						</Text>

						<Stack spacing="1" mt="2">
							<HStack fontSize="sm">
								<Icon as={BsEnvelope} color="gray.500" />
								<Text>{user?.email || "No associated email"}</Text>
							</HStack>

							<HStack fontSize="sm">
								<Icon
									as={user?.emailVerified ? BsFillCheckCircleFill : BsFillExclamationCircleFill}
									color={user?.emailVerified ? "brand.500" : "danger.500"}
								/>
								<Text>{user?.emailVerified ? "Email Verified" : "Unverified Email"}</Text>
							</HStack>

							{
								!user?.emailVerified && <Button
									className="hover-pop"
									colorScheme="brand"
									color="white"
									_hover={{ bg: "" }}
									_active={{ bg: "" }}
									size="sm"
									onClick={() => { setIsVerifyEmailOpen(true); }}
								>
									Verify Your Email
								</Button>
							}

							<HStack fontSize="sm">
								<Icon
									as={user?.termsAccepted ? BsFillCheckCircleFill : BsFillExclamationCircleFill}
									color={user?.termsAccepted ? "brand.500" : "danger.500"}
								/>
								<Text>{user?.termsAccepted ? "Terms and Conditions Accepted" : "Terms Not Accepted"}</Text>
							</HStack>

						</Stack>

						<Divider my={5} />

						<Text fontWeight="semibold" mb="2">
							Manage
						</Text>

						<Wrap shouldWrapChildren>
							<Button onClick={() => { setIsChangePasswordOpen(true); }} >
								Change Password
							</Button>

							<Button onClick={() => { handleClickThrough("Profile"); }}>
								More
							</Button>
						</Wrap>
					</CardContent>
				</Stack>
			</Card>

			{renderResetPasswordModal()}
			{renderVerifyEmailModal()}
		</Box>
	);
};