import { Fragment, useEffect, useState } from "react";
import { isEmpty, isUndefined } from "lodash";
import { Box, Avatar, Icon, HStack, Text } from "@chakra-ui/react";
import { Combobox, Transition } from '@headlessui/react';
import { BsChevronDown, BsPersonFill, BsSearch } from "react-icons/bs";

export const UserMessageCombobox: React.FC<any> = ({
    users,
    disabled,
    update,
    selectedUser
}) => {
    const [selected, setSelected] = useState<any>();
    const [query, setQuery] = useState("");

    if (!users.some((user: any) => user.uduid === "0")) {
        users.unshift({ uduid: "0" })
    }

    useEffect(() => {
        if (selectedUser === 0 || isEmpty(selectedUser)) {
            setSelected(undefined);
        }
    }, [selectedUser]);

    const getDisplayValue = () =>
        !isUndefined(selected?.udforename) ? `${selected?.udforename} ${selected?.udsurname}` : "";

    const filteredUsers =
        query === ""
            ? users
            : users.filter((user: any) => {
                return (user.udforename?.toLowerCase()?.includes(query.toLowerCase()) ||
                    user.udsurname?.toLowerCase()?.includes(query.toLowerCase())) ||
                    (`${user.udforename?.toLowerCase()} ${user.udsurname?.toLowerCase()}`).includes(query.toLowerCase())
            });

    const updateUser = (e: any) => {
        setSelected(e);
        update(e);
    };

    return (
        <Combobox
            value={selected}
            disabled={disabled}
            onChange={(e: any) => updateUser(e)}
        >
            <Box
                border="1px solid"
                borderColor={!disabled ? "#e2e8f0" : ""}
                rounded="lg"
                paddingY="5px"
            >
                <Box mx={2}>
                    <Combobox.Button
                        hidden={disabled}
                        style={{ width: "100%", textAlign: "start" }}
                    >
                        <Avatar
                            size="xs"
                            icon={<Icon as={BsPersonFill} />}
                            name={!isUndefined(selected?.udforename) ? `${selected?.udforename} ${selected?.udsurname}` : ""}
                            color="white"
                            verticalAlign="middle"
                        />

                        <Combobox.Input
                            className="combobox"
                            autoComplete="off"
                            defaultValue={''}
                            displayValue={getDisplayValue}
                            onChange={(event: any) => {
                                setQuery(event.target.value);
                            }}
                            style={{
                                width: "full",
                                color: "#4a5568",
                                padding: "4px",
                                backgroundColor: "transparent"
                            }}
                        />

                        <Icon
                            as={BsChevronDown}
                            color="gray.500"
                            float="right"
                            mt={2}
                        />
                    </Combobox.Button>
                </Box>
            </Box>

            <Transition
                as={Fragment}
                leave="transition ease-in duration-600"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={() => setQuery("")}
            >
                <Combobox.Options
                    as={Box}
                    disabled={disabled}
                    rounded="lg"
                    style={{
                        boxShadow: "0px 0px 10px rgba(48, 49, 51, 0.05)",
                        listStyle: "none",
                        zIndex: 999,
                        position: "absolute",
                        backgroundColor: "white",
                        border: "1px solid #E2E8F0",
                        borderTop: "none",
                        width: "76.4%"
                    }}
                >
                    {
                        filteredUsers.length === 0 && query !== "" ?
                            <HStack
                                p={2}
                                bg="white"
                                rounded="md"
                                boxShadow="md"
                            >
                                <Icon as={BsSearch} color="gray.500" />
                                <Text
                                    color="gray.500"
                                    fontSize="10pt"
                                    fontWeight={500}
                                    cursor="pointer"
                                >
                                    Nothing found
                                </Text>
                            </HStack>

                            : filteredUsers.map((user: any, i: number) => (
                                <Combobox.Option
                                    key={`${user.uduid}_${i}`}
                                    value={user}
                                >
                                    {({ active }) => {
                                        if (user.uduid !== "0") return <HStack
                                            w="100%"
                                            p={2}
                                            bgColor={active ? "gray.50" : ""}
                                            cursor="pointer"
                                        >
                                            <Avatar
                                                size="xs"
                                                name={!isEmpty(user.udforename) ? `${user.udforename} ${user.udsurname}` : ""}
                                                color="white"
                                            />

                                            <Text
                                                fontWeight={500}
                                                fontSize="sm"
                                                color="gray.600"
                                                wordBreak="break-word"
                                                textAlign="left"
                                            >
                                                {!isEmpty(user.udforename) ? `${user.udforename} ${user.udsurname}` : user.uduid === '0' ? "All Users" : "Unnamed"}
                                            </Text>
                                        </HStack>
                                    }}
                                </Combobox.Option>
                            ))
                    }
                </Combobox.Options>
            </Transition>
        </Combobox>
    )
};