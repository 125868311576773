import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import _, { isUndefined } from "lodash";
import {
	Box,
	HStack,
	Icon,
	Text,
	Badge,
	Divider,
	SlideFade,
	VStack,
	Table,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
	FormControl,
	Image,
	Input,
	Select,
	Stack,
	Button,
	Flex,
	Spacer,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	useBreakpointValue,
	Grid,
	MenuButton,
	Menu,
	MenuItem,
	MenuList,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useToast,
	Center,
} from "@chakra-ui/react";
import {
	BsArrowDown,
	BsArrowRightCircle,
	BsArrowUp,
	BsCheckCircle,
	BsDash,
	BsExclamationCircle,
	BsHandThumbsUp,
	BsFiles,
	BsInfoCircle,
	BsPencil,
	BsPlusCircle,
	BsThreeDotsVertical,
	BsTrash,
} from "react-icons/bs";

import { localDate, localDateTimeIgnoreToday } from "../../helpers/DayJsHelper";
import { useTable, useSortBy, usePagination } from "react-table";
import { SmallFilterCard } from "../common/SmallFilterCard";
import { useDebounce } from "../common/useDebounce";
import { beginNewReview, deleteRiskAssessment, getRiskAssessments } from "../../features/riskAssessments/riskAssessments.actions";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";
import { Pager } from "../common/Pager";
import RiskAssessmentActions from "./RiskAssessmentActions";
import { InsightActionCard } from "../common/InsightActionCard";

import expired from "../../assets/images/incident.svg";
import expiringsoon from "../../assets/images/certificateExpiring.svg";
import needsacknowledged from "../../assets/images/viewing-files.svg";
import { ContactSalesModal } from "../common/ContactSalesModal";
import { CheckIsWithinProductTierLimit, CheckProductTierFlag } from "../../helpers/Utilities";
import construction from "../../assets/images/openDraft.svg";

export const RiskAssessments = () => {
	const user = useSelector((state: RootState) => state.authReducer.user);
	const canEdit = user?.isTeamManager || user?.isAdmin;

	const { state }: any = useLocation();
	const toast = useToast();

	const [pageIndex, setPageIndex] = useState(0);
	const [pageCount, setPageCount] = useState(0);

	const navigateTo = useNavigate();

	const [firstTimeLoading, setFirstTimeLoading] = useState(true);
	const [loading, setLoading] = useState<boolean>(true);
	const [categories, setCategories] = useState([]);

	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [selectedRow, setSelectedRow] = useState<any>({
		currentversionid: undefined,
		percentageacknowledged: 0,
		publishedversionid: undefined,
		racategory: "",
		radatecreated: "",
		raexpirydate: null,
		raid: "",
		raname: "",
		state: "",
		useractions: undefined
	});

	const [sort, setSort] = useState("");
	const [category, setCategory] = useState<string>("");
	const [search, setSearch] = useState<string>("");
	const [currentItems, setCurrentItems] = useState<any>([]);
	const [filter, setFilter] = useState<string>(
		state !== null && state.filter ? state.filter : "all"
	);

	const isMobile = useBreakpointValue({ base: true, md: false });
	const [tabIndex, setTabIndex] = useState(state?.tabIndex || 0);

	const [needAcknowledgedCount, setNeedAcknowledgedCount] = useState(0);
	const [expiringSoonCount, setExpiringSoonCount] = useState(0);
	const [expiredCount, setExpiredCount] = useState(0);

	const [deleteRAModalIsOpen, setDeleteRAModalIsOpen] = useState(false);
	const [targetRAForDelete, setTargetRAForDelete] = useState<any>(null);

	const [isWithinProductTierLimit, setIsWithinProductTierLimit] = useState(true);
	const [salesModalIsOpen, setSalesModalIsOpen] = useState(false);
	const hasRiskAssessmentEditingPermission = CheckProductTierFlag(user?.producttier?.ptriskassessmenteditingflag);

	const fetchData = useCallback(async ({ sort, filter, search, category, pageIndex }) => {

		pageIndex = (pageIndex + 1).toString();

		setLoading(true);

		try {
			await getRiskAssessments(sort, filter, search, category, pageIndex)
				.then(async (res: any) => {
					let displayedRAs: any = []

					let ras = res.riskAssessments;

					ras.forEach((ra: any) => {
						if (ra.publishedversion !== null) {
							ra.state = 'Published';
							displayedRAs.push(ra);
						} else if (ra.currentversion !== null) {
							ra.state = 'Draft';
							displayedRAs.push(ra);
						}

						ra.currentversionid = ra.currentversion?.ravid;
						ra.publishedversionid = ra.publishedversion?.ravid;
					});

					setPageCount(res.pageCount);
					setCategories(res.availableCategories);

					setNeedAcknowledgedCount(res.needAcknowledgedCount);
					setExpiringSoonCount(res.expiringSoonCount);
					setExpiredCount(res.expiredCount);

					setIsWithinProductTierLimit(CheckIsWithinProductTierLimit(res.orgRACount, user?.producttier?.ptriskassessmentlimit));

					setCurrentItems(displayedRAs);
				})
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
			setFirstTimeLoading(false);
		}
	}, []);

	useEffect(() => {
		fetchData({
			sort: sort,
			filter: filter,
			search: search,
			category: category,
			pageIndex: pageIndex

		});
	}, [fetchData, sort, category, pageIndex, filter]);

	const columns = useMemo(
		() => [
			{
				Header: "",
				accessor: "raid",
				disableSortBy: true,
			},
			{
				Header: "CURRENT ID",
				accessor: "currentversionid",
				disableSortBy: true,
			},
			{
				Header: "PUBLISHED ID",
				accessor: "publishedversionid",
				disableSortBy: true,
			},
			{
				Header: "User can edit",
				accessor: "usercanedit",
				disableSortBy: true,
			},
			{
				Header: <>
					TITLE {
						sort === "raname" ?
							<Icon as={BsArrowUp} color="gray.600" /> :
							sort === "ranamedesc" ?
								<Icon as={BsArrowDown} color="gray.600" /> :
								<></>
					}
				</>,
				accessor: "raname",
				Cell: (cell: any) => {
					return <Box
						display={'flex'}
						flexWrap={'wrap'}
						maxWidth={'500px'}
						width="100%"
					>
						<Text
							color="gray.600"
							fontSize="sm"
							whiteSpace="normal"
							overflow="visible"
							textOverflow="clip"
							minWidth="120px"
						>
							{cell.value || "Untitled"}
						</Text>
					</Box>

				}
			},
			{
				Header: <>
					CATEGORY {
						sort === "racategory" ?
							<Icon as={BsArrowUp} color="gray.600" /> :
							sort === "racategorydesc" ?
								<Icon as={BsArrowDown} color="gray.600" /> :
								<></>
					}
				</>,
				accessor: "racategory",
				Cell: (cell: any) => <Box
					display={'flex'}
					flexWrap={'wrap'}
					maxWidth={'400px'}
					width="100%"
				>
					<Text
						color="gray.600"
						fontSize="sm"
						whiteSpace="normal"
						overflow="visible"
						textOverflow="clip"
						minWidth="100px"
					>
						{cell.value || "Untitled"}
					</Text>
				</Box>

			},
			{
				Header: <>
					CREATED {
						sort === "radatecreated" ?
							<Icon as={BsArrowUp} color="gray.600" /> :
							sort === "radatecreateddesc" ?
								<Icon as={BsArrowDown} color="gray.600" /> :
								<></>
					}
				</>,
				accessor: "radatecreated",
				Cell: (cell: any) => <Text
					color="gray.600"
					fontSize="sm"
				>
					{localDate(cell.value)}
				</Text>
			},
			{
				Header: <>
					PUBLISHED {
						sort === "publisheddate" ?
							<Icon as={BsArrowUp} color="gray.600" /> :
							sort === "publisheddatedesc" ?
								<Icon as={BsArrowDown} color="gray.600" /> :
								<></>
					}
				</>,
				accessor: "radatepublished",
				Cell: (cell: any) => {
					return <>
						{
							cell.value ?
								<>
									<Text
										color="gray.600"
										fontSize="sm"
									>
										{localDate(cell.value)}
									</Text>
									{
										cell.row.original.expiring &&
										<Box mt={2}>
											<Badge colorScheme={"orange"}>
												<Icon
													as={BsInfoCircle}
													mt="-2px"
													mr="5px"
													verticalAlign="middle"
												/>
												Review Due Soon
											</Badge>
										</Box>
									}
									{
										cell.row.original.expired &&
										<Box mt={2}>
											<Badge colorScheme={"red"}>
												<Icon as={BsExclamationCircle} color="danger.500"
													mt="-2px"
													mr="5px"
													verticalAlign="middle"
												/>
												Overdue Review
											</Badge>
										</Box>
									}
								</>
								:
								<Icon as={BsDash} color="gray.500" />
						}
					</>
				}
			},
			{
				Header: <>
					NEXT REVIEW {
						sort === "expirydate" ?
							<Icon as={BsArrowUp} color="gray.600" /> :
							sort === "expirydatedesc" ?
								<Icon as={BsArrowDown} color="gray.600" /> :
								<></>
					}
				</>,
				accessor: "raexpirydate",
				Cell: (cell: any) => {
					return <>
						{
							cell.value ?
								<Text
									color="gray.600"
									fontSize="sm"
								>
									{localDate(cell.value)}
								</Text>
								:
								<Icon as={BsDash} color="gray.500" />
						}
					</>
				}
			},
			{
				Header: "ACKNOWLEDGEMENTS",
				accessor: "percentageacknowledged",
				disableSortBy: true,
				Cell: (cell: any) => {
					if (cell.row.original.state === 'Draft') {
						return <Icon as={BsDash} color="gray.500" />
					}

					return <Badge colorScheme={cell.value === 100 ? "green" : "gray"}>
						{cell.row.original.acknowledgementscompleted} / {cell.row.original.acknowledgementscount}
					</Badge>
				}
			},
			{
				Header: "ACKNOWLEDGED",
				accessor: "acknowledgement",
				disableSortBy: true,
				Cell: (cell: any) => {
					return <>
						{
							cell.value?.raadateacknowledged ?
								<Badge colorScheme={"green"}><Icon
									as={BsCheckCircle}
									mt="-2px"
									mr="5px"
									verticalAlign="middle"
								/>{localDateTimeIgnoreToday(cell.value?.raadateacknowledged)}</Badge>
								:
								<Icon as={BsDash} color="gray.500" />
						}
					</>
				}
			},
			{
				Header: "",
				accessor: "useractions",
				Cell: (cell: any) => <>
					{
						cell.row.original?.usercanedit &&
						<Menu>
							<MenuButton
								as={Button}
								variant="ghost"
								size="xs"
								float="right"
							>
								<Icon as={BsThreeDotsVertical} />
							</MenuButton>

							<MenuList>
								{cell.row.original?.currentversion === null ?
									<MenuItem onClick={() => createNewReview(cell.row.original.raid)}>
										<Icon as={BsFiles} mr={2} />Create New Review
									</MenuItem> :

									<MenuItem
										onClick={() =>
											navigateTo(`/riskassessments/edit/${cell.row.original.raid}`)
										}
									>
										<Icon as={BsPencil} mr={2} />Edit Draft
									</MenuItem>
								}
								<MenuItem onClick={() => showDeleteModal(cell.row.original)}>
									<Icon as={BsTrash} mr={2} />Delete
								</MenuItem>
							</MenuList>
						</Menu >
					}
				</>
			}
		], [sort]
	);

	const showDeleteModal = (data: any) => {
		setTargetRAForDelete(data);
		setDeleteRAModalIsOpen(true);
	}

	const deleteRA = async (raid: any) => {
		setTargetRAForDelete(null);
		setDeleteRAModalIsOpen(false);

		try {
			await deleteRiskAssessment(raid)
				.then((res: any) => {
					if (res?.success) {
						toast({
							title: "Risk Assessment deleted successfully",
							status: "success",
							duration: 5000,
							isClosable: true,
						});

						fetchData({
							sort: sort,
							filter: filter,
							search: search,
							category: category,
							pageIndex: pageIndex
						});
					} else {
						toast({
							title: "Could not delete the Risk Assessment",
							status: "error",
							duration: 5000,
							isClosable: true,
						});
					}
				});
		} catch (error) {
			console.log(error);
		}
	}

	const createNewReview = async (raid: any) => {
		try {
			await beginNewReview(raid)
				.then((res: any) => {
					navigateTo(`/riskassessments/edit/${res.riskAssessment.raid}`);
				});
		} catch (error) {
			console.log(error);
		}
	}

	function DataTable({
		data,
		columns,
		loading,
		pageCount: controlledPageCount,
		onHeaderClick
	}: any) {
		const {
			getTableProps,
			getTableBodyProps,
			headerGroups,
			page,
			prepareRow,
		} = useTable(
			{
				columns,
				data,
				manualPagination: true,
				manualSortBy: true,
				pageCount: controlledPageCount,
				onHeaderClick,
				autoResetPage: false,
				initialState: {
					hiddenColumns: ["raid", "currentversionid", "publishedversionid", "usercanedit", "radatecreated",
						hasRiskAssessmentEditingPermission && canEdit ? "" : "useractions",
						canEdit ? "" : "state",
						canEdit ? "" : "percentageacknowledged",
						canEdit ? "" : "raexpirydate",
						!canEdit ? "" : "acknowledgement",
					],
				}
			},
			useSortBy,
			usePagination
		);

		return (
			<SlideFade in={!loading}>

				{/* mobile view  */}
				<VStack
					display={{ base: "flex", lg: "none" }}
					mb={currentItems.length > 6 ? 5 : 0}
				>
					{
						currentItems.map((task: any, i: number) => (
							<Box key={i} w="100%">
								<Box
									p={3}
									w="full"
									className="hover-pop"
									cursor="pointer"
									boxShadow="xs"
									rounded="lg"
									border="1px solid"
									borderColor="gray.100"
									onClick={() => {
										navigateTo(`/riskassessments/version/${task?.publishedversionid ?? task?.currentversionid}`, {
											state: {
												raid: task?.raid,
												ravid: task?.publishedversionid ?? task?.currentversionid
											}
										});
									}}
								>
									<VStack alignItems="start">
										<Text
											fontSize="sm"
											fontWeight={600}
											color="gray.600"
										>
											{task.raname}
										</Text>

										<HStack>
											<Icon
												as={BsExclamationCircle}
												fontWeight={600}
												color="green.500"
											/>
											<Text
												fontSize="xs"
												fontWeight={500}
												textAlign="start"
												color="gray.500"
											>
												{task.racategory}
											</Text>
										</HStack>

										{
											canEdit && <HStack w="100%" gap={12}>
												<Box alignItems="start">
													<Text
														fontSize="8pt"
														fontWeight={600}
														textAlign="start"
														color="gray.600"
													>
														Next Review
													</Text>

													<Text
														fontSize="xs"
														fontWeight={500}
														textAlign="start"
														color="gray.500"
													>
														{localDate(task.raexpirydate) || "-"}
													</Text>
												</Box>
											</HStack>
										}

										<HStack w="100%">
											{
												!canEdit && task?.acknowledgement?.raadateacknowledged && <Badge fontSize="xs" colorScheme="green">
													<Icon
														as={BsHandThumbsUp}
														mt="-2px"
														mr={2}
														verticalAlign="middle"
													/>
													Acknowledged
												</Badge>
											}

											{
												canEdit && <Badge fontSize="xs" colorScheme={task.percentageacknowledged === 100 ? "green" : "orange"}>
													{task.acknowledgementscompleted} / {task.acknowledgementscount} &nbsp;Acknowledged
												</Badge>
											}

											<>
												{
													task.state === 'Draft' && <Badge colorScheme={"orange"} fontSize="xs">
														<Icon
															as={BsPencil}
															mt="-2px"
															mr={2}
															verticalAlign="middle"
														/>
														Draft
													</Badge>
												}

												{
													task.state === 'Published' && <Badge colorScheme={"green"} fontSize="xs">
														<Icon
															as={BsCheckCircle}
															mt="-2px"
															mr={2}
															verticalAlign="middle"
														/>
														Published
													</Badge>
												}

												{
													task.state !== 'Draft' && task.state !== 'Published' && <Badge colorScheme={"gray"} fontSize="xs">
														Unknown
													</Badge>
												}
											</>
										</HStack>
									</VStack>
								</Box>
							</Box>
						))
					}
				</VStack>

				{/* desktop view */}
				<Table
					{...getTableProps()}
					id="RiskAssessmentTable"
					variant="striped"
					display={{ base: "none", lg: "inline-table" }}
				>
					<Thead>
						{
							page.length != 0 && headerGroups.map((headerGroup, i) => (
								<Tr {...headerGroup.getHeaderGroupProps()}>
									{
										headerGroup.headers.map((column, i) => (
											<Th
												{...column.getHeaderProps(column.getSortByToggleProps())}
												onClick={() => onHeaderClick(column)}
												key={`task_header_${i}`}
											>
												<Text fontWeight={700} color="gray.600">
													{column.render("Header")}
												</Text>
											</Th>
										))
									}
								</Tr>
							))
						}
					</Thead>

					<Tbody {...getTableBodyProps()}>
						{
							page.length === 0 ?
								<Center m={5}>
									<VStack>
										<Text fontSize="xl" fontWeight={600}>
											No Risk Assessments Found
										</Text>
										<Text fontWeight={400} color="gray.500">
											You have no risk assessments available
										</Text>
									</VStack>
								</Center>
								:
								page.map((row, i) => {
									prepareRow(row);

									return (
										<Tr
											{...row.getRowProps()}
											key={i}
											bg={i % 2 ? "white" : "purple.50"}
											borderBottom="1px solid"
											borderColor="gray.100"
											cursor="pointer"
											rounded="lg"
										>
											{
												row.cells.map((cell) => {
													if (cell.column.id === 'useractions') {
														return (
															<Td wordBreak="break-word" {...cell.getCellProps()}>
																{cell.render("Cell")}
															</Td>
														)
													}
													else {
														return (
															<Td wordBreak="break-word"
																onClick={() => {
																	if ((!isUndefined(row?.values?.publishedversionid) && !isUndefined(row?.values?.currentversionid)) &&
																		row?.values?.usercanedit) {

																		setSelectedRow(row?.values);
																		setIsOpen(true);
																	} else if ((isUndefined(row?.values?.publishedversionid) && !isUndefined(row?.values?.currentversionid)) &&
																		row?.values?.usercanedit) {

																		navigateTo(`/riskassessments/edit/${row.values?.raid}`, {
																			state: {
																				isReview: true,
																				step: 1
																			}
																		});
																	} else
																		navigateTo(`/riskassessments/version/${row?.values?.publishedversionid ?? row?.values?.currentversionid}`, {
																			state: {
																				raid: row.values?.raid,
																				ravid: row?.values?.publishedversionid ?? row.values?.currentversionid
																			}
																		});
																}}
																{...cell.getCellProps()}>
																{cell.render("Cell")}
															</Td>
														);
													}
												})
											}
										</Tr>
									);
								})
						}
					</Tbody>
				</Table>

				{
					pageCount > 1 && <Box
						mt={3}
						textAlign="center"
					>
						<Pager
							pageCount={pageCount || 0}
							pageIndex={pageIndex || 0}
							setPageIndex={setPageIndex}
						/>
					</Box>
				}
			</SlideFade>
		);
	}

	const updateCategory = (category: string) => {
		setPageIndex(0);
		setCategory(category);
	};

	const debouncedRequest = useDebounce(() => {
		setPageIndex(0);

		fetchData(
			{
				sort: sort,
				search: search,
				category: category,
				pageIndex: 0
			}
		)
	}, 300);

	const updateSearch = (e: any) => {
		setSearch(e.target.value);
		debouncedRequest();
	};

	const sortColumn = (term: string) => {
		switch (term) {
			case "raname":
				if (sort === "raname") {
					setSort("ranamedesc");
				} else if (sort === "ranamedesc") {
					setSort("raname");
				} else setSort("ranamedesc");
				break;
			case "racategory":
				if (sort === "racategory") {
					setSort("racategorydesc");
				} else if (sort === "racategorydesc") {
					setSort("racategory");
				} else setSort("racategorydesc");
				break;
			case "radatecreated":
				if (sort === "radatecreated") {
					setSort("radatecreateddesc");
				} else if (sort === "radatecreateddesc") {
					setSort("radatecreated");
				} else setSort("radatecreateddesc");
				break;
			case "raexpirydate":
				if (sort === "expirydate") {
					setSort("expirydatedesc");
				} else if (sort === "expirydatedesc") {
					setSort("expirydate");
				} else setSort("expirydatedesc");
				break;
			case "radatepublished":
				if (sort === "publisheddate") {
					setSort("publisheddatedesc");
				} else if (sort === "publisheddatedesc") {
					setSort("publisheddate");
				} else setSort("publisheddatedesc");
				break;
		}
	};

	const renderDeleteHazardModal = () => {
		return <Modal
			size="lg"
			isOpen={deleteRAModalIsOpen}
			onClose={() => { setDeleteRAModalIsOpen(false); }}
		>
			<ModalOverlay />

			<ModalContent>
				<ModalHeader>
					Delete Risk Assessment
				</ModalHeader>

				<ModalCloseButton
					_hover={{ bg: "" }}
					_active={{ bg: "" }}
				/>

				<ModalBody>
					<Text
						fontWeight={500}
						fontSize="sm"
						color="gray.600"
					>
						Are you sure you want to delete the risk assessment <b>{targetRAForDelete?.raname}</b>?
					</Text>
				</ModalBody>

				<ModalFooter>
					<HStack>
						<Button
							onClick={() => { setDeleteRAModalIsOpen(false); }}
							cursor="pointer"
						>
							Cancel
						</Button>

						<Button
							className="hover-pop"
							colorScheme="red"
							_active={{ bg: "" }}
							_hover={{ bg: "" }}
							onClick={() => { deleteRA(targetRAForDelete?.raid); }}
						>
							Delete
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	};

	const renderNavigateToRiskAssessmentModal = () => {
		return <Modal
			size="lg"
			isOpen={isOpen}
			onClose={() => { setIsOpen(false); }}
		>
			<ModalOverlay />

			<ModalContent>
				<ModalHeader>
					This Risk Assessment has an open draft
				</ModalHeader>

				<ModalCloseButton
					_hover={{ bg: "" }}
					_active={{ bg: "" }}
				/>

				<ModalBody>
					<HStack>
						<Image src={construction} h="70px" />

						<Spacer />

						<Text
							fontWeight={500}
							fontSize="sm"
							color="gray.600"
						>
							Would you like to continue editing or view the document?
						</Text>
					</HStack>
				</ModalBody>

				<ModalFooter>
					<HStack w="100%">
						<Button
							size="sm"
							onClick={() => { setIsOpen(false); }}
							cursor="pointer"
						>
							Cancel
						</Button>

						<Spacer />

						<Button
							size="sm"
							colorScheme="brand"
							_active={{ bg: "" }}
							_hover={{ bg: "#248451" }}
							onClick={() => {
								navigateTo(`/riskassessments/edit/${selectedRow?.raid}`, {
									state: {
										isReview: true,
										step: 1
									}
								});
							}}
						>
							<HStack>
								<Icon as={BsPencil} />
								<Text>
									Edit Draft
								</Text>
							</HStack>
						</Button>

						<Button
							size="sm"
							colorScheme="brand"
							_active={{ bg: "" }}
							_hover={{ bg: "#248451" }}
							onClick={() => {
								navigateTo(`/riskassessments/version/${selectedRow?.publishedversionid ?? selectedRow?.currentversionid}`, {
									state: {
										raid: selectedRow?.raid,
										ravid: selectedRow?.publishedversionid ?? selectedRow?.currentversionid
									}
								});
							}}
						>
							<HStack>
								<Text>
									View Risk Assessment
								</Text>
								<Icon as={BsArrowRightCircle} />
							</HStack>
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	};

	return (
		<Box m={{ base: 0, md: 5 }}>
			<Box
				h={{ base: "full", md: "unset" }}
				pt={2}
				pb={3}
				px={{ base: 0, md: 5 }}
				bg="white"
				rounded={{ base: "none", md: "lg" }}
				boxShadow={{ base: "none", md: "lg" }}
			>
				<HStack w="100%">
					<HStack
						p={3}
						pb={5}
						gap={2}
					>
						<Icon
							as={BsExclamationCircle}
							verticalAlign="middle"
							fontSize={{ base: "2xl", lg: "3xl" }}
							color="green.500"
						/>

						<Text
							fontWeight={{ base: 600, lg: 700 }}
							fontSize={{ base: "lg", lg: "2xl" }}
							textAlign="left"
							color="gray.700"
						>
							Risk Assessments
						</Text>
					</HStack>

					<Spacer />

					{
						isMobile && canEdit && tabIndex === 0 && <Box pr={3}>
							<Button
								leftIcon={<Icon as={BsPlusCircle} />}
								mt={-3}
								bg="brand.500"
								size="sm"
								color="white"
								_hover={{
									bg: "#248451",
								}}
								onClick={() => {
									if (hasRiskAssessmentEditingPermission && isWithinProductTierLimit) {
										navigateTo("/riskassessments/create", { state: { isReview: false } });
									}
									else {
										setSalesModalIsOpen(true);
									}
								}}
							>
								Create New
							</Button>
						</Box>
					}

				</HStack>

				<Divider w="unset" mx={{ base: 0, md: -5 }} />

				<Tabs
					colorScheme="green"
					onChange={(index) => setTabIndex(index)}
					defaultIndex={state?.tabIndex || 0}
				>
					{
						canEdit && <Flex
							pt={isMobile ? 0 : 2}
							pb={1}
							justify={CheckProductTierFlag(user?.producttier?.ptriskassessmentactionsflag) ? { base: "center", md: "space-between" } : "right"}
						>
							{
								CheckProductTierFlag(user?.producttier?.ptriskassessmentactionsflag) && <TabList
									mx={{ base: 0, md: 5 }}
									w={{ base: "full", md: "unset" }}
									justifyContent="center"
								>
									<Tab>
										<Text
											fontSize={isMobile ? "xs" : "sm"}
											color={tabIndex === 0 ? "green.500" : "gray.500"}
											fontWeight={tabIndex === 0 ? 700 : 500}
										>
											Risk Assessments
										</Text>
									</Tab>
									<Tab
										fontSize={isMobile ? "xs" : "sm"}
										color={tabIndex === 1 ? "green.500" : "gray.500"}
										fontWeight={tabIndex === 1 ? 700 : 500}
									>
										<Text>
											Safety Action Plan
										</Text>
									</Tab>
								</TabList>
							}

							<HStack
								mr={5}
								display={{ base: "none", md: tabIndex === 0 ? "flex" : "none" }}
							>
								<Button
									leftIcon={<Icon as={BsPlusCircle} />}
									bg="brand.500"
									_hover={{
										bg: "#248451",
									}}
									size="sm"
									color="white"
									float="right"
									onClick={() => {
										if (hasRiskAssessmentEditingPermission && isWithinProductTierLimit) {
											navigateTo("/riskassessments/create", { state: { isReview: false } });
										}
										else {
											setSalesModalIsOpen(true);
										}
									}}
								>
									Create Risk Assessment
								</Button>
							</HStack>
						</Flex>
					}

					<TabPanels>
						<TabPanel>
							<>
								<Grid
									gap={isMobile ? 2 : 3}
									mb={isMobile ? 2 : 5}
									templateColumns={{
										base: "repeat(2, 2fr)",
										lg: "repeat(4, 1fr)",
									}}

								>
									<InsightActionCard
										title="Need Acknowledged"
										count={needAcknowledgedCount || 0}
										image={needsacknowledged}
										color="danger.500"
										active={filter === "needAcknowledged"}
										className="hover-pop"
										cursor="pointer"
										onClickAction={() => {
											if (filter === "needAcknowledged") {
												setFilter("all");
												setPageIndex(0);
											} else {
												setFilter("needAcknowledged");
												setPageIndex(0);
											}
										}}
										loading={firstTimeLoading}
									/>

									{
										canEdit && <>
											<InsightActionCard
												title="Review Due Soon"
												count={expiringSoonCount || 0}
												image={expiringsoon}
												color="orange.500"
												active={filter === "expiringSoon"}
												className="hover-pop"
												cursor="pointer"
												onClickAction={() => {
													if (filter === "expiringSoon") {
														setFilter("all");
														setPageIndex(0);
													} else {
														setFilter("expiringSoon");
														setPageIndex(0);
													}
												}}
												loading={firstTimeLoading}
											/>

											<InsightActionCard
												colSpan={2}
												title="Overdue Review"
												count={expiredCount || 0}
												image={expired}
												color="#805AD5"
												active={filter === "expired"}
												className="hover-pop"
												cursor="pointer"
												onClickAction={() => {
													if (filter === "expired") {
														setFilter("all");
														setPageIndex(0);
													} else {
														setFilter("expired");
														setPageIndex(0);
													}
												}}
												loading={firstTimeLoading}
											/>
										</>
									}
								</Grid>

								{
									!isMobile && <Divider w="unset" mx={{ base: 0, md: -10 }} mb={5} />
								}

								<SlideFade in={!firstTimeLoading}>
									{
										categories?.length > 0 && categories?.length <= 7 && <Box
											p={1.5}
											bg="gray.50"
											textAlign="left"
											rounded="lg"
											border="1px solid"
											borderColor="gray.200"
										>
											<Text
												fontWeight={isMobile ? 600 : 500}
												color="gray.500"
												fontSize={isMobile ? "xs" : 'sm'}
												ml={1}
												mb={2}
											>
												Risk Assessment Categories
											</Text>

											<SlideFade in={!firstTimeLoading}>
												{
													categories?.map((c: any, i: Number) => {
														return (
															<SmallFilterCard
																key={i}
																title={c.racategory}
																onClickAction={() => {
																	c.racategory !== category
																		? updateCategory(c.racategory)
																		: updateCategory("")
																}}
																selectedCard={category}
																icon={BsExclamationCircle}
																iconColor="brand.500"
															/>
														);
													})
												}
											</SlideFade>
										</Box>
									}
								</SlideFade>

								<SlideFade in={!firstTimeLoading} offsetY="20px">
									<Stack
										direction={{ base: "column", lg: "row" }}
										mt={
											categories.length > 0 && categories.length <= 7 ? 2 :
												categories.length > 7 ? 5 : 5
										}
									>
										{
											categories?.length > 7 && <>
												<FormControl w={{ base: "full", lg: "50%" }}>
													<Input
														placeholder="Search Risk Assessments"
														fontSize="sm"
														value={search}
														onChange={updateSearch}
													/>
												</FormControl>
											</>
										}

										{
											categories?.length > 7 ? <FormControl
												colorScheme="green"
												w={{ base: "full", lg: "50%" }}
											>
												<Select
													_focus={{ borderColor: "brand.500", borderWidth: "1px" }}
													color="gray.600"
													fontSize="sm"
													onChange={(e) => {
														updateCategory(e.target.value);
													}}
													placeholder="All Categories"
												>
													{
														categories?.map((c: any, i) => {
															return <option key={`category_${i}`} value={c.racategory}>
																{c.racategory}
															</option>;
														})
													}
												</Select>
											</FormControl> :

												<FormControl w={{ base: "full", lg: "50%" }}>
													<Input
														placeholder="Search Risk Assessments"
														fontSize="sm"
														value={search}
														onChange={updateSearch}
													/>
												</FormControl>
										}
									</Stack>
								</SlideFade>

								<Box
									w="full"
									p={isMobile ? 0 : 3}
									mt={5}
									border={isMobile ? "none" : "1px solid"}
									borderColor="gray.100"
									borderRadius="lg"
								>
									{
										DataTable({
											data: currentItems ?? [],
											columns,
											loading,
											pageCount: 0,
											onHeaderClick: (c: any) => sortColumn(c.id)
										})
									}
								</Box>

								{renderDeleteHazardModal()}
							</>
						</TabPanel>

						<TabPanel>
							<RiskAssessmentActions />
						</TabPanel>
					</TabPanels>
				</Tabs>
			</Box>

			{renderNavigateToRiskAssessmentModal()}

			<ContactSalesModal
				salesModalisOpen={salesModalIsOpen}
				setSalesModalIsOpen={setSalesModalIsOpen}
				title={"Upgrade to Obbi Pro to unlock the full power of Risk Assessments"}
				lineOneTitle={"Unlimited oversight"}
				lineOne={"With unlimited risk assessment creation, you can ensure you’ve got coverage across all significant areas of risk."}
				lineTwoTitle={"Safety action plans"}
				lineTwo={"Create and track follow-up actions, including alerts to assigned teams and full visibility during inspections and audits."}
				image={expired}
				buttonOneText="Contact Sales"
				buttonOneLink="https://meetings-eu1.hubspot.com/ryan-bell?__hstc=191942773.9148893facbd66f6fc81cc0b9a1e1318.1674738526181.1696412244530.1696429283866.190&__hssc=191942773.4.1696429283866&__hsfp=1747869543&uuid=e194695e-43b6-4c57-b480-e4ce8a16ebcb"
				buttonTwoText="Learn More"
				buttonTwoLink="https://obbi.golf/features/"
				limitReached={!isWithinProductTierLimit}
				limit={user?.producttier?.ptriskassessmentlimit}
				limitType={user?.producttier?.ptriskassessmentlimit === "1" ? 'risk assessment' : 'risk assessments'}
			/>
		</Box>
	);
};