import { useCallback, useEffect, useState } from "react";
import {
	Avatar,
	Badge,
	Box,
	Button,
	HStack,
	Icon,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	SlideFade,
	Spacer,
	Stack,
	Text,
	useBreakpointValue,
	VStack
} from "@chakra-ui/react";
import { BsCheckCircle, BsDashCircle, BsLink45Deg, BsPlayCircle, BsStopCircle, BsThreeDotsVertical, BsTrash } from "react-icons/bs";

import { deleteAction, getAction } from "../../features/riskAssessments/riskAssessments.actions";
import { useNavigate } from "react-router-dom";
import { localDate } from "../../helpers/DayJsHelper";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";
import { CheckProductTierFlag } from "../../helpers/Utilities";

export const Action: React.FC<any> = ({
	action,
	actions,
	setActions,
	riskAssessmentId,
	riskAssessmentVersionId,
	hazardId
}) => {
	const navigateTo = useNavigate();
	const isMobile = useBreakpointValue({ base: true, md: false });

	const [loading, setLoading] = useState(true);
	const user = useSelector((state: RootState) => state.authReducer.user);

	const [ActionData, setActionData] = useState<any>({
		raaid: "",
		raadescription: "N/A"
	});

	const [deleteActionModalIsOpen, setDeleteActionModalIsOpen] = useState(false);

	const renderDeleteActionModal = () => {
		return <Modal
			size="lg"
			isOpen={deleteActionModalIsOpen}
			onClose={() => { setDeleteActionModalIsOpen(false); }}
		>
			<ModalOverlay />

			<ModalContent>
				<ModalHeader color="gray.600">
					Delete Action
				</ModalHeader>

				<ModalCloseButton
					_hover={{ bg: "" }}
					_active={{ bg: "" }}
				/>

				<ModalBody>
					<Text
						fontWeight={500}
						fontSize="sm"
						color="gray.600"
					>
						Are you sure you want to delete <b>{ActionData?.raadescription}</b>?
					</Text>
				</ModalBody>

				<ModalFooter>
					<HStack>
						<Button
							cursor="pointer"
							size="sm"
							onClick={() => { setDeleteActionModalIsOpen(false); }}
						>
							Cancel
						</Button>

						<Button
							className="hover-pop"
							colorScheme="red"
							size="sm"
							_active={{ bg: "" }}
							_hover={{ bg: "" }}
							onClick={() => {
								deleteActionFunc(ActionData?.raaid);
							}}
						>
							Delete
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	};

	const deleteActionFunc = (actionId: any) => {
		deleteAction(
			riskAssessmentId,
			riskAssessmentVersionId,
			hazardId,
			actionId
		)
			.then(() => {
				const deletedAction = actions.filter(
					(action: any) => actionId === action.raaid
				);

				const updatedActions = actions.filter(
					(action: any) => !deletedAction.includes(action)
				);

				setActions(updatedActions);
			})
			.catch((error: any) => {
				console.log(error);
			})
			.finally(() => {
				setDeleteActionModalIsOpen(false);
			});
	};

	const getActionFunc = useCallback(async () => {
		setLoading(true);

		getAction(
			riskAssessmentId,
			riskAssessmentVersionId,
			hazardId,
			action?.raaid
		)
			.then((res: any) => {
				setActionData(res.action);
			})
			.catch((error: any) => {
				console.log(error);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	useEffect(() => {
		getActionFunc();
	}, []);

	return (
		<SlideFade in={!loading} style={{ width: "100%" }}>
			<Box
				border="1px solid"
				borderColor="gray.200"
				rounded="lg"
				p={{ base: "8px", md: "11.5px" }}
			>
				<VStack alignItems="start" w="full">
					<HStack w="full">
						{
							(ActionData?.isTeamManager || ActionData?.isTeamMember) && <Icon as={BsLink45Deg} />
						}
						<Text
							lineHeight="5"
							color="gray.600"
							textAlign="left"
							fontSize={{ base: "xs", md: "sm" }}
							fontWeight={500}
							wordBreak="break-word"
							whiteSpace="break-spaces"
							cursor={CheckProductTierFlag(user?.producttier?.ptriskassessmentactionsflag) && (ActionData?.isTeamManager || ActionData?.isTeamMember) ? "pointer" : "default"}
							_hover={CheckProductTierFlag(user?.producttier?.ptriskassessmentactionsflag) && (ActionData?.isTeamManager || ActionData?.isTeamMember) ? { textDecoration: "underline" } : {}}
							onClick={() => {
								if (CheckProductTierFlag(user?.producttier?.ptriskassessmentactionsflag) && (ActionData?.isTeamManager || ActionData?.isTeamMember)) {
									navigateTo(`/actions/action/${ActionData?.action?.actionid}`,
										{ state: { from: "riskassessment" } }
									);
								}
							}}
						>
							{ActionData?.raadescription}
						</Text>

						<Spacer />

						<Menu>
							<MenuButton alignSelf="baseline">
								<Icon
									mt={1}
									as={BsThreeDotsVertical}
									fontSize="sm"
									color="gray.600"
									cursor="pointer"
								/>
							</MenuButton>

							<MenuList fontSize="sm">
								<MenuItem
									onClick={() => {
										setDeleteActionModalIsOpen(true);
									}}
								>
									<Icon as={BsTrash} mr={2} />
									<Text>Delete</Text>
								</MenuItem>
							</MenuList>
						</Menu>
					</HStack>

					<Stack
						w="full"
						mt={1}
						alignItems="start"
						direction={{ base: "column", md: "row" }}
					>
						<HStack w="full">
							<Avatar name={ActionData?.action?.teamname || null} size="xs" />
							<Text
								color="gray.500"
								fontSize={{ base: "8pt", md: "xs" }}
								fontWeight={500}
							>
								{ActionData?.action?.teamname || "Unassigned"}
							</Text>
						</HStack>

						<Spacer />

						<Text
							w="full"
							color="gray.500"
							fontSize={{ base: "8pt", md: "xs" }}
							fontWeight={500}
							alignSelf="center"
						>
							{
								ActionData?.action?.duedate ?
									`Due: ${localDate(ActionData?.action?.duedate)}` : "No due date"
							}
						</Text>

						{
							CheckProductTierFlag(user?.producttier?.ptriskassessmentactionsflag) &&
							<>
								<Spacer />

								<Badge
									size="sm"
									color={
										ActionData?.action?.actionstatus === "0" ? "gray.700" :
											ActionData?.action?.actionstatus === "1" ? "telegram" :
												ActionData?.action?.actionstatus === "2" ? "red.700" :
													ActionData?.action?.actionstatus === "99" ? "green.700" :
														"gray.700"
									}
									colorScheme={
										ActionData?.action?.actionstatus === "0" ? "gray" :
											ActionData?.action?.actionstatus === "1" ? "telegram" :
												ActionData?.action?.actionstatus === "2" ? "danger" :
													ActionData?.action?.actionstatus === "99" ? "green" :
														"gray"
									}
								>
									<HStack>
										<Icon
											as={
												ActionData?.action?.actionstatus === "0" ? BsDashCircle :
													ActionData?.action?.actionstatus === "1" ? BsPlayCircle :
														ActionData?.action?.actionstatus === "2" ? BsStopCircle :
															ActionData?.action?.actionstatus === "99" ? BsCheckCircle :
																BsDashCircle
											}
										/>
										<Text fontWeight={500} fontSize={{ base: "8pt", md: "xs" }}>
											{ActionData?.action?.statustext}
										</Text>
									</HStack>
								</Badge>
							</>
						}
					</Stack>
				</VStack>
			</Box>

			{renderDeleteActionModal()}
		</SlideFade >
	);
};