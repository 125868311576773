import { Button, Center, Spinner, Text, VStack } from "@chakra-ui/react";
import { isNull } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { validateToken } from "../features/auth/auth.actions";

export const TokenValidatedRoutes = () => {
    const navigateTo = useNavigate();

    const [loading, setLoading] = useState(true);
    const [isValid, setIsValid] = useState(false);

    const executeRoute = () => {
        const queryParameters = new URLSearchParams(window.location.search);

        const action = queryParameters.get("action");
        const filter = queryParameters.get("filter");
        const id = queryParameters.get("id");

        const navigateUserWithFilter = (
            route: string,
            filter?: string | null) => {
            navigateTo(`/${route}/`, {
                state: {
                    filter: filter
                }
            });
        };

        const navigateUserWithId = (
            route: string,
            filter?: string | null) => {
            navigateTo(`/${route}/${id}`, {
                state: {
                    filter: filter
                }
            });
        };

        if (isNull(action)) setIsValid(false);
        else {
            switch (action) {
                case "home": navigateTo("/home");
                    break;
                case "Profile": navigateTo("/profile");
                    break;
                case "Action": navigateUserWithId("actions/action", id);
                    break;
                case "Certificate": navigateUserWithId("certificates", id);
                    break;
                case "Document": navigateUserWithId("documents", id);
                    break;
                case "Incident": navigateUserWithId("incidents", id);
                    break;
                case "Incidents": navigateTo("/incidents");
                    break;
                case "StartCourse": navigateUserWithId("training/module", id);
                    break;
                case "findCourseById": navigateUserWithId("training/module", id);
                    break;
                case "actions": navigateUserWithFilter(action, filter);
                    break;
                case "training": navigateUserWithFilter(action, filter);
                    break;
                case "documents": navigateUserWithFilter(action, filter);
                    break;
                case "certificates": navigateUserWithFilter(action, filter);
                    break;
                case "incidents": navigateUserWithFilter(action, filter);
                    break;
                case "messages": navigateUserWithFilter(action, filter);
                    break;
                case "notifications": navigateUserWithFilter(action, filter);
                    break;
                case "tasks": navigateTo(`/${action}`, {
                    state: {
                        from: "tasks"
                    }
                });
                    break;
                case "guidedTasks": navigateTo("/tasks", {
                    state: {
                        navData: { tabIndex: 1, filter },
                    }
                });
                    break;
                case "StartTeamTask": navigateTo(`/tasks/tasksummary/${id}`, {
                    state: {
                        from: "tasks"
                    }
                });
                    break;
                case "riskAssessments": navigateTo(`/riskassessments`);
                    break;
            };
        }
    };

    const fetchData = useCallback(async () => {
        let tokenIsValid = false;

        const queryParameters = new URLSearchParams(window.location.search);
        const token = queryParameters.get("token");

        if (!isNull(token)) tokenIsValid = await validateToken(token);

        return tokenIsValid;
    }, []);

    useEffect(() => {
        async function routeUser() {
            const isValid = await fetchData();
            if (!isValid) {
                setIsValid(false);
            }

            executeRoute();
        }

        routeUser();
    }, [fetchData]);

    return (
        <Center mt={36}>
            <VStack>
                <Text
                    fontSize="2xl"
                    fontWeight={700}
                    color="gray.500"
                    hidden={!loading}
                >
                    Just a moment...
                </Text>

                {
                    loading ? <Spinner size="lg" color="brand.500" /> :
                        isValid ?
                            <VStack>
                                <Text
                                    fontSize="lg"
                                    fontWeight={700}
                                    color="gray.500"
                                >
                                    Click here to continue
                                </Text>

                                <Button
                                    colorScheme="brand"
                                    onClick={() => {
                                        navigateTo("/home")
                                    }}
                                >
                                    Continue
                                </Button>
                            </VStack> :

                            !isValid && <Text
                                fontSize="md"
                                fontWeight={700}
                                color="danger.500"
                            >
                                Cannot route you to your destination.
                            </Text>
                }
            </VStack>
        </Center>
    )
}