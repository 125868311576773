import { get, getWithParams, post, _delete, put, patch } from "../../api/axiosClient";

export async function getRiskAssessments(
    sort: string = "",
    filter: string = "",
    search: string = "",
    category: string = "",
    page: string = "",
    pagesize: string = ""
) {
    let riskAssessments: any = [];

    await getWithParams("riskAssessments/all", { sort, filter, search, category, page, pagesize })
        .then((res: any) => {
            riskAssessments = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return riskAssessments;
}

export async function getRiskAssessment(id: any) {
    let riskAssessment: any = {};

    await getWithParams("riskAssessments/riskAssessment", { id })
        .then((res: any) => {
            riskAssessment = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return riskAssessment;
}

export async function checkRiskAssessmentExists(id: any) {
    let result: any = {};

    await getWithParams("riskAssessments/checkExists", { id })
        .then((res: any) => {
            result = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return result;
}

export async function createRiskAssessment(riskAssessmentBody: any) {
    let riskAssessment: any = {};

    await post(`riskAssessments/riskAssessment`, riskAssessmentBody)
        .then((res: any) => {
            riskAssessment = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return riskAssessment;
}

export async function updateRiskAssessment(riskAssessmentBody: any) {
    let riskAssessment: any = {};

    await put(`riskAssessments/riskAssessment`, riskAssessmentBody)
        .then((res: any) => {
            riskAssessment = res?.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return riskAssessment;
}

export async function patchRiskAssessment(patchBody: any): Promise<any> {
    let riskAssessment: any = {};

    await patch("riskAssessments/riskAssessment", patchBody)
        .then((res: any) => {
            riskAssessment = res?.data;
        })
        .catch((error: any) => {
            throw error;
        })

    return riskAssessment;
}

export async function updateRiskAssessmentTeams(postBody: any): Promise<any> {
    let riskAssessment: any = {};

    await post("riskAssessments/setAcknowledgementTeams", postBody)
        .then((res: any) => {
            riskAssessment = res?.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return riskAssessment;
}

export async function patchVersionRiskAssessment(patchBody: any): Promise<any> {
    let riskAssessmentVersion: any = {};

    await patch("riskAssessments/version", patchBody)
        .then((res: any) => {
            riskAssessmentVersion = res.data;
        })
        .catch((error: any) => {
            throw error;
        })

    return riskAssessmentVersion;
}

export async function deleteRiskAssessment(id: any) {
    let deleteRAResponse: any = {};

    await _delete(`riskAssessments/riskAssessment`, { id })
        .then((res: any) => {
            deleteRAResponse = res?.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return deleteRAResponse;
}

export async function getAcknowledgements(id: any, version: string, search: string, team: string, sort: string, pageIndex: number) {
    let riskAssessment: any = {};

    await getWithParams("riskAssessments/acknowledgements", { id, version, search, team, sort, pageIndex })
        .then((res: any) => {
            riskAssessment = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return riskAssessment;
}

export async function acknowledgeRiskAssessment(id: string) {
    let acknowledgementResponse: any = {};

    await post("riskAssessments/acknowledge", { id })
        .then((res: any) => {
            acknowledgementResponse = res?.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return acknowledgementResponse;
}

export async function beginNewReview(id: string) {
    let newReviewResponse: any = {};

    await post("riskAssessments/beginReview", { id })
        .then((res: any) => {
            newReviewResponse = res?.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return newReviewResponse;
}

export async function getVersions(id: any) {
    let versions: any = {};

    await getWithParams("riskAssessments/versions", { id })
        .then((res: any) => {
            versions = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return versions;
}

export async function getVersion(id: any, version: any) {
    let versions: any = {};

    await getWithParams("riskAssessments/version", { id, version })
        .then((res: any) => {
            versions = res?.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return versions;
}

export async function deleteDraft(id: string) {
    let result: any = {};

    await post("riskAssessments/discardDraft", { id })
        .then((res: any) => {
            if (res?.data?.success) {
                result = res?.data;
            } else
                throw new Error("Could not delete this draft");
        })
        .catch((error: any) => {
            throw error;
        });

    return result;
}

export async function createHazard(hazardBody: any) {
    let hazardData: any = {};

    await post("riskAssessments/hazard", hazardBody)
        .then((res: any) => {
            if (res.data?.success) {
                hazardData = res?.data;
            } else
                throw new Error("Could not create Risk Assessment");
        })
        .catch((error: any) => {
            throw error;
        });

    return hazardData;
}

export async function getHazard(id: any, version: any, hazard: any) {
    let hazardData: any = {};

    await getWithParams(`riskAssessments/hazard`, { id, version, hazard })
        .then((res: any) => {
            hazardData = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return hazardData;
}

export async function updateHazard(hazardBody: any) {
    let hazardData: any = {};

    await put(`riskAssessments/hazard`, hazardBody)
        .then((res: any) => {
            hazardData = res?.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return hazardData;
}

export async function deleteHazard(id: any, version: any, hazard: any) {
    let hazardData: any = {};

    await _delete(`riskAssessments/hazard`, { id, version, hazard })
        .then((res: any) => {
            hazardData = res?.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return hazardData;
}

export async function getControl(id: any, version: any, hazard: any, control: any) {
    let controlData: any = {};

    await getWithParams(`riskAssessments/control`, { id, version, hazard, control })
        .then((res: any) => {
            controlData = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return controlData;
}

export async function updateControl(controlBody: any) {
    let controlData: any = {};

    await put(`riskAssessments/control`, controlBody)
        .then((res: any) => {
            controlData = res?.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return controlData;
}

export async function createControl(controlBody: any) {
    let controlData: any = {};

    await post("riskAssessments/control", controlBody)
        .then((res: any) => {
            if (res.data?.success) {
                controlData = res?.data;
            } else
                throw new Error("Could not create this Control");
        })
        .catch((error: any) => {
            throw error;
        });

    return controlData;
}

export async function deleteControl(id: any, version: any, hazard: any, control: any) {
    let controlData: any = {};

    await _delete(`riskAssessments/control`, { id, version, hazard, control })
        .then((res: any) => {
            controlData = res?.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return controlData;
}

export async function getAction(id: any, version: any, hazard: any, action: any) {
    let controlData: any = {};

    await getWithParams(`riskAssessments/action`, { id, version, hazard, action })
        .then((res: any) => {
            controlData = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return controlData;
}

export async function createAction(actionBody: any) {
    let actionData: any = {};

    await post("riskAssessments/action", actionBody)

        .then((res: any) => {
            if (res?.data?.success) {
                actionData = res?.data;
            } else
                throw new Error("Could not create this Action");
        })
        .catch((error: any) => {
            throw error;
        });

    return actionData;
}

export async function deleteAction(id: any, version: any, hazard: any, action: any) {
    let actionData: any = {};

    await _delete(`riskAssessments/action`, { id, version, hazard, action })
        .then((res: any) => {
            actionData = res?.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return actionData;
}

export async function postEditingTeam(editBody: any) {
    let riskAssessment: any = {};

    await post("riskAssessments/setEditingTeam", editBody)

        .then((res: any) => {
            if (res?.data?.success) {
                riskAssessment = res?.data;
            } else
                throw new Error("Could not set the Editing Team.");
        })
        .catch((error: any) => {
            throw error;
        });

    return riskAssessment;
}

export async function getAllActions(
    sort: string = "",
    filter: string = "",
    search: string = "",
    team: string = "",
    category: string = "",
    page: string = "",
    pagesize: string = ""
) {
    let riskAssessmentActions: any = [];

    await getWithParams("riskAssessments/actions", { sort, filter, search, team, category, page, pagesize, all: true })
        .then((res: any) => {
            riskAssessmentActions = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return riskAssessmentActions;
}

export async function publishRiskAssessment(raid: string) {
    let publishResponse: any = {};

    await post("riskAssessments/publish", { id: raid })
        .then((res: any) => {
            publishResponse = res?.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return publishResponse;
}

export async function getAttachment(
    id: string,
    version: string,
    attachment: string,
    pdf: boolean
) {
    let result: any = {};

    await getWithParams("riskAssessments/attachment", { id, version, attachment, pdf, datauri: true, thumbnail: false })
        .then((res: any) => {
            result = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return result;
}

export async function addAttachment(attachmentData: any) {
    let result: any = {};

    await post("riskAssessments/attachment", attachmentData)
        .then((res: any) => {
            result = res?.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return result;
}

export async function deleteAttachment(id: string, version: string, attachment: any) {
    let result: any = {};

    await _delete("riskAssessments/attachment", { id, version, attachment })
        .then((res: any) => {
            result = res?.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return result;
}

export async function addHazardAttachment(attachmentData: any) {
    let result: any = {};

    await post("riskAssessments/hazardAttachment", attachmentData)
        .then((res: any) => {
            result = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return result;
}

export async function getHazardAttachment(id: any, version: any, hazard: any, attachment: any, pdf: boolean) {
    let controlData: any = {};

    await getWithParams(`riskAssessments/hazardAttachment`, { id, version, hazard, attachment, pdf, datauri: true, thumbnail: false })
        .then((res: any) => {
            controlData = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return controlData;
}

export async function deleteHazardAttachment(id: string, version: string, hazard: string, attachment: any) {
    let result: any = {};

    await _delete("riskAssessments/hazardAttachment", { id, version, hazard, attachment })
        .then((res: any) => {
            result = res?.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return result;
}