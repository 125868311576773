import { useCallback, useEffect, useMemo, useState } from "react";
import {
	Box,
	Icon,
	Text,
	SlideFade,
	VStack,
	Table,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
	Avatar,
	HStack,
	FormControl,
	Input,
	Center,
	Stack,
	useBreakpointValue,
	Flex,
} from "@chakra-ui/react";
import { BsArrowDown, BsArrowUp, BsPerson } from "react-icons/bs";
import { localDate } from "../../helpers/DayJsHelper";
import { useTable, useSortBy, usePagination } from "react-table";
import { getAcknowledgements } from "../../features/riskAssessments/riskAssessments.actions";
import { Pager } from "../common/Pager";
import { useDebounce } from "../common/useDebounce";
import { TeamCombobox } from "../common/TeamCombobox";
import { isEmpty } from "lodash";

export const Acknowledgements = ({ riskAssessment, version }: any) => {
	const isMobile = useBreakpointValue({ base: true, md: false });

	const [loading, setLoading] = useState<boolean>(true);

	const [teams, setTeams] = useState([]);
	const [team, setTeam] = useState(0);

	const [search, setSearch] = useState("");
	const [sort, setSort] = useState("statusdesc");

	const [currentItems, setCurrentItems] = useState(riskAssessment?.versions);
	const [pageCount, setPageCount] = useState(0);
	const [pageIndex, setPageIndex] = useState(0);

	const columns = useMemo(
		() => [
			{
				Header: "",
				accessor: "raaversion",
				disableSortBy: true,
			},
			{
				Header: <>
					USER
					{
						sort === "user" ?
							<Icon as={BsArrowUp} color="gray.600" /> :
							sort === "userdesc" ?
								<Icon as={BsArrowDown} color="gray.600" /> :
								<></>
					}
				</>,
				accessor: "raauser",
				Cell: (cell: any) => {
					var name = cell.row.original.udforename + " " + cell.row.original.udsurname;
					return <HStack>
						<Avatar
							size="xs"
							name={name}
							color="white"
						/>
						<Text color="gray.600" fontSize="sm" wordBreak="break-word" whiteSpace="pre-wrap" maxW="230px">
							{name}
						</Text>
					</HStack>
				}
			},
			{
				Header: <>
					TEAM MEMBERSHIPS{
						sort === "team" ?
							<Icon as={BsArrowUp} color="gray.600" /> :
							sort === "teamdesc" ?
								<Icon as={BsArrowDown} color="gray.600" /> :
								<></>
					}
				</>,
				accessor: "team",
				Cell: (cell: any) => {

					var teams = cell.row.original.teamMemberships;
					var managedteams = cell.row.original.managedTeams;

					//merge managed teams and teams without duplicates
					teams = teams.concat(managedteams.filter((item: any) => !teams.some((other: any) => item.teamid === other.teamid)));

					//teams assigned to the risk assessment
					var riskassessmentTeams = riskAssessment?.teams;

					//filter out teams not assigned to the risk assessment
					teams = teams.filter((item: any) => riskassessmentTeams?.some((other: any) => item.teamid === other.teamid));

					return <VStack alignItems={'left'}>
						{
							teams?.map((team: any, index: any) => {
								return <HStack key={index} >
									<Avatar
										size={teams.length > 1 ? "2xs" : "xs"}
										name={team?.teamname}
										color="white"
									/>
									<Text color="gray.600" fontSize={teams.length > 1 ? "8pt" : "sm"} wordBreak="break-word" whiteSpace="pre-wrap" maxW="230px">
										{team?.teamname}
									</Text>
								</HStack>
							})
						}
					</VStack>
				}
			},
			{
				Header: <>
					DATE ACKNOWLEDGED {
						sort === "raadateacknowledged" ?
							<Icon as={BsArrowUp} color="gray.600" /> :
							sort === "raadateacknowledgeddesc" ?
								<Icon as={BsArrowDown} color="gray.600" /> :
								<></>
					}
				</>,
				accessor: "raadateacknowledged",
				Cell: (cell: any) => <Text
					color="gray.600"
					fontSize="sm"
				>
					{cell.value ? localDate(cell.value) : '-'}
				</Text>
			},
			{
				Header: <>
					LAST VERSION ACKNOWLEDGED {
						sort === "lastversionacknowledged" ?
							<Icon as={BsArrowUp} color="gray.600" /> :
							sort === "lastversionacknowledgeddesc" ?
								<Icon as={BsArrowDown} color="gray.600" /> :
								<></>
					}
				</>,
				accessor: "latestRevision",
				Cell: (cell: any) => {
					var latest = cell.row.original.latest;
					var latestVersionAcknowledged = latest?.ravsequencenumber;

					return <Text
						color="gray.600"
						fontSize="sm"
					>
						{latestVersionAcknowledged ?? '-'}
					</Text>
				}
			}
		], [riskAssessment, sort]
	);

	const getUserTeams = (teamMemberships: any, managedTeams: any) => {
		var teams = teamMemberships;
		var managedteams = managedTeams;

		// merge managed teams and teams without duplicates
		teams = teams.concat(
			managedteams.filter((item: any) =>
				!teams.some((other: any) =>
					item.teamid === other.teamid
				)
			)
		);

		// teams assigned to the risk assessment
		var riskassessmentTeams = riskAssessment?.teams;

		// filter out teams not assigned to the risk assessment
		teams = teams.filter((item: any) => riskassessmentTeams?.some((other: any) => item.teamid === other.teamid));

		return teams;
	};

	function DataTable({
		data,
		columns,
		loading,
		pageCount: controlledPageCount,
		onHeaderClick
	}: any) {
		const {
			getTableProps,
			getTableBodyProps,
			headerGroups,
			page,
			prepareRow,
		} = useTable(
			{
				columns,
				data,
				manualPagination: true,
				manualSortBy: true,
				pageCount: controlledPageCount,
				onHeaderClick,
				autoResetPage: false,
				initialState: {
					hiddenColumns: ["raaversion"]
				}
			},
			useSortBy,
			usePagination
		);

		return (
			<SlideFade in={!loading}>
				<VStack
					display={{ base: "flex", lg: "none" }}
					mb={currentItems?.length > 6 ? 5 : 0}
				>
					{
						currentItems?.map((task: any, i: number) => (
							<Box
								key={i}
								p={3}
								pt={2}
								w="full"
								borderBottom="1px solid"
								borderColor="gray.100"
							>
								<VStack alignItems="start" >
									<HStack>
										<Icon
											as={BsPerson}
											fontWeight={600}
											color="gray.500"
										/>
										<Text
											fontSize="xs"
											fontWeight={500}
											textAlign="start"
											color="gray.600"
										>
											{task?.udforename + " " + task?.udsurname}
										</Text>
									</HStack>

									<Stack alignItems={'left'} direction="row" gap={1}>
										{
											getUserTeams(task?.teamMemberships, task?.managedTeams)
												.map((team: any, index: any) => {
													return <Flex key={index}>
														<Avatar
															size="2xs"
															name={team?.teamname}
															color="white"
															mr={1.5}
														/>
														<Text color="gray.500" fontSize="xs" wordBreak="break-word" whiteSpace="pre-wrap" maxW="230px">
															{team?.teamname}
														</Text>
													</Flex>
												})
										}
									</Stack>

									<HStack w="100%" gap={12}>
										<Box alignItems="start">
											<Text
												fontSize="8pt"
												fontWeight={600}
												textAlign="start"
												color="gray.600"
											>
												Date Acknowledged
											</Text>

											<Text
												fontSize="xs"
												fontWeight={500}
												textAlign="start"
												color="gray.500"
											>
												{localDate(task.raadateacknowledged) || "-"}
											</Text>
										</Box>

										<Box alignItems="start">
											<Text
												fontSize="8pt"
												fontWeight={600}
												textAlign="start"
												color="gray.600"
											>
												Last Version Acknowledged
											</Text>

											<Text
												fontSize="xs"
												fontWeight={500}
												textAlign="start"
												color="gray.500"
											>
												{task?.latest?.ravsequencenumber || "-"}
											</Text>
										</Box>
									</HStack>
								</VStack>
							</Box>
						))
					}
				</VStack>

				<Table
					{...getTableProps()}
					id="TaskTable"
					variant="striped"
					display={{ base: "none", lg: "inline-table" }}
				>
					<Thead>
						{
							page.length != 0 && headerGroups.map((headerGroup, i) => (
								<Tr {...headerGroup.getHeaderGroupProps()}>
									{
										headerGroup.headers.map((column, i) => (
											<Th
												{...column.getHeaderProps(column.getSortByToggleProps())}
												onClick={() => onHeaderClick(column)}
												key={`task_header_${i}`}
											>
												<Text fontWeight={700} color="gray.600">
													{column.render("Header")}
												</Text>
											</Th>
										))
									}
								</Tr>
							))
						}
					</Thead>

					<Tbody {...getTableBodyProps()}>
						{
							page.length === 0 ?
								<Center m={5}>
									<VStack>
										<Text fontSize="xl" fontWeight={600}>
											No Acknowledgements Found
										</Text>
										<Text fontWeight={400} color="gray.500">
											You have no acknowledgements available
										</Text>
									</VStack>
								</Center>
								:
								page.map((row, i) => {
									prepareRow(row);

									return (
										<Tr
											{...row.getRowProps()}
											key={i}
											bg={i % 2 ? "white" : "purple.50"}
											borderBottom="1px solid"
											borderColor="gray.100"
											rounded="lg"
										>
											{
												row.cells.map((cell) => {
													return (
														<Td wordBreak="break-word" {...cell.getCellProps()}>
															{cell.render("Cell")}
														</Td>
													);
												})
											}
										</Tr>
									);
								})
						}
					</Tbody>
				</Table>

				{
					pageCount > 1 && <Box
						mt={3}
						textAlign="center"
					>
						<Pager
							pageCount={pageCount || 0}
							pageIndex={pageIndex || 0}
							setPageIndex={setPageIndex}
						/>
					</Box>
				}
			</SlideFade>
		);
	};

	const debouncedRequest = useDebounce(() => {
		setPageIndex(0);

		fetchData(
			riskAssessment?.raid,
			version?.ravid,
			search,
			team,
			sort,
			0)
	}, 300);

	const updateSearch = (e: any) => {
		setSearch(e.target.value);

		debouncedRequest();
	};

	const sortColumn = (term: string) => {
		switch (term) {
			// case "ravname":
			// 	if (sort === "ravname") {
			// 		setSort("ravnamedesc");
			// 	} else if (sort === "ravnamedesc") {
			// 		setSort("ravname");
			// 	} else setSort("ravnamedesc");
			// 	break;
			// case "user":
			// 	if (sort === "user") {
			// 		setSort("userdesc");
			// 	} else if (sort === "userdesc") {
			// 		setSort("user");
			// 	} else setSort("userdesc");
			// 	break;
			// case "ravstate":
			// 	if (sort === "ravstate") {
			// 		setSort("ravstatedesc");
			// 	} else if (sort === "ravstatedesc") {
			// 		setSort("ravstate");
			// 	} else setSort("ravstatedesc");
			// 	break;
			// case "ravdatecreated":
			// 	if (sort === "ravdatecreated") {
			// 		setSort("ravdatecreateddesc");
			// 	} else if (sort === "ravdatecreateddesc") {
			// 		setSort("ravdatecreated");
			// 	} else setSort("ravdatecreateddesc");
			// 	break;
			case "raadateacknowledged":
				if (sort === "raadateacknowledged") {
					setSort("raadateacknowledgeddesc");
				} else if (sort === "raadateacknowledgeddesc") {
					setSort("raadateacknowledged");
				} else setSort("raadateacknowledgeddesc");
				break;
			// case "lastversionacknowledged":
			// 	if (sort === "lastversionacknowledged") {
			// 		setSort("lastversionacknowledgeddesc");
			// 	} else if (sort === "lastversionacknowledgeddesc") {
			// 		setSort("lastversionacknowledged");
			// 	} else setSort("lastversionacknowledgeddesc");
			// 	break;
		}
	};

	const fetchData = useCallback(
		async (id, version, search, team, sort, pageIndex) => {
			setLoading(true);

			pageIndex = (pageIndex + 1).toString();

			getAcknowledgements(id, version, search, team, sort, pageIndex)
				.then((res: any) => {
					setCurrentItems(res.acknowledgements);
					setPageCount(res.pageCount);
					setTeams(res.riskAssessment?.teams);
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					setLoading(false);
				})
		}, []);

	useEffect(() => {
		if (!riskAssessment?.raid) return;

		fetchData(
			riskAssessment?.raid,
			version?.ravid,
			search,
			team,
			sort,
			pageIndex
		);
	}, [riskAssessment, sort, team, pageIndex]);

	return (
		<Box>
			<HStack w="100%" mb={5}>
				<FormControl w={{ base: "full", lg: "50%" }}>
					<Input
						placeholder="Search Acknowledgements"
						rounded="md"
						fontSize="sm"
						value={search}
						onChange={updateSearch}
					/>
				</FormControl>

				<FormControl
					colorScheme="green"
					w={{ base: "100%", lg: "50%" }}
					hidden={isEmpty(teams)}
				>
					<TeamCombobox
						disabled={isEmpty(teams)}
						teams={teams || []}
						selectedTeam={team}
						update={setTeam}
					/>
				</FormControl>
			</HStack>

			<Box
				w="full"
				p={isMobile ? 0 : 3}
				border="1px solid"
				borderColor="gray.100"
				borderRadius="lg"
			>
				{
					DataTable({
						data: currentItems ?? [],
						columns,
						loading,
						pageCount: 0,
						onHeaderClick: (c: any) => sortColumn(c.id)
					})
				}
			</Box>
		</Box>
	);
};