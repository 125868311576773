import { get, getWithParams, post } from "../../api/axiosClient";
import store from "../../redux/store";
import { getNotificationFoldersSuccess } from "./notification.slice";
import NotificationFolders from "../../types/NotificationFolders";

export async function getNotifications(
    kind: string = "0",
    filter: string,
    grouping: string,
    sort: string,
    page: Number,
    pagesize: string = "6"
) {
    let notificationResult: any = {
        "pageCount": 0,
        "notifications": [],
        "categories": []
    };

    await getWithParams("alerts/all", { kind, filter, grouping, sort, page, pagesize })
        .then((res: any) => {
            notificationResult.pageCount = res.data.pageCount;
            notificationResult.notifications = res.data.alerts;
            notificationResult.categories = Object.keys(res.data.breakdown).map(key => {
                return res.data.breakdown[key];
            });
        })
        .catch((error: any) => {
            throw error;
        });

    return notificationResult;
}

export async function getNotification(id: Number) {
    let notification = null;

    await getWithParams("alerts/alert", { id })
        .then((res: any) => {
            notification = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return notification;
}

export async function getBreakdown() {
    let folders: NotificationFolders = {
        criticalNewCount: 0,
        criticalClearedCount: 0,
        personalNewCount: 0,
        personalClearedCount: 0,
        teamNewCount: 0,
        teamClearedCount: [],
        totalCount: 0
    };

    await get("alerts/breakdown?calculatebreakdown=0")
        .then((res: any) => {
            folders.criticalNewCount = res.data.critical.new;
            folders.criticalClearedCount = res.data.critical.cleared;
            folders.personalNewCount = res.data.personal.new;
            folders.personalClearedCount = res.data.personal.cleared;
            folders.teamNewCount = res.data.team.new;
            folders.teamClearedCount = res.data.team.cleared;

            folders.totalCount = res.data.alertCount;

            store.dispatch(getNotificationFoldersSuccess(folders));
        })
        .catch((error: any) => {
            throw error;
        });

    return folders;
}

export async function dismissAlerts() {
    let message: {} = {};

    await post("alerts/dismissAlerts", { kind: 0 })
        .then((res: any) => {
            message = res.data.message;
        })
        .catch((error: any) => {
            throw error;
        });

    return message;
}

export async function dismissAlert(id: number) {
    let message: {} = {};

    await post("alerts/dismissAlert", { id: id })
        .then((res: any) => {
            message = res.data.message;
        })
        .catch((error: any) => {
            throw error;
        });

    return message;
}

export async function rateTrainingGroup(
    id: Number,
    tg: Number,
    rating: Number
) {
    return (
        await post("alerts/rateTrainingGroup", { id, tg, rating })
            .then((res: any) => { })
            .catch((error: any) => {
                throw error;
            })
    );
}

export async function getNotificationAttachment(id: Number, attachment: string) {
    let result: {} = {};

    await getWithParams("alerts/attachment", { id, attachment, datauri: 1 })
        .then((res: any) => {
            result = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return result;
}