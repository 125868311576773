import * as React from 'react';
import { useNavigate } from 'react-router';
import {
    Box,
    Button,
    Icon,
    Square,
    Stack,
    Text,
    useBreakpointValue,
    useColorModeValue,
} from '@chakra-ui/react';
import { BsExclamationCircleFill } from 'react-icons/bs';

export const Banner: React.FC<any> = ({ showBannerLink, unreadCriticalCount }) => {
    const navigateTo = useNavigate();
    const isMobile = useBreakpointValue({ base: true, md: false })

    return (
        <Box
            borderLeft="3px solid"
            borderColor="danger.500"
            bg="red.50"
            my={{ base: 3, md: 5 }}
            mx={{ base: 3, md: 5 }}
            px={{ base: '4', md: '3' }}
            py={{ base: '4', md: '2' }}
            boxShadow={useColorModeValue('sm', 'sm-dark')}
            borderRadius="md"
        >
            <Stack
                direction={{ base: 'column', md: 'row' }}
                justify="space-between"
                spacing={{ base: '3', md: '2' }}
            >
                <Stack
                    spacing="4"
                    direction={{ base: 'column', md: 'row' }}
                    align={{ base: 'start', md: 'center' }}
                >
                    {!isMobile && (
                        <Square size="6" bg="red.50" borderRadius="md">
                            <Icon as={BsExclamationCircleFill} boxSize="6" color="red.500" />
                        </Square>
                    )}
                    <Stack spacing="0.5" pe={{ base: '4', md: '0' }}>
                        <Text fontSize={{ base: "sm", md: "sm" }} fontWeight="md">
                            You have <strong>{unreadCriticalCount} critical message{unreadCriticalCount === 1 ? "" : "s"}</strong> that require{unreadCriticalCount === 1 ? "s" : ""} your attention.
                        </Text>
                    </Stack>
                </Stack>
                <Stack
                    direction={{ base: 'column', sm: 'row' }}
                    spacing={3}
                    align={{ base: 'stretch', md: 'center' }}
                >
                    <Button
                        className="hover-pop"
                        variant="outline"
                        borderColor="red.500"
                        color="red.500"
                        bg="transparent"
                        width="100%"
                        size="sm"
                        hidden={!showBannerLink}
                        _hover={{ bg: "" }}
                        onClick={() => {
                            navigateTo("/messages",
                                {
                                    state: {
                                        filter: "critical"
                                    }
                                });
                        }}
                    >
                        View Message{unreadCriticalCount === 1 ? "" : "s"}
                    </Button>
                </Stack>
            </Stack>
        </Box>
    )
}